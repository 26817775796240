
export const $pages = {
    "register": {
        message: "Please Sign up to continue",
        caption: 'Sign Up'
    },
    "signIn": {
        message: "Please login to continue",
        caption: 'Login'
    },
    "forgotPassword": {
        message: "Please enter your email id to retrieve your password",
        caption: 'Submit'
    },
    "otp": {
        message: "Please enter your mobile number to continue",
        caption: 'Next'
    },
    "verifyotp": {
        message: "Please Enter OTP you received in your Mobile Number",
        caption: 'submit'
    }
};