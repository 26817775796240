<section class="home_serach_page">
    <div class="lines">
    <div class="line"></div>
    <div class="line"></div>
    <div class="line"></div>
    <div class="line"></div> 
    </div>
    <div class="container">
        <div class="row align-items-center">
            <div class="col home_first_col">
                <h2>Find Best Schools with <strong>Admitmykid. </strong>
                <span>A trusted school discovery and selection platform.</span>
                </h2>
                <div class="home_search_input">
                    <angular2-multiselect [data]="cities" [(ngModel)]="selectedItems"  [settings]="dropdownSettings"></angular2-multiselect>
                    <button class="btn btn-srch" (click)="home_submit()">search</button>
                </div>
            </div>
            <div class="col-auto">
                <img src="assets/img/banner-img2.png" alt="logo" class="img-fluid main_img" style=" position: relative; z-index: 99;">
                <div class="banner-shape8" data-speed="0.06" data-revert="true" >
                    <img src="assets/img/banner-shape8.png" alt="shape image">
                </div>
                <div class="banner-shape9" data-speed="0.06" data-revert="true" >
                    <img src="assets/img/banner-shape9.png" alt="shape image">
                </div>
                <div class="banner-shape10" data-speed="0.06" data-revert="true" >
                    <img src="assets/img/banner-shape10.png" alt="shape image">
                </div>
            </div>
        </div>
    </div>
</section> 