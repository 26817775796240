import { Component, ViewChild, ElementRef, NgZone } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { $animations } from './login-animations';
import { $pages } from './login-pages';
import { AuthService } from '../services/auth/auth.service';
import Swal from 'sweetalert2';
import { SessionStorageService } from 'ngx-webstorage';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Globals } from '../globals';
import { ToastrService } from 'ngx-toastr';
import { HeaderComponent } from '../header/header.component';

type pageTypes = 'register' | 'signIn' | 'forgotPassword' | 'otp' | 'verifyotp';
declare var $: any;

@Component({
  selector: 'app-auth-test',
  templateUrl: './auth-test.component.html',
  styleUrls: ['./auth-test.component.css'],
  animations: $animations
})

export class AuthTestComponent {
  public pages = $pages;
  public page: pageTypes;

  // form
  public form: FormGroup;
  public email: FormControl;
  public name: FormControl;
  public password: FormControl;
  public confirmPassword: FormControl;
  public phone: FormControl;
  public otpNumber: FormControl;
  schoolAddedTocart;
  error: string = null;
  tokenkey: string;
  validOtp: any = '0';
  mobNumberPattern = "^((\\+91-?)|0)?[0-9]{10}$";

  auth2: any;
  resendMail = false;
  checkStatus: number;
  btnDisable = false;

  @ViewChild('loginRef', { static: true }) loginElement: ElementRef;

  constructor(public auth: AuthService, public router: Router, public storage: SessionStorageService, private httpClient: HttpClient, private route: ActivatedRoute, public zone: NgZone, private globals: Globals, private toastr: ToastrService, private headerComponent: HeaderComponent) {

    // Form controls
    this.email = new FormControl(null, [Validators.required, Validators.email]);
    this.password = new FormControl(null, [Validators.required, Validators.minLength(6)]);
    this.confirmPassword = new FormControl(null, Validators.required);
    this.name = new FormControl(null, Validators.required);
    this.phone = new FormControl(null, [Validators.required]);
    this.otpNumber = new FormControl(null);
    // Empty form group
    this.form = new FormGroup({});

    // Populates the form according to the page
    this.switchPage('signIn');
    var token = sessionStorage.getItem("ngx-webstorage|access_token");
    if(token){
      this.router.navigate(['/']);
    }
  }


  get currentPage() { return this.pages[this.page || 'signIn']; }

  public switchPage(page: pageTypes) {

    // Removes all the controls from the form group
    Object.keys(this.form.controls).forEach(control => {
      this.form.removeControl(control);
    });

    // Add the relevant controls to the form according to selected page
    switch (this.page = page) {

      case 'register':
        this.form.addControl('email', this.email);
        this.form.addControl('name', this.name);
        this.form.addControl('password', this.password);
        this.form.addControl('confirmPassword', this.confirmPassword);
        break;

      default:
      case 'signIn':
        this.form.addControl('email', this.email);
        this.form.addControl('password', this.password);
        break;

      case 'forgotPassword':
        this.form.addControl('email', this.email);
        break;

      case 'otp':
        this.form.addControl('phone', this.phone);
        break;

      case 'verifyotp':
        // this.form.addControl('phone', this.phone);
        this.form.addControl('otpNumber', this.otpNumber);
        break;
    }
  }

  public loginAction() {
    switch (this.page) {
      default:
      case 'signIn':
        this.signIn(this.email.value, this.password.value);
        break;

      case 'register':
        this.registerNew(this.email.value, this.password.value, this.confirmPassword.value, this.name.value);
        break;

      case 'forgotPassword':
        this.forgotPassword(this.email.value);
        break;

      case 'otp':
        this.sendOtp(this.phone.value);
        break;

      case 'verifyotp':
        var x = this.storage.retrieve('contact_number');
        this.verifyOtp(this.otpNumber.value, x);
    }
  }

  // Registering a new user with a email/password
  public registerNew(email: string, password: string, c_password: string, name: string) {
    this.btnDisable = true;
    this.auth.registerUser(email, password, c_password, name).subscribe(
      res => {
        console.log(res);
        this.storage.store('success', res.success)
        // this.router.navigate(['dashboard']);
        this.switchPage('signIn');
        this.toastr.success('Please Login','User Created Successfully');
        this.btnDisable = false;
      },
      errorMessage => {
        console.log(errorMessage);
        Swal.fire(errorMessage);
        this.btnDisable = false;
      }
    );
  }

  // Login a user with a email/password
  public signIn(email: string, password: string) {
    this.auth.LoginUser(email, password).subscribe(
      resData => {

        this.storage.store('access_token', resData.access_token);
        var accesskey = this.storage.retrieve('access_token');
        this.loadUsername(accesskey);

        this.headerComponent.ngOnInit();
        
        this.zone.run(() => { this.router.navigate(['/dashboard']); });
        // this.router.navigateByUrl('dashboard');  

        // add cart value on server
        this.schoolAddedTocart = JSON.parse(localStorage.getItem('schools'));
        var schools_id = [];
        var schoolscart = [];
        if (this.schoolAddedTocart != null) {
          for (var i = 0; i < this.schoolAddedTocart.length; i++) {
            if (this.schoolAddedTocart[i]['id']) {
              schools_id.push({
                school_id: this.schoolAddedTocart[i]['id']
              });
            }
          }
        }

        if (schools_id.length > 0 && accesskey != '') {
          var uri = environment.baseurl;
          let headers = new HttpHeaders();
          headers.append('Content-Type', 'application/json');
          headers.append('authorization', 'Bearer ' + accesskey);
          console.log(accesskey);
          var body = schools_id;
          this.httpClient.post(uri + "cart-orders", body, { headers })
            .subscribe(
              response => {
                this.headerComponent.ngOnInit();
                localStorage.removeItem('schools');
                console.log(response as any);
              },
              error => {
                console.log("Error", error);
              }
            );
        }
        var carttest = localStorage.getItem('cartvalue');
        if (carttest == 'true') {
          localStorage.removeItem('cartvalue')
          this.router.navigate(['cart'])
        } // add cart value on server
        
      },
      errorMessage => {
        Swal.fire(errorMessage);
        console.log(errorMessage)
        if(errorMessage = 'Please Check your mail and activate the user first'){
          this.checkStatus = 1;
        }
      }
    );
  }

resendCode(){
  var accesskey = this.storage.retrieve('access_token');
  var uri = environment.baseurl;
  let headers = new HttpHeaders();
  headers.append('Content-Type', 'application/json');
  headers.append('authorization', 'Bearer ' + accesskey);
  const body = {
    email: this.email.value
  };
  this.httpClient.post(uri + "resend-code", body, { headers })
    .subscribe(
      response => {
        this.checkStatus = 0;
        console.log(response);
      },
      error => {
        console.log("Error", error);
      }
    );
}

  public loadUsername (accesskey){
    if (accesskey) {
      var uri = environment.baseurl;
      let headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      headers.append('authorization', 'Bearer ' + accesskey);
      var body = '';
      this.httpClient.post(uri + "getCartDetails", body, { headers })
        .subscribe(
          response => {
            let total_cart_orders = (response as any).total_cart_orders
            if(total_cart_orders > 0){
              this.globals.cartcount = total_cart_orders;
            }
            this.globals.username = (response as any).full_name;
          },
          error => {
            console.log("Error", error);
          }
        );

    }
  }

  // Forgot Password
  public forgotPassword(email) {
    this.auth.ForgotPassword(email).subscribe(
      res => {
        console.log(res);
        Swal.fire(res.message);
      },
      errorMessage => {
        console.log(errorMessage);
        this.error = errorMessage;
      }
    );
  }

  //otp
  public sendOtp(contact_number) {
    this.auth.Otp(contact_number).subscribe(
      res => {
        console.log(res);
        this.toastr.success('OTP Send Succefully');
        this.storage.store('contact_number', contact_number)
        this.switchPage('verifyotp')
      },
      errorMessage => {
        console.log(errorMessage);
        Swal.fire('Number is not valid!');
      }
    )
  }

  //otp
  public verifyOtp(otp, contact_number) {
    this.auth.otpVerification(otp, contact_number).subscribe(
      res => {
        console.log(res)
        this.storage.store('access_token', res.access_token);
        var accesskey = this.storage.retrieve('access_token');
        this.loadUsername(accesskey);
        this.router.navigate(['dashboard']);
        this.headerComponent.ngOnInit();
        // add cart value on server
        this.schoolAddedTocart = JSON.parse(localStorage.getItem('schools'));
        var schools_id = [];
        var schoolscart = [];
        for (var i = 0; i < this.schoolAddedTocart.length; i++) {
          if (this.schoolAddedTocart[i]['id']) {
            schools_id.push({
              school_id: this.schoolAddedTocart[i]['id']
            });
          }
        }
        if (schools_id.length > 0 && accesskey != '') {
          // var uri = 'https://demo.softprodigyphp.in/Admitmykid/backend/public/api/';
          var uri = environment.baseurl;
          let headers = new HttpHeaders();
          headers.append('Content-Type', 'application/json');
          headers.append('authorization', 'Bearer ' + accesskey);
          console.log(accesskey);
          var body = schools_id;
          this.httpClient.post(uri + "cart-orders", body, { headers })
            .subscribe(
              response => {
                localStorage.removeItem('schools');
                console.log(response as any);
              },
              error => {
                console.log("Error", error);
              }
            );
        }
        // add cart value on server
      },
      errorMessage => {
        console.log(errorMessage);
        this.toastr.error('OTP Incorrect');
      }
    )
  }

  public signinsocial(token: string, provider: string) {
    // console.log(token+'==='+provider);
    this.auth.SocialLoginUser(token, provider).subscribe(
      resData => {
        console.log(resData.access_token);

        // Swal.fire(resData.error);
        this.storage.store('access_token', resData.access_token);
        var accesskey = this.storage.retrieve('access_token');
        this.loadUsername(accesskey);
        this.zone.run(() => { this.router.navigate(['/dashboard']); });
        // // this.router.navigate(['/dashboard'],{relativeTo:this.route})
        // this.router.navigateByUrl('/login', {skipLocationChange: true}).then(()=> {
        //   this.router.navigated = false;
        //   this.router.navigate(['dashboard']);
        // });
        // this.router.navigate(['dashboard']);
        // window.location.reload();
        // add cart value on server
        this.headerComponent.ngOnInit();
        this.schoolAddedTocart = JSON.parse(localStorage.getItem('schools'));
        //console.log(this.schoolAddedTocart);
        var schools_id = [];
        var schoolscart = [];
        if (this.schoolAddedTocart != null) {
          for (var i = 0; i < this.schoolAddedTocart.length; i++) {
            if (this.schoolAddedTocart[i]['id']) {
              schools_id.push({
                school_id: this.schoolAddedTocart[i]['id']
              });
            }
          }
        }

        if (schools_id.length > 0 && accesskey != '') {
          // var uri = 'https://demo.softprodigyphp.in/Admitmykid/backend/public/api/';
          var uri = environment.baseurl;
          let headers = new HttpHeaders();
          headers.append('Content-Type', 'application/json');
          headers.append('authorization', 'Bearer ' + accesskey);
          console.log(accesskey);
          var body = schools_id;
          this.httpClient.post(uri + "cart-orders", body, { headers })
            .subscribe(
              response => {
                localStorage.removeItem('schools');
                console.log(response as any);
              },
              error => {
                console.log("Error", error);
              }
            );
        }
        var carttest = localStorage.getItem('cartvalue');
        if (carttest == 'true') {
          localStorage.removeItem('cartvalue')
          this.router.navigate(['cart'])

        }
        // add cart value on server			

      },
      error => {
        console.log(error);
        Swal.fire(error);
      }
    );
  }

  ngOnInit() {
    $('[data-toggle="tooltip"]').tooltip()
    this.fbLibrary();
    this.googleSDK();
    this.checkStatus = Number(sessionStorage.getItem('checkStatus'));
    
  }
  
  ngOnDestroy(){
    sessionStorage.setItem('checkStatus', '0');
  }
  /************  Code for FB login ****************/

  fbLibrary() {
    (window as any).fbAsyncInit = function () {
      window['FB'].init({
        appId: '898468737563412',
        cookie: true,
        xfbml: true,
        version: 'v7.0'
      });
      window['FB'].AppEvents.logPageView();
    };
    (function (d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) { return; }
      js = d.createElement(s); js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));
  }
  login() {
    window['FB'].login((response) => {
      if (response.authResponse) {
        this.signinsocial(response.authResponse.accessToken, 'facebook');
        window['FB'].api('/me', {
          fields: 'last_name, first_name, email'
        }, (userInfo) => {
          // console.log("user information+'=='+userInfo.email");
        });
      } else {
        console.log('User login failed');
      }
    }, { scope: 'email' });
  }
  /******************************/
  /******************************/
  prepareLoginButton() {

    this.auth2.attachClickHandler(this.loginElement.nativeElement, {},
      (googleUser) => {
        let profile = googleUser.getBasicProfile();
        /*		
                console.log('Token || ' + googleUser.getAuthResponse().id_token);
                console.log('ID: ' + profile.getId());
                console.log('Name: ' + profile.getName());
                console.log('Image URL: ' + profile.getImageUrl());
                console.log('Email: ' + profile.getEmail());
          */
        console.log(googleUser.getAuthResponse().access_token);


        this.signinsocial(googleUser.getAuthResponse().access_token, 'google');
      }, (error) => {
        console.log(JSON.stringify(error, undefined, 2));
      });

  }
  googleSDK() {

    window['googleSDKLoaded'] = () => {
      window['gapi'].load('auth2', () => {
        this.auth2 = window['gapi'].auth2.init({
          client_id: '46831785544-3hcvku5nt6gdbamjoe44ppdo3m5i2cf2.apps.googleusercontent.com',
          cookiepolicy: 'single_host_origin',
          scope: 'profile email'
        });
        this.prepareLoginButton();
      });
    }

    (function (d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) { return; }
      js = d.createElement(s); js.id = id;
      js.src = "https://apis.google.com/js/platform.js?onload=googleSDKLoaded";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'google-jssdk'));

  }
  /******************************/
}
