import { Router } from '@angular/router'; 
import {Injectable} from '@angular/core';

export default class Utils {
    
    constructor(private router: Router) {  
      console.log('dfsdsfdsf');
    }  

    static isHome() {
        var home_check = false;
        //router = new Router();
        //this.router.location.path()
        if(location.pathname == '/'){
            home_check = true;  
        } 
        return home_check;
    }


    static isNotEmpty(value){
        if(value == null || value == undefined || value == "" || value.length == 0){
            return false;
        }else{
            return true;
        }
    }

    static ucfirst(value){
        return value.charAt(0).toUpperCase() + value.slice(1);
    }

    static getFullAddress(school_address){
        var address = [];
        if(this.isNotEmpty(school_address.postal_address)){
            address.push(school_address.postal_address);
        }

        if(this.isNotEmpty(school_address.district)){
            address.push(school_address.district);
        }
        if(this.isNotEmpty(school_address.city)){
            address.push(school_address.city);
        }
        if(this.isNotEmpty(school_address.state)){
            address.push(school_address.state);
        }
        if(this.isNotEmpty(school_address.pin_code)){
            address.push(school_address.pin_code);
        }

        return address.join(', ');
    }

}