import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class ProductsService {
  // uri = 'https://demo.softprodigyphp.in/Admitmykid/backend/public/api/products';
  constructor(private http: HttpClient) { }
  addProduct(ProductName, ProductDescription, ProductPrice) {
    console.log(ProductName, ProductDescription, ProductPrice);
    const obj = {
      ProductName,
      ProductDescription,
      ProductPrice
    };
    // this.http.post(`${this.uri}/add`, obj)
    this.http.post(`${environment.baseproducturl}/add`, obj)
      .subscribe(res => console.log('Done'));
  }
  getProducts() {
    return this
      .http
      .get(environment.baseproducturl);
  }
  editProduct(id) {
    return this
      .http
      // .get(`${this.uri}/edit/${id}`);
      .get(`${environment.baseproducturl}/edit/${id}`)
  }

  updateProduct(ProductName, ProductDescription, ProductPrice, id) {
    const obj = {
      ProductName,
      ProductDescription,
      ProductPrice
    };
    this
      .http
      // .post(`${this.uri}/update/${id}`, obj)
      .post(`${environment.baseproducturl}/update/${id}`, obj)
      .subscribe(res => console.log('Done'));
  }

  deleteProduct(id) {
    return this
      .http
      // .get(`${this.uri}/delete/${id}`);
      .get(`${environment.baseproducturl}/delete/${id}`);
  }
}  
