<section class="main">
	<div class="top-logo text-center">
		<img src="assets/img/logo.png" width="200" alt="logo" class="img-fluid">
	</div>
	<div *ngIf="payUMoney" style="margin: 10px;">
		<span style="font-size: 20px;">Redirecting</span>
	</div>
	<div class="container" id="container">
		<div class="row" id="someStuff">
			
			<div class="col-15">
				<div class="container" style="max-width: 650px;">
					<!-- form #checkoutForm="ngForm" novalidate (ngSubmit)="checkoutForm.form.valid && submitForm(checkoutForm)" -->
					<form [formGroup]="checkoutForm" (ngSubmit)="submitForm()">
						<div class="row">
							<div class="col-50">
								<h3>Billing Address</h3>
								<div class="row row-inner">
									<div class="col-50">
										<label for="firstName">First Name :</label>
										<input type="text" id="firstName" name="firstName" minlength="3" maxlength="15" formControlName="firstName" />
										<div *ngIf="submitted && f.firstName.errors" class="error">
											<span *ngIf="f.firstName.errors.required">Please enter first name.</span>
											<span *ngIf="f.firstName.errors.pattern">Only Alphabets allowed.</span>
											<span *ngIf="f.firstName.errors.minlength">Min 3 characters required</span>
											<span *ngIf="f.firstName.errors.maxlength">Min 15 characters allowed</span>
										</div>
									</div>
									
									<div class="col-50">
										<label for="lastName">Last Name :</label>
										<input type="text" id="lastName" name="lastName" minlength="3" maxlength="15" formControlName="lastName" />
										<div *ngIf="submitted && f.lastName.errors" class="error">
											<span *ngIf="f.lastName.errors.required">Please enter last name.</span>
											<span *ngIf="f.lastName.errors.pattern">Only Alphabets allowed.</span>
											<span *ngIf="f.lastName.errors.minlength">Min 3 characters required</span>
											<span *ngIf="f.lastName.errors.maxlength">Min 15 characters allowed</span>
										</div>
									</div>
								</div>
								
								<div class="row row-inner">
									<div class="col-50">
										<label for="email">Email :</label>
										<input type="text" name="email" formControlName="email" />
										<div *ngIf="submitted && f.email.errors" class="error">
											<span *ngIf="f.email.errors.required">Please enter email.</span>
											<span *ngIf="f.email.errors.pattern">Please enter valid email.</span>
										</div>
									</div>
									<div class="col-50">
										<label for="mobile">Mobile Number :</label>
										<input type="text" name="mobile" id="mobile" maxlength="10" formControlName="mobile" />
										<div *ngIf="submitted && f.mobile.errors" class="error">
											<span *ngIf="f.mobile.errors.required">Please enter 10 digit number.</span>
											<span *ngIf="f.mobile.errors.pattern">Please enter valid mobile number</span>
										</div>
									</div>
								</div>
								
								<div class="row row-inner">
									<div class="col-50">
										<label for="address">Address :</label>
										<input type="text" name="address" minlength="5" maxlength="190" id="address" formControlName="address" />
										<div *ngIf="submitted && f.address.errors" class="error">
											<span *ngIf="f.address.errors.required">Please enter address.</span>
											<span *ngIf="f.address.errors.minlength">Min 5 characters required</span>
											<span *ngIf="f.address.errors.maxlength">Min 190 characters allowed</span>
										</div>					
									</div>					
								</div>
								
								<div class="row row-inner">
									<div class="col-50">
										<label for="city">City :</label>
										<input type="text" name="city" minlength="3" maxlength="45" id="city" formControlName="city" />
										<div *ngIf="submitted && f.city.errors" class="error">
											<span *ngIf="f.city.errors.required">Please enter city.</span>
											<span *ngIf="f.city.errors.minlength">Min 3 characters required</span>
											<span *ngIf="f.city.errors.maxlength">Min 45 characters allowed</span>
										</div>
									</div>
								
									<div class="col-50">
										<label for="zip">Zipcode :</label>
										<input type="text" name="zip" id="zip" maxlength="6" formControlName="zip" />
										<div *ngIf="submitted && f.zip.errors" class="error">
											<span *ngIf="f.zip.errors.required">Please enter zip code.</span>
											<span *ngIf="f.zip.errors.pattern">Please enter 6 digit zipcode.</span>
										</div>
									</div>
								</div>
							</div>
						</div>
						<input type="hidden" value="{{totalamount}}" id="totalamount" name="totalamount" formControlName="totalamount" />
						<input type="submit" value="Proceed to Checkout" class="btn checkoutbtn">
					</form>
				</div>
			</div>
			<div class="col-30">
				<div class="container" *ngIf="total_cart_orders == 0" >
					<h4>Your cart is empty.<br> May be school close admission.</h4>
				</div>
				<div class="container" *ngIf="total_cart_orders > 0">
					<h4>Cart items </h4>
					<p *ngFor="let school of schoolAddedTocart; let q = index">
						<span>{{q +1}}. </span>
						<a href="javascript:;"> {{school.school_name}}</a>
						<span class="price" *ngIf="school.form_price != null"><i class="fa fa-inr" aria-hidden="true"></i> ₹{{school.form_price}}</span>
						<span class="price" *ngIf="school.form_price == null"><i class="fa fa-inr" aria-hidden="true"></i> ₹0</span>
					</p>
					<hr>
					<p>Total <span class="price" style="color:black"><b>₹{{totalamount}}</b></span></p>
				</div>
			</div>
			
		</div>
	</div>
</section>
<div><ngx-spinner type="ball-clip-rotate-multiple" size="medium"></ngx-spinner></div>