import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { NgxPaginationModule } from 'ngx-pagination';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SlimLoadingBarModule } from 'ng2-slim-loading-bar';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ProductsService } from './services/productService/products.service';
import { ProductAddComponent } from './product-add/product-add.component';
import { PageNotFoundComponent } from './pagenotfound/pagenotfound.component';
import { ProductGetComponent } from './product-get/product-get.component';
import { ProductEditComponent } from './product-edit/product-edit.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { AboutComponent } from './about/about.component';
import { ContactComponent } from './contact/contact.component';
import { PictureComponent } from './picture/picture.component';
import { FacilitiesComponent } from './facilities/facilities.component';
import { FeeStructureComponent } from './fee-structure/fee-structure.component';
import { SchoolListComponent } from './school-list/school-list.component';
import { AuthService } from './services/auth/auth.service';
import { LoaderComponent } from './shared/loader/loader.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AuthGuard } from './auth/auth.guard';
import { TokenInterceptorService } from './auth/token-interceptor.service'
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FrontComponent } from './front/front.component';
import { LoginModule } from './auth/login.module';
import { CartComponent } from './cart/cart.component';
import { Globals } from './globals';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { CheckoutComponent } from './checkout/checkout.component';
import { AdmissionFormComponent } from './admission-form/admission-form.component';
import { ThankyouComponent } from './thankyou/thankyou.component';
import { FailureComponent } from './failure/failure.component';
import { ResetpassworComponent } from './resetpasswor/resetpasswor.component';
import { NotificationComponent } from './notification/notification.component';
import { AdmissionFormAddComponent } from './admission-form-add/admission-form-add.component';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AdmissionFormViewComponent } from './admission-form-view/admission-form-view.component';
import { ReplacePipe } from './replace.pipe';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { FilterPipe } from './school-list/filter.pipe';
import { NgxMaskModule } from 'ngx-mask';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { NiceSelectModule } from "ng-nice-select";
import { ConfirmationComponent } from './confirmation/confirmation.component'; 
import { ConfirmationService } from './confirmation/confirmation.service';
import { ToastrModule } from 'ngx-toastr';
import { ResetPasswordService } from './resetpasswor/reset-password.service';
import { ResetPasswordModule } from './resetpasswor/reset-password.module';

@NgModule({ 
  declarations: [
    AppComponent,
    ProductAddComponent,
    ProductGetComponent,
    ProductEditComponent,
    HeaderComponent,
    PageNotFoundComponent,
    FooterComponent,
    AboutComponent,
    ContactComponent,
    PictureComponent,
    FacilitiesComponent,
    FeeStructureComponent,
    SchoolListComponent,
    LoaderComponent,
    DashboardComponent,
    FrontComponent,
    CartComponent,
    CheckoutComponent,
    AdmissionFormComponent,
    ThankyouComponent,
    FailureComponent,
    // ResetpassworComponent,
    NotificationComponent,
    AdmissionFormAddComponent,
    AdmissionFormViewComponent,
    ReplacePipe,
    FilterPipe,
    ConfirmationComponent
  ],
  imports: [
    ReactiveFormsModule,
    BrowserModule,
    FormsModule,
    NiceSelectModule, 
    AppRoutingModule,
    AngularMultiSelectModule,
    SlimLoadingBarModule,
    HttpClientModule,
    DeviceDetectorModule.forRoot(),
    NgxPaginationModule,
    NgbModule,
    LoginModule,
    ResetPasswordModule,
    BrowserAnimationsModule,
    BrowserModule,
    // import HttpClientModule after BrowserModule.  
    HttpClientModule,
    NgxWebstorageModule.forRoot(),
    NgxSpinnerModule,
    InfiniteScrollModule,
    NgxMaskModule.forRoot(),
    ToastrModule.forRoot()
  ],
  providers: [ProductsService, AuthGuard, AuthService, Globals,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true
    }, {
      provide: MatDialogRef, useValue: {}
    },
    { provide: MAT_DIALOG_DATA, useValue: [] },
    ConfirmationService,
    ResetPasswordService,
    HeaderComponent
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule { }
