import { Injectable , Inject} from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ResetPasswordService {
  baseUrl = environment.baseurl;  
  constructor(private http: HttpClient) { }

  resetPasword(data): Observable<any> {
    const headers = {'content-type': 'application/json'}  
    const body = JSON.stringify(data);
    return this.http.post(this.baseUrl + 'reset', body,{'headers':headers})
  }
  
  verifyUser(data): Observable<any> {
    return this.http.get(this.baseUrl + 'find/' + data)
  }
}
