import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService } from './confirmation.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.css'] 
})
export class ConfirmationComponent implements OnInit {
  confirmToken;

  constructor(private route: ActivatedRoute, private confirmationService: ConfirmationService,private router: Router, 
     private toastr: ToastrService ) { }

  ngOnInit(): void {
    this.confirmToken = this.route.snapshot.params['token'];
    console.log(this.confirmToken);
    if(this.confirmToken){
      const payload = {
        token : this.confirmToken
      }
      this.confirmationService.verifyUser(payload).subscribe(
        (res) => {
          this.toastr.success('User Activated Successfully');
          this.router.navigate(['login']);
        },
      (error) => {
        console.log(error);
        this.toastr.error(error.error.message);
        sessionStorage.setItem('checkStatus', '1');
        this.router.navigate(['login']);
      }
    );
    }
  }

}
