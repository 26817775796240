import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import Swal from 'sweetalert2';
import { Globals } from './globals';
import { SessionStorageService } from 'ngx-webstorage';
import { environment } from '../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class SearchService {

	cartcount: number = 0;
	count: number = 0;
	uri = environment.baseurl;
	constructor(private http: HttpClient, private globals: Globals, private session: SessionStorageService) { }
	
	getSchools(data) {
		const httpOptions = {
			headers: { 'Content-Type': 'application/json' },
			params: data
		};
		return this
		.http
		.post(`${this.uri}schools`, data);
	}

	getFilterRequiredData() {
		return this
		.http
		.get(`${this.uri}filter_required_data`);
	}

	getAreaByCity(city) {
		return this
		.http
		.get(`${this.uri}get_areas`, { params: { city: city } });
	}

	getSchool(id) {
		return this
		.http
		.get(`${this.uri}school/${id}`);
	}

	addProductToCart(schools: any) {
		var a;
		if (localStorage.getItem('schools') === null) {
			a = [];
		} else {
			a = JSON.parse(localStorage.getItem('schools'));
		}
		a.push(schools);
		localStorage.setItem("schools", JSON.stringify(a));
	}
  
	reProductToCart(id) {
		var a;
		a = JSON.parse(localStorage.getItem('schools'));
		for(var i = 0; i < a.length; i++) {
			if (a[i]['id'] == id) {
				a.splice(i,1);
			}    
		}
		// a.push(schools);
		localStorage.setItem("schools", JSON.stringify(a));
	}

	getProductFromCart() {
		return JSON.parse(localStorage.getItem('schools'));
	}

	removeAllProductFromCart(schools: any) {
		return localStorage.removeItem("schools");
	}
	
	removeProductFromCart(schools: any) {
	    return localStorage.setItem("schools", JSON.stringify(schools));
	}
}
