<section class="main common-form">
	<div class="top-logo text-center">
		<!-- <img src="assets/img/logo.png" width="200" alt="logo" class="img-fluid"> -->
		<h3>{{schoolName}} Common Admission Form</h3>
	</div>
	<div class="container">
		<form [formGroup]="myForm" id="myForm" name="myForm" (ngSubmit)="submitForm()" enctype='multipart/form-data'>
            <div class="main-outer col-md-12 m-auto p-0">
				<div class="form-top">
                    <div class="form-heading">
                        <h2>Child</h2>
                    </div>
                    <div class="form-outer-top">
                        <h5>Basic Information</h5>
							<div class="form-outer mb-1">
								<div class="input-tab login">
									<div class="position-relative">
                                        <label for="fname">First Name *:</label>
										<!-- input type="text" formControlName="fname" placeholder="First Name" id="fname" name="fname" value="{{ userDetails ? userDetails.fname : '' }}" -->
										<input type="text" formControlName="fname" [ngModel]="userDetails ? userDetails.fname : '' " id="fname" name="fname">
										<div *ngIf="(f['fname'].errors && !f['fname'].pristine) || (submitted && f['fname'].errors)" class="error-inp">
											<span *ngIf="(f['fname'].errors.required)" [hidden]="!f['fname'].errors.required">Please enter first name.</span>
                                            <span *ngIf="(f['fname'].errors.pattern)" [hidden]="!f['fname'].errors.pattern">Alphabets only/Remove Space</span>
										</div>
									</div>
								</div>
							<div class="input-tab login">
								<div class="position-relative">
                                    <label for="mname">Middle Name :</label>
									<!-- input type="text" formControlName="mname" placeholder="Middle Name" id="mname" name="mname" value="{{ userDetails ? userDetails.mname : '' }}" -->
									<input type="text" formControlName="mname" [ngModel]="userDetails ? userDetails.mname : '' " id="mname" name="mname">
									<div *ngIf="(f['mname'].errors && !f['mname'].pristine) || (submitted && f['mname'].errors)" class="error-inp">
										<span *ngIf="(f['mname'].errors.required)" [hidden]="!f['mname'].errors.required">Please enter middle name.</span>
                                        <span *ngIf="(f['mname'].errors.pattern)" [hidden]="!f['mname'].errors.pattern">Alphabets only/Remove Space</span>
									</div>
								</div>
							</div>
                            <div class="input-tab login">
                                <div class="position-relative">
                                    <label for="mname">Last Name *:</label>
                                    <!-- input type="text" formControlName="lname" placeholder="Last Name" id="lname" name="lname" value="{{ userDetails ? userDetails.lname : '' }}" -->
									<input type="text" formControlName="lname" [ngModel]="userDetails ? userDetails.lname : '' " id="lname" name="lname">
									<div *ngIf="(f['lname'].errors && !f['lname'].pristine) || (submitted && f['lname'].errors)" class="error-inp">
										<span *ngIf="(f['lname'].errors.required)" [hidden]="!f['lname'].errors.required">Please enter last name.</span>
                                        <span *ngIf="(f['lname'].errors.pattern)" [hidden]="!f['lname'].errors.pattern">Alphabets only/Remove Space</span>
									</div>
                                </div>
                            </div>
                            <div class="input-tab login">
								<div class="position-relative">
                                    <label for="dob">Date of birth *:</label>
                                    <!-- input type="text" formControlName="dob" placeholder="Date of birth" id="dob" name="dob" value="{{userDetails ? userDetails.dob : ''}}" -->
                                    <!-- <input type="text" formControlName="dob" [ngModel]="userDetails ? userDetails.dob : '' " placeholder="Date of birth" id="dob" name="dob"> -->
                                    <input id="dob" formControlName="dob" (click)="d.toggle()" placeholder="yyyy-mm-dd" name="dob" [ngModel]="userDetails ? userDetails.dob : ''" ngbDatepicker #d="ngbDatepicker" [minDate]="{year: 1980, month: 1, day: 1}" [maxDate]="maxDate">
									<div *ngIf="(f['dob'].errors && !f['dob'].pristine) || (submitted && f['dob'].errors)" class="error-inp">
										<span *ngIf="(f['dob'].errors.required)" [hidden]="!f['dob'].errors.required">Please enter date of birth.</span>
									</div>
                                    <span *ngIf="errorList" [hidden]="!errorList" class="main-error">{{errorList.dob | replace:'dob':'date of birth'}}</span>
								</div>
                            </div>
                            <div class="input-tab login">
                                <div class="position-relative">
                                    <label for="classname">Class of which admission sought *:</label>
                                    <!-- input type="text" formControlName="classname" placeholder="Class of which admission sought" id="classname" name="classname" value="{{userDetails ? userDetails.class : ''}}" -->
                                    <select id="classname" class="custom-select custom-select-lg mb-3"  name="classname" formControlName="classname">
                                        <!--<option selected value="">Whether police/defence personnel</option>-->
                                        <option value="">Please Select</option>
                                        <option value="Pre">Pre-Nursery</option>
                                        <option value="Pre">Nursery</option>
                                        <option value="Pre">LKG</option>
                                        <option value="Pre">UKG</option>
                                        <option value="1st">1st</option>
                                        <option value="2nd">2nd</option>
                                        <option value="3rd">3rd</option>
                                        <option value="4th">4th</option>
                                        <option value="5th">5th</option>
                                        <option value="6th">6th</option>
                                        <option value="7th">7th</option>
                                        <option value="8th">8th</option>
                                        <option value="9th">9th</option>
                                        <option value="10th">10th</option>
                                        <option value="11th">11th</option>
                                        <option value="12th">12th</option>
                                    </select>
									<div *ngIf="(f['classname'].errors && !f['classname'].pristine) || (submitted && f['classname'].errors)" class="error-inp">
										<span *ngIf="(f['classname'].errors.required)" [hidden]="!f['classname'].errors.required">Please enter class.</span>
									</div>
                                </div>
                            </div>
							<div class="input-tab login">
								<div class="position-relative">
                                    <label for="gender">Gender *:</label>
									<!-- input type="text" formControlName="gender" placeholder="Gender" id="gender" name="gender" value="{{userDetails ? userDetails.gender : ''}}" -->
                                    <!-- <input type="text" formControlName="gender" [ngModel]="userDetails ? userDetails.gender : '' " placeholder="Gender" id="gender" name="gender"> -->
                                    <select id="gender" class="custom-select custom-select-lg mb-3"  name="gender" formControlName="gender">
                                        <!--<option selected value="">Whether police/defence personnel</option>-->
                                        <option value="Male">Male</option>
                                        <option value="Female">Female</option>
                                    </select>
									<div *ngIf="(f['gender'].errors && !f['gender'].pristine) || (submitted && f['gender'].errors)" class="error-inp">
										<span *ngIf="(f['gender'].errors.required)" [hidden]="!f['gender'].errors.required">Please enter gender.</span>
									</div>
                                </div>
                            </div>
                            <div class="input-tab login">
                                <div class="position-relative">
                                    <label for="cast">Category *:</label>
                                    <!-- input type="text" formControlName="cast" placeholder="SC/ST/OBC" id="cast" name="cast" value="{{ userDetails ? userDetails.cast : ''}}" -->
                                    <!-- <input type="text" formControlName="cast" [ngModel]="userDetails ? userDetails.cast : '' " placeholder="SC/ST/OBC" id="cast" name="cast"> -->
                                    <select id="cast" class="custom-select custom-select-lg mb-3" name="cast" formControlName="cast">
                                        <!--<option selected value="">Whether police/defence personnel</option>-->
                                        <option value="General">General</option>
                                        <option value="SC">SC</option>
                                        <option value="ST">ST</option>
                                        <option value="OBC">OBC</option>
                                    </select>
									<div *ngIf="(f['cast'].errors && !f['cast'].pristine) || (submitted && f['cast'].errors)" class="error-inp">
										<span *ngIf="(f['cast'].errors.required)" [hidden]="!f['cast'].errors.required">Please enter cast.</span>
									</div>
									<input type="hidden" id="school_id" name="school_id" value="{{schooluid}}" />
									<input type="hidden" id="order_id" name="order_id" value="{{schooloid}}" />
                                </div>
                            </div>
                            <div class="input-tab login">
								<div class="position-relative">
                                    <label for="nationality">Nationality *:</label>
                                    <!-- input type="text" formControlName="nationality" placeholder="Nationality" id="nationality" name="nationality" value="{{userDetails ? userDetails.nationality : ''}}" -->
                                    <!-- <input type="text" formControlName="nationality" [ngModel]="userDetails ? userDetails.nationality : '' " placeholder="Nationality" id="nationality" name="nationality"> -->
                                    <select class="custom-select custom-select-lg mb-3" value="{{userDetails ? userDetails.nationality : 'India'}}" formControlName="nationality" id="nationality" name="nationality" (change)="setValues($event.target.value, 'child')">
                                        <option *ngFor="let nation of nationData">
                                            {{nation}}
                                        </option>
                                    </select>
 									<div *ngIf="(f['nationality'].errors && !f['nationality'].pristine) || (submitted && f['nationality'].errors)" class="error-inp">
										<span *ngIf="(f['nationality'].errors.required)" [hidden]="!f['nationality'].errors.required">Please enter nationality.</span>
									</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="form-outer-top">
                        <h5>Details of previous school</h5>
						<div class="form-outer mb-1">
							<div class="input-tab login">
								<div class="position-relative">
                                    <label for="school_name">Name of school :</label>
									<input type="text" formControlName="school_name" [ngModel]="userDetails ? userDetails.school_name : '' " id="school_name" name="school_name">
 									<div *ngIf="(f['school_name'].errors && !f['school_name'].pristine) || (submitted && f['school_name'].errors)" class="error-inp">
										<span *ngIf="(f['school_name'].errors.required)" [hidden]="!f['school_name'].errors.required">Please enter name of school.</span>
									</div>
                                    <span *ngIf="errorList" [hidden]="!errorList" class="main-error">{{errorList.school_name}}</span>
								</div>
							</div>
							<div class="input-tab login">
								<div class="position-relative">
                                    <label for="last_class_attended">Last class attended :</label>
                                    <select id="last_class_attended" class="custom-select custom-select-lg mb-3"  name="last_class_attended" formControlName="last_class_attended">
                                        <option value="">Please Select</option>
                                        <option value="Pre">Pre-Nursery</option>
                                        <option value="Pre">Nursery</option>
                                        <option value="Pre">LKG</option>
                                        <option value="Pre">UKG</option>
                                        <option value="1st">1st</option>
                                        <option value="2nd">2nd</option>
                                        <option value="3rd">3rd</option>
                                        <option value="4th">4th</option>
                                        <option value="5th">5th</option>
                                        <option value="6th">6th</option>
                                        <option value="7th">7th</option>
                                        <option value="8th">8th</option>
                                        <option value="9th">9th</option>
                                        <option value="10th">10th</option>
                                        <option value="11th">11th</option>
                                        <option value="12th">12th</option>
                                    </select>
									<div *ngIf="(f['last_class_attended'].errors && !f['last_class_attended'].pristine) || (submitted && f['last_class_attended'].errors)" class="error-inp">
										<span *ngIf="(f['last_class_attended'].errors.required)" [hidden]="!f['last_class_attended'].errors.required">Please enter last class attended.</span>
									</div>
                                    <span *ngIf="errorList" [hidden]="!errorList" class="main-error">{{errorList.last_class_attended}}</span>
								</div>
							</div>
							<div class="input-tab login">
								<div class="position-relative">
                                    <label for="address_of_school">Address of school :</label>
									<input type="text" id="address_of_school" name="address_of_school" value="{{ userDetails ? userDetails.address_of_school : '' }}">
                                    <div *ngIf="(f['address_of_school'].errors && !f['address_of_school'].pristine) || (submitted && f['address_of_school'].errors)" class="error-inp">
										<span *ngIf="(f['address_of_school'].errors.required)" [hidden]="!f['address_of_school'].errors.required">Please enter address of old school.</span>
									</div>
                                    <span *ngIf="errorList" [hidden]="!errorList" class="main-error">{{errorList.address_of_school}}</span>
                                </div>
							</div>

						</div>
                    </div>

                    <div class="form-outer-top">
                        <h5>Medical Information</h5>
                        <div class="form-outer mb-1">
                            <div class="input-tab login">
                                <div class="position-relative">
                                    <label for="blood_group">Blood Group *:</label>
                                    <!-- <input type="text" formControlName="blood_group" [ngModel]="userDetails ? userDetails.blood_group : ''" placeholder="Blood Group" id="blood_group" name="blood_group"> -->
                                    <select class="custom-select custom-select-lg mb-3" id="blood_group" name="blood_group" formControlName="blood_group">
                                        <option value="A+">A+</option>
                                        <option value="A-">A-</option> 
                                        <option value="B+">B+</option> 
                                        <option value="B-">B-</option> 
                                        <option value="O+">O+</option>
                                        <option value="O-">O-</option>
                                        <option value="AB+">AB+</option>
                                        <option value="AB-">AB-</option>
                                    </select>
                                    <div *ngIf="(f['blood_group'].errors && !f['blood_group'].pristine) || (submitted && f['blood_group'].errors)" class="error-inp">
										<span *ngIf="(f['blood_group'].errors.required)" [hidden]="!f['blood_group'].errors.required">Please enter blood group.</span>
                                    </div>
                                    <span *ngIf="errorList" [hidden]="!errorList" class="main-error">{{errorList.blood_group}}</span>
                                </div>
                            </div>
                            <!-- <div class="input-tab login">
                                
                                 <div>
									<div *ngIf="(f['special_child'].errors && !f['special_child'].pristine) || (submitted && f['special_child'].errors)" class="error-inp">
										<span *ngIf="(f['special_child'].errors.required)" [hidden]="!f['special_child'].errors.required">Please select special need child.</span>
									</div>
                                </div>  
                            </div> -->
                            <div class="input-tab login">
                                <div class="position-relative " style="display: flex; flex-direction: row; justify-content: space-between;">
                                    <!-- <input type="text" formControlName="special_child" [ngModel]="userDetails ? userDetails.special_child : ''" placeholder="Special need child" id="special_child" name="special_child"> -->
                                    
                                </div>
                                <div class="position-relative">
                                    <label for="details" style="width: 100%;
                                    justify-content: space-between;
                                    display: flex;">If yes then give details:
                                        
                                        <label class="special-text m-0">
                                            <b style="font-weight: normal;">
                                                Special need child: 
                                            </b>
                                            <input id="special_child" name="special_child" type="checkbox" style="width: 10%;" value="{{special_child}}" (click)="toggleValues('special')" [checked]="special_child">
                                            <span style="margin: 0;
                                            width: 14px;
                                            height: 14px;"></span>
                                        </label>
                                    </label>
                                    <input type="text" id="details" name="details" value="{{userDetails ? userDetails.details : ''}}" [disabled]="!special_child">
                                    <span *ngIf="errorList" [hidden]="!errorList" class="main-error">{{errorList.details}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

				<div class="form-top">
                    <div class="form-heading">
                        <h2>Father</h2>
                    </div>
                    <div class="form-outer-top">
                        <h5>Basic Information</h5>
                        <div class="form-outer mb-1">
                            <div class="input-tab login">
                                <div class="position-relative">
                                    <label for="father_name">Name *:</label>
                                    <input type="text" formControlName="father_name" [ngModel]="userDetails ? userDetails.father_name : ''" id="father_name" name="father_name">
                                    <div *ngIf="(f['father_name'].errors && !f['father_name'].pristine) || (submitted && f['father_name'].errors)" class="error-inp">
										<span *ngIf="(f['father_name'].errors.required)" [hidden]="!f['father_name'].errors.required">Please enter father name.</span>
									</div>
                                </div>
                            </div>
                            <div class="input-tab login">
                                <div class="position-relative">
                                    <label for="fnationality">Nationality *:</label>
                                    <!-- <input type="text" placeholder="Nationality" formControlName="fnationality" [ngModel]="userDetails ? userDetails.fnationality : ''" id="fnationality" name="fnationality"> -->
                                    <select class="custom-select custom-select-lg mb-3" id="fnationality" name="fnationality" value="{{userDetails ? userDetails.fnationality : 'India'}}" formControlName="fnationality" (change)="setValues($event.target.value, 'father')">
                                        <option *ngFor="let nation of nationData">
                                            {{nation}}
                                        </option>
                                    </select>
                                    <div *ngIf="(f['fnationality'].errors && !f['fnationality'].pristine) || (submitted && f['fnationality'].errors)" class="error-inp">
										<span *ngIf="(f['fnationality'].errors.required)" [hidden]="!f['fnationality'].errors.required">Please select Nationality.</span>
									</div>
                                </div>
                            </div>
                            <div class="input-tab login">
                                <div class="position-relative">
                                    <label for="highest_academic_qualification">Highest academic qualification:</label>
                                    <input type="text" id="highest_academic_qualification" name="highest_academic_qualification" value="{{ userDetails ? userDetails.highest_academic_qualification : '' }}">
                                    <span *ngIf="errorList" [hidden]="!errorList" class="main-error">{{errorList.highest_academic_qualification}}</span>
                                </div>
                            </div>
                            <div class="input-tab login">
                                <div class="position-relative">
                                    <label for="phone_1">Mobile Number *:</label>
                                    <input type="text" formControlName="phone_1" [pattern]="mobNumberPattern" maxlength="10" [ngModel]="userDetails ? userDetails.phone_1 : ''" name="phone_1" id="phone_1">
                                    <div *ngIf="(f['phone_1'].errors && !f['phone_1'].pristine) || (submitted && f['phone_1'].errors)" class="error-inp">
										<span *ngIf="(f['phone_1'].errors.required)" [hidden]="!f['phone_1'].errors.required">Please enter phone number.</span>
                                        <span *ngIf="(f['phone_1'].errors.pattern)" [hidden]="!f['phone_1'].errors.pattern">Please enter correct mobile number.</span>
                                    </div>
                                    <span *ngIf="errorList" [hidden]="!errorList" class="main-error">{{errorList.phone_1 | replace:'phone_1':'Mobile Number'}}</span>
                                    <span *ngIf="(f['phone_1'].errors?.pattern)" class="main-error"> Mobile number not valid</span>
                                </div>
                            </div>
                            <div class="input-tab login">
                                <div class="position-relative">
                                    <label for="phone_2">Alternate Number:</label>
                                    <input type="text" name="phone_2" id="phone_2" formControlName="phone_2" [ngModel]="userDetails ? userDetails.phone_2 : ''">
                                    <div *ngIf="(f['phone_2'].errors && !f['phone_2'].pristine) || (submitted && f['phone_2'].errors)" class="error-inp">
                                        <span *ngIf="(f['phone_2'].errors.pattern)" [hidden]="!f['phone_2'].errors.pattern">Please enter a valid phone number.</span>
                                    </div>
                                </div>
                            </div>
                            <div class="input-tab login">
                                <div class="position-relative">
                                    <label for="office_phone">Office phone :</label>
                                    <input type="text" name="office_phone" id="office_phone" formControlName="office_phone" [ngModel]="userDetails ? userDetails.office_phone : ''">
                                    <div *ngIf="(f['office_phone'].errors && !f['office_phone'].pristine) || (submitted && f['office_phone'].errors)" class="error-inp">
                                        <span *ngIf="(f['office_phone'].errors.pattern)" [hidden]="!f['office_phone'].errors.pattern">Please enter a valid phone number.</span>
                                    </div>
                                </div>
                            </div>
                            <div class="input-tab login">
                                <div class="position-relative">
                                    <label for="occupation">Occupation *:</label>
                                    <input type="text" id="occupation" name="occupation" formControlName="occupation" [ngModel]="userDetails ? userDetails.occupation : ''">
                                    <div *ngIf="(f['occupation'].errors && !f['occupation'].pristine) || (submitted && f['occupation'].errors)" class="error-inp">
										<span *ngIf="(f['occupation'].errors.required)" [hidden]="!f['occupation'].errors.required">Please enter occupation.</span>
                                    </div>
                                    <span *ngIf="errorList" [hidden]="!errorList" class="main-error">{{errorList.occupation}}</span>
                                </div>
                            </div>
                            <div class="input-tab login">
                                <div class="position-relative">
                                    <label for="address_of_occupation">Address of Occupation:</label>
                                    <input type="text" id="address_of_occupation" name="address_of_occupation" formControlName="address_of_occupation" [ngModel]="userDetails ? userDetails.address_of_occupation : ''">
                                    <!-- <div *ngIf="(f['address_of_occupation'].errors && !f['address_of_occupation'].pristine) || (submitted && f['address_of_occupation'].errors)" class="error-inp">
										<span *ngIf="(f['address_of_occupation'].errors.required)" [hidden]="!f['address_of_occupation'].errors.required">Please enter address of occupation.</span>
                                    </div> -->
                                    <span *ngIf="errorList" [hidden]="!errorList" class="main-error">{{errorList.address_of_occupation}}</span>
                                </div>
                            </div>
                            <div class="input-tab login">
                                <div class="position-relative">
                                    <label for="email_id">Email id *:</label>
                                    <input type="text" id="email_id" name="email_id" formControlName="email_id" [ngModel]="userDetails ? userDetails.email_id : ''">
                                    <div *ngIf="(f['email_id'].errors && !f['email_id'].pristine) || (submitted && f['email_id'].errors)" class="error-inp">
										<span *ngIf="(f['email_id'].errors.required)" [hidden]="!f['email_id'].errors.required">Please enter email address.</span>
                                        <span *ngIf="(f['email_id'].errors.pattern)" [hidden]="!f['email_id'].errors.pattern">Please enter valid email address.</span>
                                    </div>
                                    <span *ngIf="errorList" [hidden]="!errorList" class="main-error">{{errorList.email_id}}</span>
                                </div>
                            </div>
						</div>
					</div>
				</div>

                <div class="form-top">
                    <div class="form-heading">
                        <h2>Mother</h2>
                    </div>
                    <div class="form-outer-top">
                        <h5>Basic Information</h5>
						<div class="form-outer mb-1">
							<div class="input-tab login">
								<div class="position-relative">
                                    <label for="mother_name">Name *:</label>
									<input type="text" formControlName="mother_name" [ngModel]="userDetails ? userDetails.mother_name : ''" id="mother_name" name="mother_name">
									<div *ngIf="(f['mother_name'].errors && !f['mother_name'].pristine) || (submitted && f['mother_name'].errors)" class="error-inp">
										<span *ngIf="(f['mother_name'].errors.required)" [hidden]="!f['mother_name'].errors.required">Please enter mother name.</span>
									</div>
								</div>
							</div>
							<div class="input-tab login">
                                <div class="position-relative">
                                    <label for="mnationality">Nationality *:</label>
                                    <!-- <input type="text" formControlName="mnationality" [ngModel]="userDetails ? userDetails.mnationality : ''" placeholder="Nationality" id="mnationality" name="mnationality"> -->
                                    <select class="custom-select custom-select-lg mb-3" id="mnationality" name="mnationality" value="{{userDetails ? userDetails.mnationality : 'India' }}" (change)="setValues($event.target.value, 'mother')">
                                        <option *ngFor="let nation of nationData">
                                            {{nation}}
                                        </option>
                                    </select>                                    
                                    <div *ngIf="(f['mnationality'].errors && !f['mnationality'].pristine) || (submitted && f['mnationality'].errors)" class="error-inp">
										<span *ngIf="(f['mnationality'].errors.required)" [hidden]="!f['mnationality'].errors.required">Please enter nationality.</span>
									</div>
                                </div>
                            </div>
                            <div class="input-tab login">
                                <div class="position-relative">
                                    <label for="mhighest_academic_qualification">Highest academic qualification :</label>
                                    <input type="text" id="mhighest_academic_qualification" name="mhighest_academic_qualification" value="{{ userDetails ? userDetails.mhighest_academic_qualification : '' }}">
                                    <span *ngIf="errorList" [hidden]="!errorList" class="main-error">{{errorList.mhighest_academic_qualification | replace:'mhighest':'mother highest' }}</span>
                                </div>
                            </div>
                            <div class="input-tab login">
                                <div class="position-relative">
                                    <label for="mphone_1">Mobile Number:</label>
									<input type="text" formControlName="mphone_1" maxlength="10" [pattern]="mobNumberPattern"  [ngModel]="userDetails ? userDetails.mphone_1 : ''" id="mphone_1" name="mphone_1">
									<div *ngIf="(f['mphone_1'].errors && !f['mphone_1'].pristine) || (submitted && f['mphone_1'].errors)" class="error-inp">
                                        <!-- <span *ngIf="(f['mphone_1'].errors.required)" [hidden]="!f['mphone_1'].errors.required">Please enter phone number.</span> -->
                                        <span *ngIf="(f['mphone_1'].errors?.pattern)" class="main-error"> Mobile number not valid</span>
									</div>
                                </div>
                            </div>
                            <div class="input-tab login">
                                <div class="position-relative">
                                    <label for="mphone_2">Alternate Number :</label>
                                    <input type="text" id="mphone_2" name="mphone_2" formControlName="mphone_2" [ngModel]="userDetails ? userDetails.mphone_2 : ''">
                                    <div *ngIf="(f['mphone_2'].errors && !f['mphone_2'].pristine) || (submitted && f['mphone_2'].errors)" class="error-inp">
                                        <span *ngIf="(f['mphone_2'].errors.pattern)" [hidden]="!f['mphone_2'].errors.pattern">Please enter a valid phone number.</span>
                                    </div>
                                </div>
                            </div>
                            <div class="input-tab login">
                                <div class="position-relative">
                                    <label for="moffice_phone">Office phone :</label>
                                    <input type="text" id="moffice_phone" name="moffice_phone" formControlName="moffice_phone" [ngModel]="userDetails ? userDetails.moffice_phone : ''">
                                    <div *ngIf="(f['moffice_phone'].errors && !f['moffice_phone'].pristine) || (submitted && f['moffice_phone'].errors)" class="error-inp">
                                        <span *ngIf="(f['moffice_phone'].errors.pattern)" [hidden]="!f['moffice_phone'].errors.pattern">Please enter a valid phone number.</span>
                                    </div>
                                </div>
                            </div>
                            <div class="input-tab login">
                                <div class="position-relative">
                                    <label for="moccupation">Occupation :</label>
                                    <input type="text" id="moccupation" name="moccupation" value="{{ userDetails ? userDetails.moccupation : '' }}">
                                    <span *ngIf="errorList" [hidden]="!errorList" class="main-error">{{errorList.moccupation | replace:'moccupation':'mother occupation'}}</span>
                                </div>
                            </div>
                            <div class="input-tab login">
                                <div class="position-relative">
                                    <label for="maddress_of_occupation">Address of Occupation :</label>
                                    <input type="text" id="maddress_of_occupation" name="maddress_of_occupation" value="{{ userDetails ? userDetails.maddress_of_occupation : '' }}">
                                    <span *ngIf="errorList" [hidden]="!errorList" class="main-error">{{errorList.maddress_of_occupation | replace:'maddress':'address'}}</span>
                                </div>
                            </div>
                            <div class="input-tab login">
                                <div class="position-relative">
                                    <label for="memail_id">Email id :</label>
                                    <input type="text" id="memail_id" name="memail_id" formControlName="memail_id" [ngModel]="userDetails ? userDetails.memail_id : ''">
                                    <div *ngIf="(f['memail_id'].errors && !f['memail_id'].pristine) || (submitted && f['memail_id'].errors)" class="error-inp">
                                        <span *ngIf="(f['memail_id'].errors.pattern)" [hidden]="!f['memail_id'].errors.pattern">Please enter valid email address.</span>
                                    </div>
                                    <span *ngIf="errorList" [hidden]="!errorList" class="main-error">{{errorList.memail_id}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="form-top">
                    <div class="form-heading">
                        <h2>Address</h2>
                    </div>
                    <div class="form-outer-top">
                        <h5>Present Address</h5>
                        <div class="form-outer mb-1">
                            <div class="input-tab login">
                                <div class="position-relative">
                                    <label for="present_address">Address *:</label>
                                    <input type="text" formControlName="present_address" [ngModel]="userDetails ? userDetails.present_address : ''" id="present_address" name="present_address">
									<div *ngIf="(f['present_address'].errors && !f['present_address'].pristine) || (submitted && f['present_address'].errors)" class="error-inp">
										<span *ngIf="(f['present_address'].errors.required)" [hidden]="!f['present_address'].errors.required">Please enter present address.</span>
									</div>
                                </div>
                            </div>
                            <div class="input-tab login">
                                <div class="position-relative">
                                    <label for="city">City *:</label>
                                    <input type="text" formControlName="city" [ngModel]="userDetails ? userDetails.city : ''" id="city" name="city">
									<div *ngIf="(f['city'].errors && !f['city'].pristine) || (submitted && f['city'].errors)" class="error-inp">
										<span *ngIf="(f['city'].errors.required)" [hidden]="!f['city'].errors.required">Please enter city.</span>
									</div>                                    
                                </div>
                            </div>
                            <div class="input-tab login">
                                <div class="position-relative">
                                    <label for="state">State *:</label>
                                    <input type="text" formControlName="state" [ngModel]="userDetails ? userDetails.state : ''" required id="state" name="state">
									<div *ngIf="(f['state'].errors && !f['state'].pristine) || (submitted && f['state'].errors)" class="error-inp">
										<span *ngIf="(f['state'].errors.required)" [hidden]="!f['state'].errors.required">Please enter state.</span>
									</div>                                    
                                </div>
                            </div>
                            <div class="input-tab login">
								<div class="position-relative">
                                    <label for="pin_code">Pin code *:</label>
									<input type="text" maxlength="6" formControlName="pin_code" [ngModel]="userDetails ? userDetails.pin_code : ''" id="pin_code" name="pin_code">
									<div *ngIf="(f['pin_code'].errors && !f['pin_code'].pristine) || (submitted && f['pin_code'].errors)" class="error-inp">
										<span *ngIf="(f['pin_code'].errors.required)" [hidden]="!f['pin_code'].errors.required">Please enter pin code.</span>
                                        <span *ngIf="(f['pin_code'].errors.pattern)" [hidden]="!f['pin_code'].errors.pattern">Please enter a valid pincode.</span>
                                    </div>	
                                    <span *ngIf="errorList" [hidden]="!errorList" class="main-error">{{errorList.pin_code}}</span>								
								</div>
							</div>
                        </div>
                    </div>
                        
                    <div class="form-outer-top">
						<h5>Permanent Address
                            <label class="special-text" style="color:#000; font-weight: normal;">
                                <input type="checkbox" (click)="toggleValues('address')" [(ngModel)]="checkAddress" [ngModelOptions]="{standalone: true}">
                                <span></span>
                                Present Address same as Permanent Address:
                            </label>
                        </h5>
                        <div class="form-outer mb-1">
                            <div class="input-tab login">
                                <div class="position-relative">
                                    <label for="permanent_address">Address *:</label>
                                    <input type="text" formControlName="permanent_address" [ngModel]="userDetails ? userDetails.permanent_address : ''" id="permanent_address" name="permanent_address" [readonly]="checkAddress">
									<div *ngIf="(f['permanent_address'].errors && !f['permanent_address'].pristine) || (submitted && f['permanent_address'].errors)" class="error-inp">
										<span *ngIf="(f['permanent_address'].errors.required)" [hidden]="!f['permanent_address'].errors.required">Please enter permanent address.</span>
									</div>                                    
                                </div>
	                        </div>
                            <div class="input-tab login">
                                <div class="position-relative">
                                    <label for="p_city">City *:</label>
                                    <input type="text" formControlName="p_city" [ngModel]="userDetails ? userDetails.p_city : ''" id="p_city" name="p_city" [readonly]="checkAddress">
									<div *ngIf="(f['p_city'].errors && !f['p_city'].pristine) || (submitted && f['p_city'].errors)" class="error-inp">
										<span *ngIf="(f['p_city'].errors.required)" [hidden]="!f['p_city'].errors.required">Please enter city.</span>
									</div>
                                </div>
                            </div>
                            <div class="input-tab login">
                                <div class="position-relative">
                                    <label for="p_state">State *:</label>
                                    <input type="text" formControlName="p_state" [ngModel]="userDetails ? userDetails.p_state : ''" id="p_state" name="p_state" [readonly]="checkAddress">
									<div *ngIf="(f['p_state'].errors && !f['p_state'].pristine) || (submitted && f['p_state'].errors)" class="error-inp">
										<span *ngIf="(f['p_state'].errors.required)" [hidden]="!f['p_state'].errors.required">Please enter state.</span>
									</div>                                    
                                </div>
                            </div>
                            <div class="input-tab login">
								<div class="position-relative">
                                    <label for="p_pin_code">Pin code *:</label>
									<input type="text" maxlength="6" formControlName="p_pin_code" [ngModel]="userDetails ? userDetails.p_pin_code : ''" id="p_pin_code" name="p_pin_code" [readonly]="checkAddress">
									<div *ngIf="(f['p_pin_code'].errors && !f['p_pin_code'].pristine) || (submitted && f['p_pin_code'].errors)" class="error-inp">
										<span *ngIf="(f['p_pin_code'].errors.required)" [hidden]="!f['p_pin_code'].errors.required">Please enter pin code.</span>
                                        <span *ngIf="(f['p_pin_code'].errors.pattern)" [hidden]="!f['p_pin_code'].errors.pattern">Please enter a valid pincode.</span>
                                    </div>
                                    <span *ngIf="errorList" [hidden]="!errorList" class="main-error">{{errorList.pin_code}}</span>									
	                            </div>
							</div>
                        </div>
					</div>
                </div>

                <div class="form-top">
                    <div class="form-heading">
                        <h2>General</h2>
                    </div>
                    <div class="form-outer-top">
                        <h5>Guardian</h5>
                        <div class="form-outer mb-1">
                            <div class="input-tab login">
                                <div class="position-relative">
                                    <label for="gname">Name :</label>
                                    <input type="text" name="gname" id="gname" value="{{ userDetails ? userDetails.gname : '' }}">
                                    <span *ngIf="errorList" [hidden]="!errorList" class="main-error">{{errorList.gname}}</span>
                                </div>
                            </div>
                            <div class="input-tab login">
                                <div class="position-relative">
                                    <label for="relationship">Relationship :</label>
                                    <input type="text" id="relationship" name="relationship" value="{{ userDetails ? userDetails.relationship : '' }}">
                                    <span *ngIf="errorList" [hidden]="!errorList" class="main-error">{{errorList.relationship | replace:'gname':'guardian name'}}</span>
                                </div>
                            </div>
                            <div class="input-tab login">
                                <div class="position-relative">
                                    <label for="phone">Mobile Number :</label>
                                    <input type="text" id="phone" name="phone" maxlength="10" [pattern]="mobNumberPattern" formControlName="phone" [ngModel]="userDetails ? userDetails.phone : ''">
                                    <div *ngIf="(f['phone'].errors && !f['phone'].pristine) || (submitted && f['phone'].errors)" class="error-inp">
                                        <span *ngIf="(f['phone'].errors.pattern)" [hidden]="!f['phone'].errors.pattern">Please enter a correct mobile number</span>
                                    </div>
                                    <span *ngIf="errorList" [hidden]="!errorList" class="main-error">{{errorList.phone | replace:'gname':'guardian name'}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-outer-top c_2 c_2_custom">
                            <div class="input-tab login">
                                <div class="">
                                    <label for="parent_status"><h5>Parent Status :</h5></label>
                                      <label class="radio-inline" *ngFor="let parent_statu of parent_statuses;let i = index">
                                        <input type="radio" name="parent_status" [value]="parent_statu" formControlName="parent_status" [checked]="userDetails != undefined ? (userDetails.parent_status == parent_statu ? true : false) : (i == 0 ? true : false)" value="{{ parent_statu }}">{{ parent_statu }}
                                      </label>
                                      <div *ngIf="(f['parent_status'].errors && !f['parent_status'].pristine) || (submitted && f['parent_status'].errors)" class="error-inp">
										<span *ngIf="(f['parent_status'].errors.required)" [hidden]="!f['parent_status'].errors.required">Please enter parent status.</span>
									  </div>   
                                </div>
                            </div>
                            <div class="input-tab login hidden">
                                <div class="position-relative">
                                    <label for="windowname">Widow :</label>
                                    <input type="text" id="windowname" name="windowname" >
                                </div>
                            </div>
                            <div class="input-tab login hidden">
                                <div class="position-relative">
                                    <label for="windowername">Widower :</label>
                                    <input type="text" id="windowername" name="windowername" >
                                </div>
                            </div>
                            <div class="input-tab login">
                                    <div class="position-relative">
                                        <label for="whether_police_defence_personnel"><h5>Whether police/defence personnel :</h5></label>
                                        <label class="radio-inline" *ngFor="let police of whether_police_personnel; let i = index">
                                            <input type="radio" name="whether_police_defence_personnel" [value]="police == 'Yes' ? 1 : 0" formControlName="whether_police_defence_personnel" [checked]="1" value="{{ police }}"> {{ police }}
                                          </label>
                                    </div>
                             </div>
                    </div>
                    <div class="c_2">
                        <div class="form-outer-top">
                            <h5>Sibling studying in school ?</h5>
                            <div class="form-outer mb-1">
                                <div class="input-tab login">
                                    <div class="position-relative">
                                        <label for="sname">Sibling Name :</label>
                                        <input type="text" id="sname" name="sname" value="{{ userDetails ? userDetails.sname : '' }}">
                                        <span *ngIf="errorList" [hidden]="!errorList" class="main-error">{{errorList.sname | replace:'sname':'sibling name'}}</span>
                                    </div>
                                </div>
                                <div class="input-tab login">
                                    <div class="position-relative">
                                        <label for="sclass">Class :</label>

                                        <!-- <input type="text" id="sclass" name="sclass" value="{{ userDetails ? userDetails.sclass : '' }}"> -->
                                        <select id="sclass" class="custom-select custom-select-lg mb-3"  name="sclass" formControlName="sclass">
                                            <option value="">Please Select</option>
                                            <option value="Pre">Pre-Nursery</option>
                                            <option value="Pre">Nursery</option>
                                            <option value="Pre">LKG</option>
                                            <option value="Pre">UKG</option>
                                            <option value="1st">1st</option>
                                            <option value="2nd">2nd</option>
                                            <option value="3rd">3rd</option>
                                            <option value="4th">4th</option>
                                            <option value="5th">5th</option>
                                            <option value="6th">6th</option>
                                            <option value="7th">7th</option>
                                            <option value="8th">8th</option>
                                            <option value="9th">9th</option>
                                            <option value="10th">10th</option>
                                            <option value="11th">11th</option>
                                            <option value="12th">12th</option>
                                        </select>
                                        <span *ngIf="errorList" [hidden]="!errorList" class="main-error">{{errorList.sclass | replace:'sname':'sibling name'}}</span>
                                        <div *ngIf="(f['sclass'].errors && !f['sclass'].pristine) || (submitted && f['sclass'].errors)" class="error-inp"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-outer-top">
                            <h5>Area of interest where parents could enrich the school</h5>
                            <div class="form-outer mb-1">
                                <div class="input-tab login">
                                    <div class="position-relative">
                                        <label for="hobbies">Hobbies :</label>
                                        <select id="hobbies" class="custom-select custom-select-lg mb-3" name="hobbies" >
                                            <option selected value="">Select Hobbies</option>
                                            <option *ngFor="let hobi of hobbiesdata">{{ hobi }}</option>
                                        </select>
                                        <span *ngIf="errorList" [hidden]="!errorList" class="main-error">{{errorList.hobbies}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="form-top">
                <div class="form-outer-top">
                     <div class="form-group">
                        <label for="information">Any other information you would like to share:</label>
                         <textarea style="    border: 1px solid #ddd;
                         min-height: 150px;
                         padding: 20px 10px;
                         font-size: 13px;
                         border-radius: 6px;" class="form-control" rows="3" id="information" name="information" placeholder="Any other information you would like to share ?" >{{ userDetails ? userDetails.information : '' }}</textarea>
                         <span *ngIf="errorList" [hidden]="!errorList" class="main-error">{{errorList.information}}</span>
                    </div>
                </div>

				<div class="form-heading">
					<h2>Documents Upload </h2>
				</div>
				<h4 style="color:red;">Allow file type (PDF OR JPG) *</h4>
				<div class="form-row">
					<div class="form-group col-md-6">
						<label for="file-input">Date of birth certificate of child <img src="assets/img/up-arrow.png" class="ml-2"/></label>
                        <input formControlName="files" id="dobcertificate"  type="file"  class="form-control" (change)="onFileChange($event,'dobcertificate')">
                        <div *ngIf="(f['files'].touched && f['files'].invalid) || (submitted && f['files'].errors)" class="error-inp">
                           <span *ngIf="(f['files'].errors.required)">Please upload date of brith certificate.</span>
                        </div>
					</div>
					<div class="form-group col-md-6">
						<label for="file-input"> Voter ID <img src="assets/img/up-arrow.png" class="ml-2"/></label>
						<input  formControlName="files" id="votercard"  type="file"  class="form-control" (change)="onFileChange($event,'votercard')">
					</div>
				</div>
				<div class="form-row">
					<div class="form-group col-md-6">
						<label for="file-input">Aadhar Card <img src="assets/img/up-arrow.png" class="ml-2"/></label>
						<input  formControlName="files" id="aadharcard"  type="file"  class="form-control" (change)="onFileChange($event,'aadharcard')">
					</div>
					<div class="form-group col-md-6">
						<label for="file-input">Current Electricity/Telephone/Water Bill<img src="assets/img/up-arrow.png" class="ml-2"/></label>
						<input  formControlName="files" id="anybill"  type="file"  class="form-control" (change)="onFileChange($event,'anybill')">
					</div>
				</div>
				<div class="form-row">
					<div class="form-group col-md-6">
						<label for="file-input">School leaving certificate (if applicable) <img src="assets/img/up-arrow.png" class="ml-2"/></label>
						<input  formControlName="files" id="tc"  type="file"  class="form-control" (change)="onFileChange($event,'tc')">
					</div>
				</div>
				<div class="total-count d-flex justify-content-center text-center mt-3 mb-4">
					<button type="submit" class="btn">Submit</button>
				</div>
				</div>
			</div>
		</form>
	</div>
</section>
<div><ngx-spinner type="ball-clip-rotate-multiple" size="medium"></ngx-spinner></div>
