<div class="tab-panel-wrapper ">
    <div class="school-rating">
        <h3>Fee Structure</h3>
        <div class="col-md-10 m-auto fee-table">
            <table class="table">
                <thead>
                    <tr>
                        <th scope="col">Heads </th>
                        <th scope="col"> Pre Nur </th>
                        <th scope="col">Nursery</th>
                        <th scope="col"> I to V </th>
                        <th scope="col">IX & X</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th scope="row">Tuition Fee </th>
                        <td> 38800 </td>
                        <td> 50200 </td>
                        <td> 38800 </td>
                        <td>39200</td>
                    </tr>
                    <tr>
                        <th scope="row">Annual Fee </th>
                        <td> 38800 </td>
                        <td> 50200 </td>
                        <td> 38800 </td>
                        <td>39200</td>
                    </tr>
                    <tr>
                        <th scope="row">Development Fee </th>
                        <td> 38800 </td>
                        <td> 50200 </td>
                        <td> 38800 </td>
                        <td>39200</td>
                    </tr>
                    <tr>
                        <th scope="row">Safety & Security </th>
                        <td> 38800 </td>
                        <td> 50200 </td>
                        <td> 38800 </td>
                        <td>39200</td>
                    </tr>
                    <tr>
                        <th scope="row">Development Fee </th>
                        <td> 38800 </td>
                        <td> 50200 </td>
                        <td> 38800 </td>
                        <td>39200</td>
                    </tr>
                    <tr>
                        <th scope="row">Caution Money </th>
                        <td> 38800 </td>
                        <td> 50200 </td>
                        <td> 38800 </td>
                        <td>39200</td>
                    </tr>
                    <tr>
                        <th scope="row">Admission Fee* </th>
                        <td> 38800 </td>
                        <td> 50200 </td>
                        <td> 38800 </td>
                        <td>39200</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>