<section class="main dashboard-top-main">
	<div class="top-logo text-center">
		<img src="assets/img/logo.png" width="200" alt="logo" class="img-fluid">
	</div>
	<div class="container">
		<div class="main-outer">
			<div class="school-list cart table-responsive">
				<div class=" dashboard-table" *ngIf="notificationcount > 0">
					<ul class="thead-dark">
						<li class="col">School Details</li>
						<li class="col">Application Status</li>
						<li class="col">Next Step</li>
						<li class="col">PAYMENT STATUS</li>
						<li class="col">School Ack.ID</li>
					</ul>
					<div class="dashboard-content">
						<ul class="dashboard-list" *ngFor="let school of selectlist; let q = index">
							<li>
								<div class="school-list cart">
									<div class="list-inner">
										<div class="row">
											<div class="col-4 p-md-1">
												<div class="cart-school-img">
													<img src="assets/img/logo-3.gif" alt="logo" class="img-fluid">
												</div>
											</div>
											<div class="col-8 school-text p-0">
												<h2>{{school.school_name}}</h2>
												<p>{{school.postal_address}}, {{school.pin_code}}</p>
												<div class="location-text">
													<p><img src="assets/img/galobe.png" alt="" /><a href="{{school.website}}" target="_blank"> {{school.website}}</a></p>
												</div>
												<p>{{school.city}}</p>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li>
								<h2>CAF Pending</h2>
							</li>
							<li *ngIf="!school.ack_id">
								<h2 *ngIf="school.ack_id"><a href="/admission-form/{{school.schoolId}}/{{school.id}}">Edit</a></h2>
								<h2 *ngIf="!school.ack_id"><a href="/admission-form-add/{{school.schoolId}}/{{school.id}}">Add</a></h2>
							</li>
							<li class="order-number">
								<h2 *ngIf="school.order_number">Payment Successful.<br/>
									Payment ID : {{school.order_number}}</h2>
							</li>
							<li>
								<h2>{{school.ack_id}}</h2>
							</li>
						</ul>
					</div>
				</div>
				<div class="dashboard-table no-record" *ngIf="notificationcount == 0">
					<h2>No notification found.</h2>
				</div>
			</div>
		</div>
	</div>
</section>
