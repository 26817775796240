<div class="tab-panel-wrapper ">                              

    <div class="school-rating">
        <h3>SCHOOL FACILITY</h3>
        <div class="d-flex justify-content-between flex-wrap mb-4 prdct-images">
            <div class="text-center">
                <img src="assets/img/school-rating-1.png" alt="">
                <div class="name">Canteen</div>
            </div>
            <div class="text-center">
                <img src="assets/img/school-rating-2.png" alt="">
                <div class="name">Computer lab</div>
            </div>
            <div class="text-center">

                <img src="assets/img/school-rating-3.png" alt="">

                <div class="name">LIBRARY</div>
            </div>
            <div class="text-center">
                <img src="assets/img/school-rating-4.png" alt="">
                <div class="name">MEDICAL FACILITIES</div>
            </div>
            <div class="text-center">
                <img src="assets/img/school-rating-5.png" alt="">
                <div class="name">Classroom</div>
            </div>
            <div class="text-center">
                <img src="assets/img/school-rating-6.png" alt="">
                <div class="name">Grounds</div>
            </div>
        </div>
    </div>
</div>