import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { ResetPasswordService } from './reset-password.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-resetpasswor',
  templateUrl: './resetpasswor.component.html',
  styleUrls: ['./resetpasswor.component.css']
})
export class ResetpassworComponent implements OnInit {
  token: "";
  tokenAvail = false;
  verifiedToken;
  userEmail;
  userPassword;
  confirmPassword;
  errorMsg;

  constructor(private route: ActivatedRoute, private router: Router, private http: HttpClient,
    private resetPasswordService: ResetPasswordService, private toastr: ToastrService) {
    this.token = this.route.snapshot.params['id'];
  }
  authUrl = environment.baseurl;
  
  ngOnInit(): void {
    console.log(this.token);
    this.verifyUser();
  }

  verifyUser() {
    this.resetPasswordService.verifyUser(this.token).subscribe(
        (res) => {
          console.log(res);
          this.verifiedToken = res.token;
          this.userEmail = res.email;
          this.tokenAvail = true;
        },
      (error) => {
        console.log(error);
        this.toastr.error(error.error.message, 'Please try again');
        this.router.navigate(['login']);
      }
    );
  }

  resetPasword() {
    if (this.confirmPassword != this.userPassword) {
      this.errorMsg = 'Confirm Password does not match';
    }
    else{
      const payload ={
        email: this.userEmail,
        password: this.userPassword,
        token: this.token,
      }
      this.resetPasswordService.resetPasword(payload).subscribe(
        (res) => {
          console.log(res);
          this.toastr.success('Password Reset Successfully');
          this.router.navigate(['login']);
        },
      (error) => {
        console.log(error);
        this.toastr.error(error.error.message, 'Please try again');
      }
    );
    }
    
  }
  // resetpass(email, password, id) {
  //   return this.http.post<any>(this.authUrl + "reset", {
  //     email: email,
  //     password: password,
  //     id: id
  //   }).pipe()
  // }

}
