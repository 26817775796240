<!-- <section class="fisrt-screen d-md-flex  flex-column px-3" *ngIf="tokenAvail">
    <div class="login-top d-flex justify-content-center align-items-center  flex-column">
        <a rounterLink="/"> <img src="assets/img/logo.png" width="200" alt="logo" class="img-fluid"></a>
        <p>Please Enter New password </p>
        <form class="input-tab login">
            <div class="position-relative">
                <input type="text" required email name="email" ngModel #email="ngModel" placeholder="Email id">
                <i class="fa fa-user" aria-hidden="true"></i>
            </div>
            <div class="position-relative">
                <input type="password" name="password" ngModel #password="ngModel" required minlength="6"
                    placeholder="Password">
                <img src="assets/img/key.png" alt="logo" class="img-fluid key-icon">

            </div>
            <button type="submit" click=(resetpass)>resetpass</button>
        </form>
    </div>
</section> -->


<div class="fisrt-screen d-md-flex flex-column" *ngIf="tokenAvail">
    <div class="login-top d-flex justify-content-center align-items-center flex-column">
        <div class="headingMain">
            <p>Reset Password</p>
        </div>
        <div  class="input-tab login">
            <!-- EMAIL -->
            <mat-form-field appearance="outline" >
                <mat-label>Email Id</mat-label>
                <input matInput [(ngModel)]="userEmail" disabled type="email">
            </mat-form-field>

            <!-- PASSWORD -->
            <mat-form-field appearance="outline">
                <mat-label>Password</mat-label>
                <input matInput type="password" [(ngModel)]="userPassword">
            </mat-form-field>

            <!-- confirm PASSWORD -->
            <mat-form-field appearance="outline">
                <mat-label>Confirm Password</mat-label>
                <input matInput type="password" [(ngModel)]="confirmPassword">

            </mat-form-field>
            <p class="error-msg" *ngIf="errorMsg">{{errorMsg}}</p>
            <button (click)="resetPasword()" mat-stroked-button color="primary" type="submit" class="btn btn-srch mb-4">
                Reset Password
            </button>
    </div>
            
    </div>
</div>
