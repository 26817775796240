import { NgModule } from '@angular/core';
import { Routes, RouterModule, CanActivate } from '@angular/router';
import { ProductAddComponent } from './product-add/product-add.component';
import { ProductEditComponent } from './product-edit/product-edit.component';
import { ProductGetComponent } from './product-get/product-get.component';
import { FrontComponent } from './front/front.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { SchoolListComponent } from './school-list/school-list.component';
import { AuthGuard } from './auth/auth.guard';
import { AuthTestComponent } from './auth/auth-test.component';
import { CartComponent } from './cart/cart.component';
import { AdmissionFormComponent } from './admission-form/admission-form.component';
import { AdmissionFormViewComponent } from './admission-form-view/admission-form-view.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { ThankyouComponent } from './thankyou/thankyou.component';
import { FailureComponent } from './failure/failure.component';
import { ResetpassworComponent } from './resetpasswor/resetpasswor.component';
import { NotificationComponent } from './notification/notification.component';
import { AdmissionFormAddComponent } from './admission-form-add/admission-form-add.component';
import { PageNotFoundComponent } from './pagenotfound/pagenotfound.component';
import { ConfirmationComponent } from './confirmation/confirmation.component';
import { HeaderComponent } from './header/header.component';
const routes: Routes = [
  {
    path: 'login',
    component: AuthTestComponent
  },
  {
    path: 'dashboard',
    component: DashboardComponent, canActivate: [AuthGuard]
  },
  {
    path: 'notification',
    component: NotificationComponent, canActivate: [AuthGuard]
  },
  {
    path: 'product/create',
    component: ProductAddComponent
  },
  {
    path: 'edit/:id',
    component: ProductEditComponent
  },
  {
    path: 'products',
    component: ProductGetComponent
  },
  {
    path: 'schools',
    component: SchoolListComponent,
  },
  {
    path: '',
    component: FrontComponent
  },
  {
    path: 'cart',
    component: CartComponent
  },
  {
    path: 'checkout',
    component: CheckoutComponent
  },
  {
    path: 'thankyou',
    component: ThankyouComponent, canActivate: [AuthGuard]
  },
  {
    path: 'confirmation/:token',
    component: ConfirmationComponent
  },
  {
    path: 'failure',
    component: FailureComponent, canActivate: [AuthGuard]
  },
  {
    path: 'reset/edit/:id',
    component: ResetpassworComponent
  },
  {
    path: 'admission-form/:id/:oid',
    component: AdmissionFormComponent
  },
  {
    path: 'admission-form-view/:id/:oid',
    component: AdmissionFormViewComponent
  },
  {
    path: 'admission-form-add/:id/:oid',
    component: AdmissionFormAddComponent
  },
  {
    path: '404', component: PageNotFoundComponent
  },
  {
    path: '**', redirectTo: '/404'
  }

];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }



