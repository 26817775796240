import { Component, OnInit } from "@angular/core";
import { AuthService } from "../services/auth/auth.service";
import { AuthGuard } from "../auth/auth.guard";
import { SharedService } from "../services/updateCart";
import { Globals } from "../globals";
import { SelectedSchool } from "../Models/Product.Model";
import { SearchService } from "../search.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { SessionStorageService } from "ngx-webstorage";
import { environment } from "../../environments/environment";
declare var $: any;

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"],
})
export class HeaderComponent implements OnInit {
  public schoolAddedTocart: SelectedSchool[];
  portaluri = environment.portalurl;

  constructor(
    private session: SessionStorageService,
    public auth: AuthService,
    private globals: Globals,
    readonly guard: AuthGuard,
    private sharedService: SharedService,
    private httpClient: HttpClient,
    private ss: SearchService
  ) {
    this.globals = globals;
  }

  ngOnInit() {
    // var uri = 'https://demo.softprodigyphp.in/Admitmykid/backend/public/api/';
    var uri = environment.baseurl;
    var access_token = this.session.retrieve("access_token");
    if (access_token) {
      let headers = new HttpHeaders();
      headers.append("Content-Type", "application/json");
      headers.append("authorization", "Bearer " + access_token);
      var body = "";
      this.httpClient.post(uri + "getCartDetails", body, { headers }).subscribe(
        (response) => {
          console.log((response as any).full_name);
          this.globals.cartcount = (response as any).total_cart_orders;
          this.globals.username = (response as any).full_name;
          console.log((response as any).contact_number);
          if((response as any).full_name == ""){
          	this.globals.username = "User";
          	sessionStorage.setItem('userName', this.globals.username)
          }
        },
        (error) => {
          console.log("Error", error);
        }
      );
    } else {
      this.schoolAddedTocart = this.ss.getProductFromCart();
      if (this.schoolAddedTocart != null) {
        this.globals.cartcount = this.schoolAddedTocart.length;
      } else {
        this.globals.cartcount = 0;
      }
    }

    var uri = environment.baseurl;
    var access_token = this.session.retrieve("access_token");
    if (access_token) {
      let headers = new HttpHeaders();
      headers.append("Content-Type", "application/json");
      headers.append("authorization", "Bearer " + access_token);
      var body = "";

      this.httpClient.post(uri + "get_not_sch", body, { headers }).subscribe(
        (response) => {
          (this.globals.notificationcount = (response as any).notification_count),
			(this.globals.username = (response as any).username);
			if((response as any).username == null){
				this.globals.username = "User";
			}
        },
        (error) => {
          console.log("Error", error);
        }
      );
    } else {
      this.globals.notificationcount = 0;
    }

    if (!this.auth.loggedIn() && this.schoolAddedTocart != null) {
      localStorage.setItem("cartvalue", "true");
    }

    $(window).scroll(function () {
      var scroll = $(window).scrollTop();

      if (scroll >= 100) {
        $(".top-nav").addClass("darkHeader");
      } else {
        $(".top-nav").removeClass("darkHeader");
      }
    });
  }
}
