<div class="tab-panel-wrapper ">

    <div class="school-rating">
        <h3>Contact Us</h3>
        <div class="row">
            <div class="text-center col-6 col-sm-4 contact-tab-text">
                <img src="assets/img/call.png" alt="">
                <div class="contact-heading">Call Us</div>
                <div class="contact-text">0172 2222222</div>
                <div class="contact-text">0172 2222222</div>
            </div>
            <div class="text-center col-6 col-sm-4 contact-tab-text">
                <img src="assets/img/mail.png" alt="">
                <div class="contact-heading">Email</div>
                <div class="contact-text">stephenschandigarh@gmail.com</div>
            </div>
            <div class="text-center col-12 col-sm-4 contact-tab-text">
                <img src="assets/img/world.png" alt="">
                <div class="contact-heading">Visit us</div>
                <div class="contact-text">www.stephenschandigarh.com</div>
            </div>
        </div>
    </div>
</div>