import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { SelectedSchool } from '../Models/Product.Model';
import { SearchService } from '../search.service';
import { SharedService } from '../services/updateCart';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Globals } from '../globals';
import { SessionStorageService } from 'ngx-webstorage';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { AbstractControl, ValidatorFn, FormGroup, FormControl, Validators, FormArray, FormBuilder} from '@angular/forms';
import { environment } from '../../environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';
import { Nationality } from '../shared/nationality';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { single } from 'rxjs/operators';
@Component({
  selector: 'app-admission-form',
  templateUrl: './admission-form.component.html',
  styleUrls: ['./admission-form.component.css']
})
export class AdmissionFormComponent implements OnInit {
	schoolMainName;
	userDetails;
	childDate: string;
	schooluid;
	schooloid;
	res;
	nationData = Nationality;
	checkAddress: any = 1;
	files:string  []  =  [];
	responseform;
	hobbiesdata: any = ['Music/ Dance/ Drama', 'Media/PR', 'Academic', 'Medical', 'Sports', 'Art & Craft', 'Community', 'Programme', 'Others'];
	parent_statuses: string[] = ['Married', 'Single/Divorce', 'Widow', 'Widower','Any Other'];
	date_of_birth_certificate_of_child: string = '';
	proof_of_residence: string ='';
	proof_types: string ='';
	proof_of_sibling: string ='';
	school_leaving_certificate: string ='';

	myForm = new FormGroup({
		fname: new FormControl('', [Validators.required]),
		mname: new FormControl(''),
		lname: new FormControl('', [Validators.required]),
		dob: new FormControl('', [Validators.required]),
		classname: new FormControl('', [Validators.required]),
		gender: new FormControl('', [Validators.required]),
		cast: new FormControl('', [Validators.required]),
		nationality: new FormControl('', [Validators.required]),
		mnationality: new FormControl('', [Validators.required]),
		fnationality: new FormControl('', [Validators.required]),
		last_class_attended: new FormControl(''),
		dobcertificate: new FormControl(''),
		permanent_address: new FormControl('', [Validators.required]),
		blood_group: new FormControl('', [Validators.required]),
		p_city: new FormControl('', [Validators.required]),
		p_state: new FormControl('', [Validators.required]),
		p_pin_code: new FormControl('', [Validators.required]),
		//files: new FormControl('', [Validators.required]),
	});
	
	constructor(private ss: SearchService, private globals: Globals, private session: SessionStorageService, private httpClient: HttpClient, private router: Router, private sharedService: SharedService,private route: ActivatedRoute, private spinner: NgxSpinnerService, private el: ElementRef) { 
	}
	
	ngOnInit(){
		this.route.params.subscribe(params => {
			this.schooluid=params['id'];
			this.schooloid=params['oid'];
			// alert(this.schooloid);
		});

		var access_token = this.session.retrieve('access_token');
		if (!access_token) {
			this.router.navigate(['dashboard']);
		}
		
		// var uri = 'https://demo.softprodigyphp.in/Admitmykid/backend/public/api/';
		 var uri = environment.baseurl;
		let headers = new HttpHeaders();
		headers.append('Content-Type', 'application/json');
		headers.append('Authorization', 'Bearer ' + access_token);
		var body = '';
		
		const school_id = this.schooluid;
        const order_id = this.schooloid;
        
		this.httpClient.post(uri + "editUserForm?school_id="+school_id+"&order_id="+order_id, body, { headers })
		.subscribe(
			response => {
				this.res=(response as any);
				this.userDetails=this.res.details;
				this.schoolMainName=this.res;
				if (this.userDetails.length) {
					this.setDates();
					this.patchValues(this.userDetails[0]);
				}
			},
			error => {
				console.log("Error", error);
			}
		);	
		setTimeout(() => {
			var element = <HTMLInputElement> document.getElementById('special_child');
			this.userDetails.map(val => {
				if (val.special_child === 1) {
					element.checked = true;
				} else if (val.special_child === 0) {
					element.checked = false;
				}
			});
			if (this.userDetails.length) {
				const childNationality = (document.getElementById('nationality')) as HTMLSelectElement;
				(<HTMLSelectElement>childNationality).value = this.myForm.controls['nationality'].value;
				const fatherNationality = (document.getElementById('fnationality')) as HTMLSelectElement;
				(<HTMLSelectElement>fatherNationality).value = this.userDetails[0]['fnationality'];
				const motherNationality = (document.getElementById('mnationality')) as HTMLSelectElement;
				(<HTMLSelectElement>motherNationality).value = this.userDetails[0]['mnationality'];
				const bloodGroup = (document.getElementById('blood_group')) as HTMLSelectElement;
				(<HTMLSelectElement>bloodGroup).value = this.userDetails[0]['blood_group'];
				const hobby = <HTMLSelectElement>document.getElementById('hobbies');
				(<HTMLSelectElement>hobby).value = this.userDetails[0]['hobbies'];
				if (this.userDetails[0]['whether_police_defence_personnel'] === 1) {
					const personnel = <HTMLSelectElement>document.getElementById("whether_police_defence_personnel");
					(<HTMLSelectElement>personnel).value = "One";
				} else if (this.userDetails[0]['whether_police_defence_personnel'] === 2) {
					const personnel = <HTMLSelectElement>document.getElementById("whether_police_defence_personnel");
					(<HTMLSelectElement>personnel).value = "Two";
				} else if (this.userDetails[0]['whether_police_defence_personnel'] === 3) {
					const personnel = <HTMLSelectElement>document.getElementById("whether_police_defence_personnel");
					(<HTMLSelectElement>personnel).value = "Three";
				}
			}
		}, 500)	
		
	}

	patchValues(values) {
		this.myForm.patchValue({
			fname: values.fname,
			mname: values.mname,
			lname: values.lname,
			gender: values.gender,
			classname: values.class,
			last_class_attended: values.last_class_attended,
			cast: values.cast,
			nationality: values.nationality,
			mnationality: values.mnationality,
			fnationality: values.fnationality,
			// dobcertificate: values.date_of_birth_certificate_of_child
		});
	}

	setValues(value) {
		this.myForm.controls['nationality'].setValue(value)
	}

	toggleValues(value) {
		if (value === 'special') {
			this.userDetails.map(val => {
				val.special_child = !val.special_child
				if (!val.special_child) {
					val.details = ""
					const specialChildInput = <HTMLInputElement>document.getElementById('details');
					specialChildInput.value = '';
				}
			})
			const specialChildInput = <HTMLInputElement>document.getElementById('details');
			const specialChildInputValue = specialChildInput.value;
			const specialChildCheckbox = <HTMLInputElement>document.getElementById('special_child');
			const specialChildCheckboxValue = specialChildCheckbox.value;
		} else if  (value === 'address') {
			this.checkAddress = !this.checkAddress;
			const presentAddress = <HTMLInputElement>document.getElementById('present_address')
			const presentAddressValue = presentAddress.value;
			const presentCity = <HTMLInputElement>document.getElementById('city')
			const presentCityValue = presentCity.value;
			const presentState = <HTMLInputElement>document.getElementById('state')
			const presentStateValue = presentState.value;
			const presentPinCode = <HTMLInputElement>document.getElementById('pin_code')
			const presentPinCodeValue = presentPinCode.value;
			if (this.checkAddress) {
				this.myForm.controls['permanent_address'].setValue(presentAddressValue);
				this.myForm.controls['p_city'].setValue(presentCityValue);
				this.myForm.controls['p_state'].setValue(presentStateValue);
				this.myForm.controls['p_pin_code'].setValue(presentPinCodeValue);
			} else {
				this.myForm.controls['permanent_address'].setValue('');
				this.myForm.controls['p_city'].setValue('');
				this.myForm.controls['p_state'].setValue('');
				this.myForm.controls['p_pin_code'].setValue('');
			}
		}
	}

	setDates() {
		//set date for student
		this.userDetails.map(val => {
			let dated_child = val.dob.split('-');
			let year_child = +dated_child[0];
			let month_child = dated_child[1].split('');
			if (month_child[0] === "0") {
				month_child = +month_child[1];
			}else{
				month_child = parseInt(month_child.join(''));
			}
			let date_child = +dated_child[2];
			val.dob = new NgbDate(year_child, month_child, date_child);
		})
	}
 	 
	get f(){
		return this.myForm.controls;
	}
	
	onFileChange(event,docs) {
		if (event.target.files.length > 0) {
			for  (var i =  0; i <  event.target.files.length; i++)  {  
				this.files.push(event.target.files[i]);
				if(docs=="dobcertificate"){
					this.date_of_birth_certificate_of_child = event.target.files[i].name;
				} else if(docs=="votercard"){
					this.proof_of_residence = event.target.files[i].name; 
				}else if(docs=="aadharcard"){
					this.proof_types = event.target.files[i].name; 
				}else if(docs=="anybill"){
					this.proof_of_sibling = event.target.files[i].name; 
				}else if(docs=="tc"){
					this.school_leaving_certificate = event.target.files[i].name; 
				}
				console.log(this.date_of_birth_certificate_of_child);
			}
		}
	}
	
	/*
	onFileChange(event)  {
		for  (var i =  0; i <  event.target.files.length; i++)  {  
			this.files.push(event.target.files[i]);
		}
    }
	*/
	
	private scrollToFirstInvalidControl() {
		const firstInvalidControl: HTMLElement = this.el.nativeElement.querySelector(
			"form .ng-invalid"
		);

		firstInvalidControl.focus(); 
	}
	
	// add adminission detail
	submitForm() {
	if (this.myForm.invalid) {
		this.scrollToFirstInvalidControl();
		return;
	}

	if (this.myForm.valid) {
		this.spinner.show();
		const formData =  new FormData();
		
		for  (var i =  0; i <  this.files.length; i++)  {
			formData.append("files[]",  this.files[i]);
		} 

		const fnameElement: HTMLInputElement = document.getElementById('fname') as HTMLInputElement
		const fname: string = fnameElement.value;
		const mnameElement: HTMLInputElement = document.getElementById('mname') as HTMLInputElement
		const mname: string = mnameElement.value;
		const lnameElement: HTMLInputElement = document.getElementById('lname') as HTMLInputElement
		const lname: string = lnameElement.value;
		const dobElement: HTMLInputElement = document.getElementById('dob') as HTMLInputElement
		const dob: string = dobElement.value;
		const classnameElement: HTMLInputElement = document.getElementById('classname') as HTMLInputElement
		const classname: string = classnameElement.value;
		const genderElement: HTMLInputElement = document.getElementById('gender') as HTMLInputElement
		const gender: string = genderElement.value;
		const castElement: HTMLInputElement = document.getElementById('cast') as HTMLInputElement
		const cast: string = castElement.value;
		//const school_idElement: HTMLInputElement = document.getElementById('school_id') as HTMLInputElement
		//const school_id: string = school_idElement.value;
		const nationalityElement: HTMLInputElement = document.getElementById('nationality') as HTMLInputElement
		const nationality: string = nationalityElement.value;
		const school_nameElement: HTMLInputElement = document.getElementById('school_name') as HTMLInputElement
		const school_name: string = school_nameElement.value;
		const last_class_attendedElement: HTMLInputElement = document.getElementById('last_class_attended') as HTMLInputElement
		const last_class_attended: string = last_class_attendedElement.value;
		const address_of_schoolElement: HTMLInputElement = document.getElementById('address_of_school') as HTMLInputElement
		const address_of_school: string = address_of_schoolElement.value;
		const blood_groupElement: HTMLInputElement = document.getElementById('blood_group') as HTMLInputElement
		const blood_group: string = blood_groupElement.value;
		const special_childElement: HTMLInputElement = document.getElementById('special_child') as HTMLInputElement
		let special_child: string = special_childElement.value;
		const detailsElement: HTMLInputElement = document.getElementById('details') as HTMLInputElement
		const details: string = detailsElement.value;
		const father_nameElement: HTMLInputElement = document.getElementById('father_name') as HTMLInputElement
		const father_name: string = father_nameElement.value;
		const fnationalityElement: HTMLInputElement = document.getElementById('fnationality') as HTMLInputElement
		const fnationality: string = fnationalityElement.value;
		const highest_academic_qualificationElement: HTMLInputElement = document.getElementById('highest_academic_qualification') as HTMLInputElement
		const highest_academic_qualification: string = highest_academic_qualificationElement.value;
		const phone_1Element: HTMLInputElement = document.getElementById('phone_1') as HTMLInputElement
		const phone_1: string = phone_1Element.value;
		const phone_2Element: HTMLInputElement = document.getElementById('phone_2') as HTMLInputElement
		const phone_2: string = phone_2Element.value;
		const office_phoneElement: HTMLInputElement = document.getElementById('office_phone') as HTMLInputElement
		const office_phone: string = office_phoneElement.value;
		const occupationElement: HTMLInputElement = document.getElementById('occupation') as HTMLInputElement
		const occupation: string = occupationElement.value;
		const address_of_occupationElement: HTMLInputElement = document.getElementById('address_of_occupation') as HTMLInputElement
		const address_of_occupation: string = address_of_occupationElement.value;
		const email_idElement: HTMLInputElement = document.getElementById('email_id') as HTMLInputElement
		const email_id: string = email_idElement.value;
		const mother_nameElement: HTMLInputElement = document.getElementById('mother_name') as HTMLInputElement
		const mother_name: string = mother_nameElement.value;
		const mnationalityElement: HTMLInputElement = document.getElementById('mnationality') as HTMLInputElement
		const mnationality: string = mnationalityElement.value;
		const maqElement: HTMLInputElement = document.getElementById('mhighest_academic_qualification') as HTMLInputElement
		const mhighest_academic_qualification: string = maqElement.value;
		const mphone_1Element: HTMLInputElement = document.getElementById('mphone_1') as HTMLInputElement
		const mphone_1: string = mphone_1Element.value;
		const mphone_2Element: HTMLInputElement = document.getElementById('mphone_2') as HTMLInputElement
		const mphone_2: string = mphone_2Element.value;
		const moffice_phoneElement: HTMLInputElement = document.getElementById('moffice_phone') as HTMLInputElement
		const moffice_phone: string = moffice_phoneElement.value;
		const moccupationElement: HTMLInputElement = document.getElementById('moccupation') as HTMLInputElement
		const moccupation: string = moccupationElement.value;
		const maddress_of_occupationElement: HTMLInputElement = document.getElementById('maddress_of_occupation') as HTMLInputElement
		const maddress_of_occupation: string = maddress_of_occupationElement.value;
		const memail_idElement: HTMLInputElement = document.getElementById('memail_id') as HTMLInputElement
		const memail_id: string = memail_idElement.value;
		const present_addressElement: HTMLInputElement = document.getElementById('present_address') as HTMLInputElement
		const present_address: string = present_addressElement.value;
		const cityElement: HTMLInputElement = document.getElementById('city') as HTMLInputElement
		const city: string = cityElement.value;
		const stateElement: HTMLInputElement = document.getElementById('state') as HTMLInputElement
		const state: string = stateElement.value;
		const pin_codeElement: HTMLInputElement = document.getElementById('pin_code') as HTMLInputElement
		const pin_code: string = pin_codeElement.value;
		const permanent_addressElement: HTMLInputElement = document.getElementById('permanent_address') as HTMLInputElement
		const permanent_address: string = permanent_addressElement.value;
		const p_cityElement: HTMLInputElement = document.getElementById('p_city') as HTMLInputElement
		const p_city: string = p_cityElement.value;
		const p_stateElement: HTMLInputElement = document.getElementById('p_state') as HTMLInputElement
		const p_state: string = p_stateElement.value;		
		const p_pin_codeElement: HTMLInputElement = document.getElementById('p_pin_code') as HTMLInputElement
		const p_pin_code: string = p_pin_codeElement.value;
		
		const gnameElement: HTMLInputElement = document.getElementById('gname') as HTMLInputElement
		const gname: string = gnameElement.value;
		const relationshipElement: HTMLInputElement = document.getElementById('relationship') as HTMLInputElement
		const relationship: string = relationshipElement.value;
		const phoneElement: HTMLInputElement = document.getElementById('phone') as HTMLInputElement
		const phone: string = phoneElement.value;
		const parentstatusElement: HTMLInputElement = document.querySelector('input[name="parent_status"]:checked') as HTMLInputElement
		const parent_status: string = parentstatusElement.value;
		const windownameElement: HTMLInputElement = document.getElementById('windowname') as HTMLInputElement
		const windowname: string = windownameElement.value;
		const windowernameElement: HTMLInputElement = document.getElementById('windowername') as HTMLInputElement
		const windowername: string = windowernameElement.value;
		const whether_police_defence_personnelElement: HTMLInputElement = document.getElementById('whether_police_defence_personnel') as HTMLInputElement
		let whether_police_defence_personnel: string = whether_police_defence_personnelElement.value;
		const snameElement: HTMLInputElement = document.getElementById('sname') as HTMLInputElement
		const sname: string = snameElement.value;
		const sclassElement: HTMLInputElement = document.getElementById('sclass') as HTMLInputElement
		const sclass: string = sclassElement.value;
		const hobbiesElement: HTMLInputElement = document.getElementById('hobbies') as HTMLInputElement
		const hobbies: string = hobbiesElement.value;
		const informationElement: HTMLInputElement = document.getElementById('information') as HTMLInputElement
		const information: string = informationElement.value;
		const date_of_birth_certificate_of_child: string = this.date_of_birth_certificate_of_child;
		const proof_of_residence: string = this.proof_of_residence;
		const proof_types: string = this.proof_types;
		const proof_of_sibling: string = this.proof_of_sibling;
		const school_leaving_certificate: string = this.school_leaving_certificate;
 		 
		var schoolid = this.schooluid;
		var orderid  = this.schooloid;
		
  		// post info
		// var uri = 'https://demo.softprodigyphp.in/Admitmykid/backend/public/api/';
		var uri = environment.baseurl;
		var access_token = this.session.retrieve('access_token');
		let headers = new HttpHeaders();
		headers.append('Content-Type', 'application/json');
		headers.append('authorization', 'Bearer ' + access_token);
		 
		if (special_child === 'false') {
			special_child = "0";
		} else if (special_child === 'true') {
			special_child = "1";
		}

		if (whether_police_defence_personnel === "One") {
			whether_police_defence_personnel = "1";
		} else if (whether_police_defence_personnel === "Two") {
			whether_police_defence_personnel = "2";
		} else if (whether_police_defence_personnel === "Three") {
			whether_police_defence_personnel = "3";
		}

		let parameters = "fname="+fname+"&lname="+lname+"&mname="+mname+"&dob="+dob+"&class="+classname+"&gender="+gender+"&cast="+cast+"&nationality="+nationality+"&school_name="+school_name+"&last_class_attended="+last_class_attended+"&address_of_school="+address_of_school+"&blood_group="+blood_group+"&special_child="+special_child+"&details="+details+"&father_name="+father_name+ "&fnationality="+fnationality+"&highest_academic_qualification="+highest_academic_qualification+"&phone_1="+phone_1+"&phone_2="+phone_2+"&office_phone="+office_phone+"&occupation="+occupation+"&address_of_occupation="+address_of_occupation+"&email_id="+email_id+"&mother_name="+mother_name+"&mnationality="+mnationality+"&mhighest_academic_qualification="+mhighest_academic_qualification+"&mphone_1="+mphone_1+"&mphone_2="+mphone_2+"&moffice_phone="+moffice_phone+"&moccupation="+moccupation+"&maddress_of_occupation="+maddress_of_occupation+"&memail_id="+memail_id+"&present_address="+present_address+"&city="+city+"&state="+state+"&pin_code="+pin_code+"&permanent_address="+permanent_address+"&p_city="+p_city+"&p_state="+p_state+"&p_pin_code="+p_pin_code+"&gname="+gname+"&relationship="+relationship+"&phone="+phone+"&parent_status="+parent_status+"&whether_police_defence_personnel="+whether_police_defence_personnel+"&sname="+sname+"&sclass="+sclass+"&hobbies="+hobbies+"&information="+information+"&proof_of_residence="+proof_of_residence+"&proof_types="+proof_types+"&proof_of_sibling="+proof_of_sibling+"&school_leaving_certificate="+school_leaving_certificate+"&school_id="+schoolid+"&orderid="+orderid+"";
		if(date_of_birth_certificate_of_child){
			parameters += "&date_of_birth_certificate_of_child="+date_of_birth_certificate_of_child;
		}

		this.httpClient.post(uri +"caf?"+parameters,formData, { headers })
		.subscribe(
			response => {
				this.spinner.hide();
				//	console.log(response as any);
				//	console.log((response as any).message);
				this.responseform = (response as any);
				Swal.fire((response as any).message);
			},
			error => {
				this.responseform = (error as any);
			}
		); 
		
	}
	}
	// add adminission detail

}
