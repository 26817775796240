import { Component,Inject, Injectable  } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { DOCUMENT } from '@angular/common';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})

export class AppComponent {
    closeResult: string;
    url;
    homeRun = false;
    constructor(private modalService: NgbModal, @Inject(DOCUMENT) private document: any) { 
        this.checkPath();
    }
 
    checkPath(){
        if(window.location.pathname == '/'){
            this.homeRun = true;
            this.document.body.classList.add('abc');
        }else{
            this.homeRun = false;
            this.document.body.classList.remove('abc');
        }
    }
    ngOnDestroy(){
        this.checkPath();
    }

    open(content) {
        this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
    }
    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }
}

