<div class="fisrt-screen d-md-flex flex-column  dsdsds " [class.forgotpage]="page == 'forgotPassword'" >
    <div class="login-top d-flex justify-content-center align-items-center flex-column">
        <!-- <a rounterLink="/"> <img src="assets/img/logo.png" width="200" alt="logo" class="img-fluid"></a> -->
        <div [@vanish]="page" class="headingMain">
            <p>{{ currentPage?.message }}</p>
        </div>
        <form [formGroup]="form" (ngSubmit)="loginAction()" class="input-tab login">
            <!-- Phone Number -->
            <mat-form-field appearance="outline" *ngIf="form.contains('phone')" @inflate>
                <mat-label>Phone Number</mat-label>
                <input type="number" matInput formControlName="phone" maxlength="10" [pattern]="mobNumberPattern">
                <mat-error *ngIf=" form.controls.phone.errors?.pattern">
                    Please enter 10 digits Phone number
                </mat-error>
                <mat-error *ngIf="form.controls.phone.errors?.phone">
                    Ooops! it looks like this is not a valid Phone number
                </mat-error>
            </mat-form-field>


            <!-- otp -->
            <mat-form-field appearance="outline" *ngIf="form.contains('otpNumber')" @inflate>
                <mat-label>OTP</mat-label>
                <input type="number" matInput formControlName="otpNumber">
            </mat-form-field>

            <!-- name -->
            <mat-form-field appearance="outline" *ngIf="form.contains('name')" @inflate>
                <mat-label>Name</mat-label>
                <input matInput formControlName="name">
                <mat-error *ngIf="form.controls.name.errors?.required">
                    A name is required
                </mat-error>

            </mat-form-field>

            <!-- EMAIL -->
            <mat-form-field appearance="outline" *ngIf="form.contains('email')" @inflate>
                <mat-label>Email Id</mat-label>
                <input matInput formControlName="email">
                <mat-error *ngIf="form.controls.email.errors?.required">
                    Please specify an email address
                </mat-error>
                <mat-error *ngIf="form.controls.email.errors?.email">
                    Ooops! it looks like this is not a valid email
                </mat-error>
            </mat-form-field>

            <!-- PASSWORD -->
            <mat-form-field appearance="outline" *ngIf="form.contains('password')" @inflate>
                <mat-label>Password</mat-label>
                <input matInput [type]="hidePassword ? 'text' : 'password'" formControlName="password">
                <mat-icon matSuffix (click)="hidePassword = !hidePassword">
                    {{ hidePassword ? 'visibility' : 'visibility_off'}}
                </mat-icon>
                <mat-error *ngIf="form.controls.password.errors?.required">
                    A password is required
                </mat-error>
            </mat-form-field>

            <!-- confirm PASSWORD -->
            <mat-form-field appearance="outline" *ngIf="form.contains('confirmPassword')" @inflate>
                <mat-label>Confirm Password</mat-label>
                <input matInput [type]="hideConfirmPassword ? 'text' : 'password'" formControlName="confirmPassword">
                <mat-icon matSuffix (click)="hideConfirmPassword = !hideConfirmPassword">
                    {{ hideConfirmPassword ? 'visibility' : 'visibility_off'}}
                </mat-icon>
                <mat-error *ngIf="form.controls.confirmPassword.errors?.required">
                    A password is required
                </mat-error>

            </mat-form-field>
            
            <mat-hint class="mat-link" align="end" (click)="switchPage('forgotPassword')" *ngIf="page == 'signIn'"
                @inflate>Forgot password?</mat-hint>
            <!-- ACTION BUTTON -->
            <p class="log-error" *ngIf="checkStatus == 1">If you not received any code then <a (click)="resendCode()">Resend Code</a></p>
            <button mat-stroked-button color="primary" type="submit" [disabled]="!form.valid || btnDisable" class="btn btn-srch mb-4">
                {{ currentPage?.caption }}
            </button>

            <div class="position-relative text-right" *ngIf="page != 'forgotPassword'">
                <h3 class="line">Sign In using</h3>
            </div>
        </form>
        
            <!-- SIGN-IN PROVIDERS -->
            <div class="flexs">
                <div class="social-icons" *ngIf="page != 'forgotPassword'" @inflate data-toggle="tooltip" data-placement="bottom" title="Login using Facebook">
                    <!-- <img src="assets/img/facebook.svg"  alt="logo" style="width: 250px;height: 60px;" class="img-fluid loginBtn loginBtn--facebook" > -->
                    <span (click)="login()" class="social-button" id="facebook-connect"> <b class="icon"></b> <span>Facebook</span></span>
                    <!-- a href=""><img src="/assets/img/facebook.png" width="60" alt="logo" class="img-fluid"></a -->
                    
                </div>
                <div [hidden]="page == 'forgotPassword'">
                <button class="loginBtn loginBtn--google ml-2" #loginRef >
                    <span class="social-button" id="google-connect" #loginRef><b class="icon"></b><span>Google</span></span>
                </button>
                </div>	
                <!-- <button class="loginBtn loginBtn--google ml-2" #loginRef>
                    <img src="assets/img/mail.svg"  alt="logo" style="width: 250px;height: 60px;" class="img-fluid loginBtn loginBtn--google" #loginRef>
                </button>-->
    
                <div class="social-icons" *ngIf="page != 'forgotPassword'" @inflate>
                    <!-- a href=""><img src="/assets/img/facebook.png" width="60" alt="logo" class="img-fluid"></a -->
                    <!-- a href=""><img src="/assets/img/gmail.png" width="60" alt="logo" class="img-fluid"></a -->
                    <a (click)="switchPage('otp')">
                        <span href="#" class="social-button" id="linkedin-connect"><b class="icon"></b><span>phone</span></span>
                    </a>
                </div>
            </div>
            
        <div class="bottom-signup">
            <p *ngIf="page == 'signIn'">
                Don't have an account? <span class="mat-link" (click)="switchPage('register')">Sign Up </span>
            </p>
            <p *ngIf="page === 'register' || page === 'forgotPassword'">
                Already have an account? <span class="mat-link" (click)="switchPage('signIn')">Sign in</span>
            </p>
            <p *ngIf="page === 'otp'">
                Want to login from Email? <span class="mat-link" (click)="switchPage('signIn')">Click Here</span>
            </p>
        </div>
    </div>
