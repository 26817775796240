import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { SearchService } from '../search.service';
import { SharedService } from '../services/updateCart';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Globals } from '../globals';
import { SessionStorageService } from 'ngx-webstorage';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-admission-form-view',
  templateUrl: './admission-form-view.component.html',
  styleUrls: ['./admission-form-view.component.css']
})
export class AdmissionFormViewComponent implements OnInit {

	userDetails;
	childDate: string;
	schooluid;
	schooloid;
	res;
	uri : string = environment.baseurl;
	image_uri : string = environment.image_baseurl + '/files/';
	checkAddress: any = 1;
	hobbiesdata: any = ['Music/ Dance/ Drama', 'Media/PR', 'Academic', 'Medical', 'Sports', 'Art & Craft', 'Community', 'Programme', 'Others'];
	parent_statuses: string[] = ['Married', 'Single/Divorce', 'Widow', 'Widower','Any Other'];
	schoolMainName;

	constructor(private ss: SearchService, private globals: Globals, private session: SessionStorageService, private httpClient: HttpClient, private router: Router, private sharedService: SharedService,private route: ActivatedRoute, private spinner: NgxSpinnerService, private el: ElementRef) { 
	}
	
	ngOnInit(){
    // this.myForm.disable()
		this.route.params.subscribe(params => {
			this.schooluid=params['id'];
			this.schooloid=params['oid'];
			// alert(this.schooloid);
		});

		var access_token = this.session.retrieve('access_token');
		if (!access_token) {
			this.router.navigate(['dashboard']);
		}
		
		// var uri = 'https://demo.softprodigyphp.in/Admitmykid/backend/public/api/';
		let headers = new HttpHeaders();
		headers.append('Content-Type', 'application/json');
		headers.append('Authorization', 'Bearer ' + access_token);
		var body = '';
		
		const school_id = this.schooluid;
        const order_id = this.schooloid;
        
		this.httpClient.post(this.uri + "editUserForm?school_id="+school_id+"&order_id="+order_id, body, { headers })
		.subscribe(
			response => {
				this.res=(response as any);
				this.userDetails=this.res.details[0];
				this.schoolMainName = this.res.application_for_school;
				if (this.userDetails) {
					let dated_child = this.userDetails.dob.split('-');
					let year_child = +dated_child[0];
					let month_child = dated_child[1].split('');
					if (month_child[0] === "0") {
					month_child = +month_child[1];
					}else{
					month_child = parseInt(month_child.join(''));
					}
					let date_child = +dated_child[2];
					this.userDetails.dob = `${year_child}-${month_child}-${date_child}`;
				}
			},
			error => {
				console.log("Error", error);
			}
		);	
	}
}
