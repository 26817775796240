<section class="main">
    <div class="container">
        <div class="main-outer">
            <div class="school-list cart">
                <div class="list-inner">
                    <div class="row">
                        <div class="col-md-12 p-md-1">
							<div class="payment-container">
								<div class="payment-container-img">
									<img src="assets/img/close.png" alt="">
								</div>
								<div class="payment-container-text">
									<span class="span">Your payment has been failed.</span>
									<span class="span">Please try again later.</span>
								</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
