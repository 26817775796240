import { Component, OnInit, ElementRef  } from '@angular/core';
import { SelectedSchool } from '../Models/Product.Model';
import { SearchService } from '../search.service';
import { SharedService } from '../services/updateCart';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Globals } from '../globals';
import { SessionStorageService } from 'ngx-webstorage';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { AbstractControl, ValidatorFn, FormGroup, FormControl, Validators, FormArray, FormBuilder} from '@angular/forms';
import { environment } from '../../environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';
import { Nationality } from '../shared/nationality';
import { NgbDate, NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-admission-form-add',
  templateUrl: './admission-form-add.component.html',
  styleUrls: ['./admission-form-add.component.css']
})
export class AdmissionFormAddComponent implements OnInit {
	mobNumberPattern = "^((\\+91-?)|0)?[0-9]{10}$"; 
	userDetails: any = [];
	maxDate;
	schooluid;
	schooloid;
	nationData = Nationality;
	checkAddress: any;
	res;
	files:string  []  =  [];
	special_child : boolean = false;
	responseform;
	errorList;
	hobbiesdata: any = ['Music/ Dance/ Drama', 'Media/PR', 'Academic', 'Medical', 'Sports', 'Art & Craft', 'Community', 'Programme', 'Others'];
	parent_statuses: string[] = ['Married', 'Single/Divorce', 'Widow', 'Widower','Any Other'];
	whether_police_personnel: string[] = ['Yes', 'No'];
	date_of_birth_certificate_of_child: string = '';
	proof_of_residence: string ='';
	proof_types: string ='';
	proof_of_sibling: string ='';
	school_leaving_certificate: string ='';
 	public submitted: boolean = false;
	 //Pattern Validations for CAF
	namePattern = '^[A-Za-z]+$';
	mobPattern = '^[6-9]{1}[0-9]{9}$';
	alterPattern = '^[0-9-]{6,12}$';
	pincodePattern = '^[1-9]{1}[0-9]{5}$';
	schoolName;
	
	myForm = new FormGroup({
		fname: new FormControl('', [Validators.required, Validators.pattern(this.namePattern)]),
		mname: new FormControl('', [Validators.pattern(this.namePattern)]),
		lname: new FormControl('', [Validators.required, Validators.pattern(this.namePattern)]),
		dob: new FormControl('', [Validators.required]),
		classname: new FormControl('', [Validators.required]),
		school_name: new FormControl(''),
		last_class_attended: new FormControl(''),
		address_of_school: new FormControl(''),
		sclass: new FormControl(''),
		gender: new FormControl('', [Validators.required]),
		cast: new FormControl('', [Validators.required]),
		nationality: new FormControl('', [Validators.required]),
		blood_group: new FormControl('', [Validators.required]),
		// special_child: new FormControl('', [Validators.required]),
		father_name: new FormControl('', [Validators.required]),
		fnationality: new FormControl('', [Validators.required]),
		phone_1: new FormControl('', [Validators.required , Validators.pattern(this.mobPattern)]),
		phone_2: new FormControl('', [Validators.pattern(this.alterPattern)]),
		office_phone: new FormControl('', [Validators.pattern(this.alterPattern)]),
		occupation: new FormControl('', [Validators.required]),
		address_of_occupation: new FormControl(''),
		email_id: new FormControl('', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]),
		mother_name: new FormControl('', [Validators.required]),
		mnationality: new FormControl('', [Validators.required]),
		mphone_1: new FormControl(''),
		mphone_2: new FormControl('', [Validators.pattern(this.alterPattern)]),
		moffice_phone: new FormControl('', [Validators.pattern(this.alterPattern)]),
		memail_id: new FormControl('', [Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]),
		present_address: new FormControl('', [Validators.required]),
		city: new FormControl('', [Validators.required]),
		state: new FormControl('', [Validators.required]),
		pin_code: new FormControl('', [Validators.required , Validators.pattern(this.pincodePattern)]),
		permanent_address: new FormControl('', [Validators.required]),
		p_city: new FormControl('', [Validators.required]),
		p_state: new FormControl('', [Validators.required]),
		p_pin_code: new FormControl('', [Validators.required , Validators.pattern(this.pincodePattern)]),
		phone: new FormControl('', [Validators.pattern(this.mobPattern)]),
		parent_status: new FormControl('', [Validators.required]),
		whether_police_defence_personnel: new FormControl('', [Validators.required]),
		//dobcertificate: new FormControl('', [Validators.required]),
		files: new FormControl('', [Validators.required]),
	});
	
	constructor(private ss: SearchService, private globals: Globals, private session: SessionStorageService, private httpClient: HttpClient, private router: Router, private sharedService: SharedService,private route: ActivatedRoute, private spinner: NgxSpinnerService, private el: ElementRef, config: NgbDatepickerConfig) { 
		// config.maxDate = {year: 2025, month: 1, day: 1};
		console.log(new Date());
		this.maxDate = {
			year: new Date().getFullYear(),
			month: new Date().getMonth(),
			day: new Date().getDate()
		  };
	}
	
	ngOnInit(){
		this.myForm.controls['whether_police_defence_personnel'].setValue(0);
		this.route.params.subscribe(params => {
			this.schooluid=params['id'];
			this.schooloid=params['oid'];
		});

		var access_token = this.session.retrieve('access_token');
		if (!access_token) {
			this.router.navigate(['dashboard']);
		}
		
		var uri = environment.baseurl;
		let headers = new HttpHeaders();
		headers.append('Content-Type', 'application/json');
		headers.append('Authorization', 'Bearer ' + access_token);
		var body = '';
		
		const school_id = this.schooluid;
        const order_id = this.schooloid;
        
		this.httpClient.post(uri + "editUserForm?school_id="+school_id+"&order_id="+order_id, body, { headers })
		.subscribe(
			response => {
				this.res=(response as any);
				console.log(this.res);
				this.userDetails=this.res.details[0];
				this.schoolName=this.res.application_for_school;
				console.log(this.userDetails);
				this.setDates();
				// this.initForm(this.userDetails);
			},
			error => {
				console.log("Error", error);
			}
		);	
		setTimeout(() => {
			if(this.userDetails != null){
				var element = <HTMLInputElement> document.getElementById('special_child');
				if (this.userDetails.special_child === 1) {
					element.checked = true;
				} else if (this.userDetails.special_child === 0) {
					element.checked = false;
				}
				(<HTMLInputElement>document.getElementById("nationality")).value = (this.userDetails.nationality ? this.userDetails.nationality : 'India');
				(<HTMLInputElement>document.getElementById("blood_group")).value = this.userDetails.blood_group;
				(<HTMLInputElement>document.getElementById("cast")).value = this.userDetails.cast;
				(<HTMLInputElement>document.getElementById("gender")).value = this.userDetails.gender;
				(<HTMLInputElement>document.getElementById("last_class_attended")).value = this.userDetails.last_class_attended;
				(<HTMLInputElement>document.getElementById("classname")).value = this.userDetails.class;
				(<HTMLInputElement>document.getElementById("sclass")).value = this.userDetails.sclass;
				(<HTMLInputElement>document.getElementById("fnationality")).value = (this.userDetails.fnationality ? this.userDetails.fnationality : 'India');
				(<HTMLInputElement>document.getElementById("mnationality")).value = (this.userDetails.mnationality ? this.userDetails.mnationality : 'India');
				(<HTMLInputElement>document.getElementById("hobbies")).value = this.userDetails.hobbies;
			}
		}, 500)	
		
	}

	setValues(value, type) {
		if (type === "child") {
			this.myForm.controls['nationality'].setValue(value);
			console.log(this.myForm.controls['nationality'])
		} else if (type === "father") {
			this.myForm.controls['fnationality'].setValue(value);
		} else if (type === "mother") {
			this.myForm.controls['mnationality'].setValue(value);
		}
	}

	setDates() {
		//set date for student
		if(this.userDetails){
			let dated_child = this.userDetails.dob.split('-');
			let year_child = +dated_child[0];
			let month_child = dated_child[1].split('');
			if (month_child[0] === "0") {
				month_child = +month_child[1];
			}else{
				month_child = parseInt(month_child.join(''));
			}
			let date_child = +dated_child[2];
			this.userDetails.dob = new NgbDate(year_child, month_child, date_child);
			this.myForm.controls['dob'].setValue(this.userDetails.dob);
			this.myForm.controls['cast'].setValue(this.userDetails.cast);
			this.myForm.controls['blood_group'].setValue(this.userDetails.blood_group);
			this.myForm.controls['gender'].setValue(this.userDetails.gender);
			this.myForm.controls['classname'].setValue(this.userDetails.class);
			this.myForm.controls['sclass'].setValue(this.userDetails.sclass);
			this.myForm.controls['last_class_attended'].setValue(this.userDetails.last_class_attended);
			this.myForm.controls['parent_status'].setValue(this.userDetails.parent_status ? this.userDetails.parent_status : 'Married');
			this.myForm.controls['whether_police_defence_personnel'].setValue(this.userDetails.whether_police_defence_personnel ? this.userDetails.whether_police_defence_personnel : '0');
			if(this.userDetails.special_child){
				this.special_child = this.userDetails.special_child;
			}
		}
		
		this.myForm.controls['nationality'].setValue((this.userDetails ? this.userDetails.nationality : 'India'));
		this.myForm.controls['fnationality'].setValue((this.userDetails ? this.userDetails.fnationality : 'India'));
		this.myForm.controls['mnationality'].setValue((this.userDetails ? this.userDetails.mnationality : 'India'));
	}

	toggleValues(value) {
		if (value === 'special') {
			if (this.special_child) {
				const specialChildInput = <HTMLInputElement>document.getElementById('details');
				specialChildInput.value = '';
			}
			this.special_child = !this.special_child;
		} else if  (value === 'address') {
			this.checkAddress = !this.checkAddress;
			const presentAddress = <HTMLInputElement>document.getElementById('present_address')
			const presentAddressValue = presentAddress.value;
			const presentCity = <HTMLInputElement>document.getElementById('city')
			const presentCityValue = presentCity.value;
			const presentState = <HTMLInputElement>document.getElementById('state')
			const presentStateValue = presentState.value;
			const presentPinCode = <HTMLInputElement>document.getElementById('pin_code')
			const presentPinCodeValue = presentPinCode.value;
		
			if (this.checkAddress) {
				this.myForm.controls['permanent_address'].setValue(presentAddressValue);
				this.myForm.controls['p_city'].setValue(presentCityValue);
				this.myForm.controls['p_state'].setValue(presentStateValue);
				this.myForm.controls['p_pin_code'].setValue(presentPinCodeValue);
			} else {
				this.myForm.controls['permanent_address'].setValue('');
				this.myForm.controls['p_city'].setValue('');
				this.myForm.controls['p_state'].setValue('');
				this.myForm.controls['p_pin_code'].setValue('');
			}
		}
	}
 	 
	get f(){
		return this.myForm.controls;
	}
	/*
	initForm(userDetail: any = []) {
		this.f['fname'].setValue(userDetail.fname);
	}
	*/
	onFileChange(event,docs) {
		if (event.target.files.length > 0) {
			for  (var i =  0; i <  event.target.files.length; i++)  {  
				this.files.push(event.target.files[i]);
				if(docs=="dobcertificate"){
					this.date_of_birth_certificate_of_child = event.target.files[i].name;
				} else if(docs=="votercard"){
					this.proof_of_residence = event.target.files[i].name; 
				}else if(docs=="aadharcard"){
					this.proof_types = event.target.files[i].name; 
				}else if(docs=="anybill"){
					this.proof_of_sibling = event.target.files[i].name; 
				}else if(docs=="tc"){
					this.school_leaving_certificate = event.target.files[i].name; 
				}
				console.log(this.date_of_birth_certificate_of_child);
			}
		}
	}

	private scrollToFirstInvalidControl() {
		const firstInvalidControl: HTMLElement = this.el.nativeElement.querySelector(
			"form .ng-invalid"
		);
	    if(firstInvalidControl)
			firstInvalidControl.focus(); 
	}
		
	// add adminission detail
	async submitForm() {
		this.submitted = true;
		// stop here if form is invalid
        if (this.myForm.invalid) {
			this.scrollToFirstInvalidControl();
			this.myForm.controls['parent_status'].setValue('Married');
			this.myForm.controls['whether_police_defence_personnel'].setValue(0);
			console.log(this.myForm.invalid)
            return;
		}
		
		if (this.myForm.valid) {	
			await Swal.fire({
				title: 'Are you sure you want to submit the form?',
				text: 'Form cannot be edited once submitted.',
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: 'Yes',
				cancelButtonText: 'No'
			  }).then((result) => {
				if (result.value) {
					this.spinner.show();
					const formData =  new FormData();	
					for  (var i =  0; i <  this.files.length; i++)  {
						formData.append("files[]",  this.files[i]);
					} 
					const fnameElement: HTMLInputElement = document.getElementById('fname') as HTMLInputElement
					const fname: string = fnameElement.value;
					const mnameElement: HTMLInputElement = document.getElementById('mname') as HTMLInputElement
					const mname: string = mnameElement.value;
					const lnameElement: HTMLInputElement = document.getElementById('lname') as HTMLInputElement
					const lname: string = lnameElement.value;
					const dobElement: HTMLInputElement = document.getElementById('dob') as HTMLInputElement
					const dob: string = dobElement.value;
					const classnameElement: HTMLInputElement = document.getElementById('classname') as HTMLInputElement
					const classname: string = classnameElement.value;
					const genderElement: HTMLInputElement = document.getElementById('gender') as HTMLInputElement
					const gender: string = genderElement.value;
					const castElement: HTMLInputElement = document.getElementById('cast') as HTMLInputElement
					const cast: string = castElement.value;
					//const school_idElement: HTMLInputElement = document.getElementById('school_id') as HTMLInputElement
					//const school_id: string = school_idElement.value;
					const nationalityElement: HTMLInputElement = document.getElementById('nationality') as HTMLInputElement
					const nationality: string = nationalityElement.value;
					const school_nameElement: HTMLInputElement = document.getElementById('school_name') as HTMLInputElement
					const school_name: string = school_nameElement.value;
					const last_class_attendedElement: HTMLInputElement = document.getElementById('last_class_attended') as HTMLInputElement
					const last_class_attended: string = last_class_attendedElement.value;
					const address_of_schoolElement: HTMLInputElement = document.getElementById('address_of_school') as HTMLInputElement
					const address_of_school: string = address_of_schoolElement.value;
					const blood_groupElement: HTMLInputElement = document.getElementById('blood_group') as HTMLInputElement

					const blood_group: string = encodeURIComponent(blood_groupElement.value);

					const special_childElement: HTMLInputElement = document.getElementById('special_child') as HTMLInputElement
					let special_child: string = special_childElement.value;
					const detailsElement: HTMLInputElement = document.getElementById('details') as HTMLInputElement
					const details: string = detailsElement.value;
					const father_nameElement: HTMLInputElement = document.getElementById('father_name') as HTMLInputElement
					const father_name: string = father_nameElement.value;
					const fnationalityElement: HTMLInputElement = document.getElementById('fnationality') as HTMLInputElement
					const fnationality: string = fnationalityElement.value;
					const highest_academic_qualificationElement: HTMLInputElement = document.getElementById('highest_academic_qualification') as HTMLInputElement
					const highest_academic_qualification: string = highest_academic_qualificationElement.value;
					const phone_1Element: HTMLInputElement = document.getElementById('phone_1') as HTMLInputElement
					const phone_1: string = phone_1Element.value;
					const phone_2Element: HTMLInputElement = document.getElementById('phone_2') as HTMLInputElement
					const phone_2: string = phone_2Element.value;
					const office_phoneElement: HTMLInputElement = document.getElementById('office_phone') as HTMLInputElement
					const office_phone: string = office_phoneElement.value;
					const occupationElement: HTMLInputElement = document.getElementById('occupation') as HTMLInputElement
					const occupation: string = occupationElement.value;
					const address_of_occupationElement: HTMLInputElement = document.getElementById('address_of_occupation') as HTMLInputElement
					const address_of_occupation: string = address_of_occupationElement.value;
					const email_idElement: HTMLInputElement = document.getElementById('email_id') as HTMLInputElement
					const email_id: string = email_idElement.value;
					const mother_nameElement: HTMLInputElement = document.getElementById('mother_name') as HTMLInputElement
					const mother_name: string = mother_nameElement.value;
					const mnationalityElement: HTMLInputElement = document.getElementById('mnationality') as HTMLInputElement
					const mnationality: string = mnationalityElement.value;
					const maqElement: HTMLInputElement = document.getElementById('mhighest_academic_qualification') as HTMLInputElement
					const mhighest_academic_qualification: string = maqElement.value;
					const mphone_1Element: HTMLInputElement = document.getElementById('mphone_1') as HTMLInputElement
					const mphone_1: string = mphone_1Element.value;
					const mphone_2Element: HTMLInputElement = document.getElementById('mphone_2') as HTMLInputElement
					const mphone_2: string = mphone_2Element.value;
					const moffice_phoneElement: HTMLInputElement = document.getElementById('moffice_phone') as HTMLInputElement
					const moffice_phone: string = moffice_phoneElement.value;
					const moccupationElement: HTMLInputElement = document.getElementById('moccupation') as HTMLInputElement
					const moccupation: string = moccupationElement.value;
					const maddress_of_occupationElement: HTMLInputElement = document.getElementById('maddress_of_occupation') as HTMLInputElement
					const maddress_of_occupation: string = maddress_of_occupationElement.value;
					const memail_idElement: HTMLInputElement = document.getElementById('memail_id') as HTMLInputElement
					const memail_id: string = memail_idElement.value;
					const present_addressElement: HTMLInputElement = document.getElementById('present_address') as HTMLInputElement
					const present_address: string = present_addressElement.value;
					const cityElement: HTMLInputElement = document.getElementById('city') as HTMLInputElement
					const city: string = cityElement.value;
					const stateElement: HTMLInputElement = document.getElementById('state') as HTMLInputElement
					const state: string = stateElement.value;
					const pin_codeElement: HTMLInputElement = document.getElementById('pin_code') as HTMLInputElement
					const pin_code: string = pin_codeElement.value;
					const permanent_addressElement: HTMLInputElement = document.getElementById('permanent_address') as HTMLInputElement
					const permanent_address: string = permanent_addressElement.value;
					const p_cityElement: HTMLInputElement = document.getElementById('p_city') as HTMLInputElement
					const p_city: string = p_cityElement.value;
					const p_stateElement: HTMLInputElement = document.getElementById('p_state') as HTMLInputElement
					const p_state: string = p_stateElement.value;		
					const p_pin_codeElement: HTMLInputElement = document.getElementById('p_pin_code') as HTMLInputElement
					const p_pin_code: string = p_pin_codeElement.value;
					
					const gnameElement: HTMLInputElement = document.getElementById('gname') as HTMLInputElement
					const gname: string = gnameElement.value;
					const relationshipElement: HTMLInputElement = document.getElementById('relationship') as HTMLInputElement
					const relationship: string = relationshipElement.value;
					const phoneElement: HTMLInputElement = document.getElementById('phone') as HTMLInputElement
					const phone: string = phoneElement.value;
					const parentstatusElement: HTMLInputElement = document.querySelector('input[name="parent_status"]:checked') as HTMLInputElement
					const parent_status: string =  this.myForm.value.parent_status;

					const windownameElement: HTMLInputElement = document.getElementById('windowname') as HTMLInputElement
					const windowname: string = windownameElement.value;
					const windowernameElement: HTMLInputElement = document.getElementById('windowername') as HTMLInputElement
					const windowername: string = windowernameElement.value;
					const whether_police_defence_personnelElement: HTMLInputElement = document.getElementById('input[name="whether_police_defence_personnel"]:checked') as HTMLInputElement
					const whether_police_defence_personnel: string = this.myForm.value.whether_police_defence_personnel;
					const snameElement: HTMLInputElement = document.getElementById('sname') as HTMLInputElement
					const sname: string = snameElement.value;
					const sclassElement: HTMLInputElement = document.getElementById('sclass') as HTMLInputElement
					const sclass: string = sclassElement.value;
					const hobbiesElement: HTMLInputElement = document.getElementById('hobbies') as HTMLInputElement
					const hobbies: string = hobbiesElement.value;
					const informationElement: HTMLInputElement = document.getElementById('information') as HTMLInputElement
					const information: string = informationElement.value;
					const date_of_birth_certificate_of_child: string = this.date_of_birth_certificate_of_child;
					const proof_of_residence: string = this.proof_of_residence;
					const proof_types: string = this.proof_types;
					const proof_of_sibling: string = this.proof_of_sibling;
					const school_leaving_certificate: string = this.school_leaving_certificate;
					 
					var schoolid = this.schooluid;
					var orderid  = this.schooloid;
					
					// post info
					var uri = environment.baseurl;
					var access_token = this.session.retrieve('access_token');
					let headers = new HttpHeaders();
					headers.append('Content-Type', 'application/json');
					headers.append('authorization', 'Bearer ' + access_token);
					if (special_child === 'false') {
						special_child = "0";
					} else if (special_child === 'true') {
						special_child = "1";
					}
		
					this.httpClient.post(uri +"caf?fname="+fname+"&lname="+lname+"&mname="+mname+"&dob="+dob+"&class="+classname+"&gender="+gender+"&cast="+cast+"&nationality="+nationality+"&school_name="+school_name+"&last_class_attended="+last_class_attended+"&address_of_school="+address_of_school+"&blood_group="+blood_group+"&special_child="+special_child+"&details="+details+"&father_name="+father_name+"&fnationality="+fnationality+"&highest_academic_qualification="+highest_academic_qualification+"&phone_1="+phone_1+"&phone_2="+phone_2+"&office_phone="+office_phone+"&occupation="+occupation+"&address_of_occupation="+address_of_occupation+"&email_id="+email_id+"&mother_name="+mother_name+"&mnationality="+mnationality+"&mhighest_academic_qualification="+mhighest_academic_qualification+"&mphone_1="+mphone_1+"&mphone_2="+mphone_2+"&moffice_phone="+moffice_phone+"&moccupation="+moccupation+"&maddress_of_occupation="+maddress_of_occupation+"&memail_id="+memail_id+"&present_address="+present_address+"&city="+city+"&state="+state+"&pin_code="+pin_code+"&permanent_address="+permanent_address+"&p_city="+p_city+"&p_state="+p_state+"&p_pin_code="+p_pin_code+"&gname="+gname+"&relationship="+relationship+"&phone="+phone+"&parent_status="+parent_status+"&whether_police_defence_personnel="+whether_police_defence_personnel+"&sname="+sname+"&sclass="+sclass+"&hobbies="+hobbies+"&information="+information+"&date_of_birth_certificate_of_child="+date_of_birth_certificate_of_child+"&proof_of_residence="+proof_of_residence+"&proof_types="+proof_types+"&proof_of_sibling="+proof_of_sibling+"&school_leaving_certificate="+school_leaving_certificate+"&school_id="+schoolid+"&orderid="+orderid+"",formData, { headers })
					.subscribe(
						response => {
							this.spinner.hide();
							// console.log((response as any).message);
							this.responseform = (response as any);
							console.log(JSON.stringify(this.responseform) + 'Send');
							Swal.fire((response as any).message);
							this.router.navigate(['dashboard']);
						},
						error => {
							this.responseform = (error as any);
							this.spinner.hide();
							this.errorList = this.responseform.error.errors;
							Swal.fire({
								icon: 'error',
								title: 'Oops... We got some errors',
								text: 'Please correct the details',
								// timer: 3000

							  })
							document.body.scrollTop = 0;
							console.log(this.errorList);
							
						}
					); 
				} else if (result.dismiss === Swal.DismissReason.cancel) {
					return false;
				}
			  })
		} else {
			Swal.fire("Please fill all the required fields");
		}
	}
	// add adminission detail

}
