import { Component, OnInit } from '@angular/core';
 import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SelectedSchool } from '../Models/Product.Model';
import { environment } from '../../environments/environment';
@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css']
})
export class NotificationComponent implements OnInit {
	public selectlist: SelectedSchool[];
	public notificationcount: 0;

  constructor(private httpClient: HttpClient) { }
  ngOnInit() {
     var access_token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6ImUzMjM4MjIwNTQzNmM2ZDViOGNlODcwZTY1M2E2YjU4YTY1NDIxMTI4MmY5YTI1ZTM1OWUxODk3MzUxZGE2YzRiOTJkOWM1M2E1NGM5M2FmIn0.eyJhdWQiOiIzIiwianRpIjoiZTMyMzgyMjA1NDM2YzZkNWI4Y2U4NzBlNjUzYTZiNThhNjU0MjExMjgyZjlhMjVlMzU5ZTE4OTczNTFkYTZjNGI5MmQ5YzUzYTU0YzkzYWYiLCJpYXQiOjE1OTE2ODU1NjQsIm5iZiI6MTU5MTY4NTU2NCwiZXhwIjoxNjIzMjIxNTY0LCJzdWIiOiI5NTYzNGY0Mi1kNTEzLTRiMzMtYjcxMy01YWM1YzFjM2M5YTYiLCJzY29wZXMiOltdfQ.pSQ1afe7umO_j7Byr9jx-YyLv7SDnfdCJjTzgJXiqFKhkWpdOeMwi4jaPpysnQuGyHpCzGZYuKuDUkHZWHyI5DGGDCGcxjGjdBIE_gvREftUwWF-i8Flb0vtJiL56X0PwrMZFVI7Ie672SfYUo6aArmOms9Gz1lcCKzBvaq55dXGQfla3S87V1V-VEZevIFgeWAD8tlLnOSeDbLcvQsuX5moqdhOiVdl7VwujH1gxzyQlbk78D0oblzOLBe682f3_YGK0J58UL6NyvJaD8cvRLFakApTK4tnlpbc5YogfKYF8Hbib__dRaOSx_PxlIiAzSIjdV2qWSS4ZeLoJBJ7uPUu1txIKJro8CFiAZTE9f_-YZbBEoYQ9tTl-06v0Q1UfjBdgP6nrfb6tuJrMpZiORPj7_ZIlAxWWJvkEhmPxaTmeqaEeJYNvDki52xVSYuTZYR6b_ljON8BBa0wB7bCFH6922prmE0uL1wSUyeTSqScblTDh2cZEhI-q7OVFcT_MtzF4L8PWjbQ09OZfbuJM8CtkIM_IpAV4tudf6iSw9ApFFDhDOYRGXnyHzV5LdV5D2C9wcdmn4ykw7a9lDA3DSINymHlxc3csiQAIrX2XuzrJwaf3jcxmCPfggxUMT6dTBoWI78kIUG_CeBxdzlvVJ6Dwjr7lpRsevmExmPKF3Y';
    // var access_token = this.session.retrieve('access_token');
		if (access_token) {
			//this.tokenacc = access_token;
			// var uri = 'https://demo.softprodigyphp.in/Admitmykid/backend/public/api/';
			var uri = environment.baseurl;
			let headers = new HttpHeaders();
			headers.append('Content-Type', 'application/json');
			headers.append('authorization', 'Bearer ' + access_token);
			var body = '';
			this.httpClient.post(uri + "get_not_sch", body, { headers })
				.subscribe(
					response => {
						console.log((response as any));
						this.selectlist = (response as any).School_details;
						this.notificationcount = (response as any).notification_count
					},
					error => {
						console.log("Error", error);
					}
				);
		}  

  }
}
