import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { Router } from '@angular/router'
import { SessionStorageService } from 'ngx-webstorage';
import { environment } from '../../../environments/environment';
import { Globals } from '../../globals';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(private http: HttpClient, private router: Router, private session: SessionStorageService, private globals: Globals) { }

  // Sign Up
  registerUser(email: string, password: string, c_password: string,name:string) {
    return this.http.post<any>(environment.baseurl + "user-register", {
      email: email,
      password: password,
      c_password: password,
      name:name,
      success: true
    }).pipe(catchError(this.handleError))
  }

  // Sign In
  LoginUser(email: string, password: string) {
    return this.http.post<any>(environment.baseurl + "user-login", {
      email: email,
      password: password,
      access_token: true
    }).pipe(catchError(this.handleError))
  }

  // Sign In
  SocialLoginUser(token: string, provider: string) {
    return this.http.post<any>(environment.baseurl + "callback", {
      token: token,
      provider: provider,
      access_token: true
    }).pipe(catchError(this.handleError))
  }

  // Forgot Passsword
  ForgotPassword(email: string) {
    return this.http.post<any>(environment.baseurl + 'create', {
      email: email
    }).pipe(catchError(this.handleError))
  }

  // Otp
  Otp(contact_number: string) {
    return this.http.post<any>(environment.baseurl + 'otp-register', {
      contact_number: contact_number
    }).pipe()
  }

  // Otp verification
  otpVerification(otp: string, contact_number: string) {
    return this.http.post<any>(environment.baseurl + 'otp-verify', {
      contact_number: contact_number,
      otp: otp
    }).pipe()
  }

  loggedIn() {
    return !!this.session.retrieve('access_token')
  }

  getToken() {
    return this.session.retrieve('access_token')
  }

  logoutUser() {
    this.session.clear('access_token');
    this.globals.username = "";
    this.globals.cartcount = 0;
    this.router.navigate(['login'])
    localStorage.removeItem('schools');
  }


  private handleError(errorRes: HttpErrorResponse) {
    let errorMessages = errorRes.error.error.email ? errorRes.error.error.email[0] : errorRes.error.error;
    let errorMessage = "An Unkown error occured!";
    if (!errorMessages) {
      return throwError(errorMessage)
    }
    switch (errorMessages) {
      case 'The email has already been taken.':
        errorMessage = 'The email already exists!'
        break;
      case 'Password or Email not matched':
        errorMessage = 'Password or Email not matched!'
        break;
      case 'The contact number field is required.':
        errorMessage = 'Please enter 10 digits Phone number'
        break;
      case 'User is not active, Please check your email':
        errorMessage = 'Please Check your mail and activate the user first';
    }
    return throwError(errorMessage);
  }
}



