import { Component, ElementRef, OnInit } from '@angular/core';
import { SearchService } from '../search.service';
// import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SlimLoadingBarService } from 'ng2-slim-loading-bar';
import { DeviceDetectorService } from 'ngx-device-detector';
import School from '../School';
import Utils from '../utils';
import { SelectedSchool } from '../Models/Product.Model';
import { IAlert } from '../Models/IAlert';
import { SharedService } from '../services/updateCart';
import Swal from 'sweetalert2';
import { SessionStorageService } from 'ngx-webstorage';
import { Globals } from '../globals';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

declare var $: any;

interface MyInterface {
  (): string; 
  text2(content: string);
}

import { ActivatedRoute, NavigationCancel, Event, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
@Component({
  selector: 'app-school-list',
  templateUrl: './school-list.component.html',
  styleUrls: ['./school-list.component.css']
})

export class SchoolListComponent {
	public alerts: Array<IAlert> = [];
	cartItemCount: number = 0;
	title = 'School Website';
	public util: any;
	public facilities: any;
	public cities: any;
	public areas: any;
  public boards:any;
	public loading: boolean;
	public schools: School[];
	public query_params: any;
	public search_params: any;
	public showVar: boolean = true;
	public isMobile = null;
	public isTablet = null;
	public isDesktopDevice = null;
	public p: any = 1;
	public count: any = 10;
	public schoolAddedTocart: SelectedSchool[];
	cartcount: number = 0;
  searchSchool: string;
	token: string;
  uri = environment.baseurl;

  affiliation: any;
  currentPage = 1;
  itemsPerPage = 20;
  totalItems;
  noPagination = true;
  schoolCount;

	constructor(private session: SessionStorageService, private globals: Globals, private httpClient: HttpClient, private route: ActivatedRoute, private loadingBar: SlimLoadingBarService, private router: Router, private elRef: ElementRef, private ss: SearchService, private deviceService: DeviceDetectorService, private sharedService: SharedService) {
		this.ss
		.getFilterRequiredData()
		.subscribe((data: any) => {
			this.facilities = data.facilities;
			this.cities = data.cities;
      this.boards = data.boards;
		});
		
		this.util = Utils;
		this.router.events.subscribe((event: Event) => {
			this.navigationInterceptor(event);
		});
    this.epicFunction();
    
	}

	private navigationInterceptor(event: Event): void {
		if (event instanceof NavigationStart) {
			this.loadingBar.start();
		}
		if (event instanceof NavigationEnd) {
			this.loadingBar.complete();
		}
		if (event instanceof NavigationCancel) {
			this.loadingBar.stop();
		}
		if (event instanceof NavigationError) {
			this.loadingBar.stop();
		}
  }
  


	epicFunction() {
		this.isMobile = this.deviceService.isMobile();
		this.isTablet = this.deviceService.isTablet();
		this.isDesktopDevice = this.deviceService.isDesktop();
	}

	public closeAlert(alert: any) {
		const index: number = this.alerts.indexOf(alert);
		this.alerts.splice(index, 1);
	}

	OnAddCart(id, school_name) {
		let access_token = this.session.retrieve('access_token')
		if (access_token) {
			var schools_id = [];
			var uri = environment.baseurl;
			if (id) {
				schools_id.push({
					school_id: id
				});
				// post request
				let headers = new HttpHeaders();
				headers.append('Content-Type', 'application/json');
				headers.append('authorization', 'Bearer ' + access_token);
				var body = schools_id;
				this.httpClient.post(uri + "cart-orders", body, { headers })
				.subscribe(
				response => {
				// console.log((response as any));
					this.globals.cartcount = (response as any).total_cart_orders
					let tempProduct = this.schools.find(p => p.id == id);
					if (tempProduct == null) {
						console.log('test')
					}
          Swal.fire(school_name + ' added to cart.');
				},
				error => {
          console.log("Error", error);
          var cartError = error.error.message;
          Swal.fire({
            title: school_name,
            html: '<p class="error-cart">' + cartError + '</p>',
            showCancelButton: true,
            showConfirmButton: false
          })
				}
				);
				// post request
			} else {
				this.router.navigate(['/login']);
			}

		}
	}

  OffAddCart(schools: SelectedSchool) {
    this.schoolAddedTocart = this.ss.getProductFromCart();
    if (this.schoolAddedTocart == null) {
      this.schoolAddedTocart = [];
      this.schoolAddedTocart.push(schools);
      this.ss.addProductToCart(schools);
      Swal.fire(schools.school_name + ' added to cart.')
    } else {
      let tempProduct = this.schoolAddedTocart.find(p => p.id == schools.id);
      if (tempProduct == null) {
        this.schoolAddedTocart.push(schools);
        this.ss.addProductToCart(schools);
        Swal.fire(schools.school_name + ' added to cart.')
      } else {
        // Swal.fire(schools.school_name + ' ')
        Swal.fire({
          title: schools.school_name,
          html: '<p class="error-cart"> already exists in cart. </p>',
          showCancelButton: true,
          showConfirmButton: false
        })
      }
    }
    if (this.schoolAddedTocart != null) {
      this.cartItemCount = this.schoolAddedTocart.length;
      this.globals.cartcount = this.schoolAddedTocart.length;
    } else {
      this.cartItemCount = 0;
      this.globals.cartcount = 0;
    }
    this.sharedService.updateCartCount(this.cartItemCount);
  }


  get_schools(search_params?: any): void {
    this.loading = true;
    var obj = {};
    obj['school_upto'] = '';
    let form_data = this.elRef.nativeElement.querySelectorAll(".form_data");
    for (var i = 0; i < form_data.length; i++) {
      if (form_data[i].name == 'school_upto') {
        const selected = form_data[i].querySelectorAll('option:checked');
        const values = Array.from(selected).map((el: any) => el.value);
        obj[form_data[i].name] = values.join(',');
      } else if (form_data[i].name == 'school_upto_res') {
        const selected = form_data[i].querySelectorAll('option:checked');
        const values = Array.from(selected).map((el: any) => el.value);
        obj[form_data[i].name] = values.join(',');
      } else {
        obj[form_data[i].name] = form_data[i].value;
      }
    }

    if (this.util.isNotEmpty(obj['school_upto_res'])) {
      obj['school_upto'] = obj['school_upto_res'];
    }
    delete obj['school_upto_res'];

    $('.col .dropdown').each(function () {
      if ($(this).find('a.dropdown-toggle').attr('id')) {
        
        obj[$(this).find('a.dropdown-toggle').attr('id')] = $(this).find('a.dropdown-toggle').text(); 
      }
    });
    this.assign_radio('admission_status_res', obj);
    this.assign_radio('school_paging_res', obj);

    this.assign_checkbox('affilation_program', obj);
    this.assign_checkbox('by_gender', obj);
    this.assign_checkbox('school_type', obj);

    var facility = [];
    $('.facilities_options').each(function () {
      if ($(this).is(':checked')) {
        facility.push($(this).val());
      }
    });
    obj['facility'] = facility.join(',');

    if (this.util.isNotEmpty(search_params)) {
      if (Object.keys(search_params).length > 0) {
        obj = search_params;
        for (let key in obj) {
          obj[key] = decodeURIComponent(obj[key]);
        }
      }
    }
    // debugger; 

    if (this.util.isNotEmpty(obj['location'])) {
      this.ss.getAreaByCity(obj['location'])
        .subscribe((data: any) => {
          this.areas = data.areas;
        });
    }
    var address = '';
    obj['affilation_program'] = this.affiliation;
    if (obj['area']) {
      address += obj['area'] + ',';
    }
    address += obj['location'];

    if (this.util.isNotEmpty(obj['current_page'])) {
      this.p = parseInt(obj['current_page']);
    }

    obj['current_page'] = this.p;
    this.count = parseInt(obj['school_paging'].replace(' School', ''));
    console.log(this.count);

    if(isNaN(this.count)){
      this.noPagination = false;
    } else{
      this.noPagination = true;
    }
    // this.totalItems = this.schoolCount;
    // console.log(this.totalItems);

    if (obj['admission_status'] === "All") {
      obj['admission_status'] = ""
    }
    if (obj['affilation_program'] === "All") {
      obj['affilation_program'] = ""
    } 
    if (obj['by_gender'] === "All") {
      obj['by_gender'] = ""
    } 
    if (obj['school_paging'] === "All") {
      obj['school_paging'] = ""
    } 
    if (obj['school_type'] === "All") {
      obj['school_type'] = ""
    }

    this.ss.getSchools(obj)
      .subscribe((data: School[]) => {
        this.schools = data;
        this.schoolCount = this.schools.length;
        this.totalItems = this.schoolCount;
        // console.log(this.totalItems);

        this.token = this.session.retrieve('access_token');
        // console.log(this.schools);
        this.loading = false;
        if (this.schools.length < ((+this.count * +this.p) - +this.count)) {
          this.p = 1;
        }
        // this.pageChanged();
      });
    //   });
    
    this.query_params = obj;
    this.router.navigate(['/schools'], { queryParams: obj });
  }
  


  pageChange(event) {
    this.p = event;
    var new_query_params = this.getQueryParams();
    new_query_params['current_page'] = event;
    new_query_params['school_paging'] = decodeURIComponent(new_query_params['school_paging']);
    new_query_params['location'] = decodeURIComponent(new_query_params['location']);
    this.router.navigate(['/schools'], { queryParams: new_query_params });
  }

  getAreaByCity(event): void {
    this.ss.getAreaByCity(event.target.value)
      .subscribe((data: any) => {
        this.areas = data.areas;
        this.get_schools();
      });
  }
  changeAffilation(event): void {
    // this.ss.getFilterRequiredData(event.target.value)
    //   .subscribe
    // this.query_params.obj['affilation_program'] === event.value.target;
    this.affiliation = event.target.value;
    this.get_schools();
  }

  pageChanged(){
    this.get_schools();
    // this.schools = this.schools.slice(( this.currentPage - 1) * this.itemsPerPage, this.currentPage * this.itemsPerPage);
    console.log(this.schools);
  }



  assign_checkbox(selector, obj): void {
    var data = [];
    $('.' + selector).each(function () {
      if ($(this).is(':checked')) {
        data.push($(this).val());
      }
    });
    if (this.util.isNotEmpty(data)) {
      obj[selector] = data.join(',');
    } else {
      obj[selector] = ([obj[selector]]).join(',');
    }
  }

  assign_radio(selector, obj): void {
    let data = this.elRef.nativeElement.querySelector('input[name="' + selector + '"]:checked');
    if (this.util.isNotEmpty(data)) {
      obj[selector.replace('_res', '')] = data.value;
    }
  }

  home_submit(): void {
    let form_data = this.elRef.nativeElement.querySelectorAll("#from_location");
    let params = {
      "admission_status": "On",
      "affilation_program": 'CBSC',
      "area": "",
      "area_distance": "0",
      "by_gender": "All",
      "facility": "",
      "fee_from": "200",
      "fee_to": "800",
      "location": form_data[0].value,
      "school_paging": "100",
      "school_type": "Private",
      "school_upto": ""
    };

    this.router.navigate(['/schools'], { queryParams: params }).then(() => window.location.reload());
  }

  getSchoolInfo(school_id): void {
    var school_div = $('.school_' + school_id);
    if (school_div.is(":visible")) {
      school_div.css('display', 'none');
    } else {
      this.ss.getSchool(school_id).subscribe(res => {
        school_div.html(this.getSchoolInfoHtml(res));
        school_div.css('display', 'block');

        $('.horizontalTab_' + school_id).easyResponsiveTabs({
          type: 'default',
          width: 'auto',
          fit: true,
          closed: 'accordion',
          activate: function (event) {
            var $tab = $(this);
            var $info = $('#tabInfo');
            var $name = $('span', $info);
            $name.text($tab.text());
            $info.show();
          }
        });
      });
    }
  }

  getQueryParams(): any {
    return window.location.search.length === 0
      ? {}
      : window.location.search
        .substr(1)
        .split('&')
        .map(pairString => pairString.split('='))
        .reduce((out, pair) => {
          out[pair[0]] = pair[1];
          return out;
        }, {});
  }

  ngAfterViewInit() {
    /*this.route.queryParams.subscribe(params => {
        if(Object.keys(params).length != 0){
          this.search_params = params;
        }
    }); */

    if (!(this.util.isHome())) {
      var obj = this;
      let toChangeValues = {};
      toChangeValues = this.getQueryParams();
      // toChangeValues['admission_status'] = "";
      // toChangeValues['affilation_program'] = "";
      // toChangeValues['by_gender'] = "";
      // toChangeValues['school_type'] = "";
      // toChangeValues['area'] = "";
      // toChangeValues['area_distance'] = "0";
      // toChangeValues['by_gender'] = "Co-Ed";
      // toChangeValues['facility'] = "";
      // toChangeValues['fee_from'] = "";
      // toChangeValues['fee_to'] = "";
      // toChangeValues['location'] = "";
      // toChangeValues['school_paging'] = "100 School";
      // toChangeValues['school_type'] = "";
      // toChangeValues['school_upto'] = "";

      obj.get_schools(toChangeValues);
    }

    $(".js-range-slider1").ionRangeSlider({
      min: 0,
      max: 150,
      from: this.query_params.area_distance,
      postfix: "km",
      onStart: function (data) {
        $('#area_distance').val(data.from);
      },
      onFinish: function (data) {
        var area_val = $('.search_from').val();
        $('.area_search').val(area_val);
        $('#area').val(area_val);
        $('#area_distance').val(data.from);
        obj.get_schools();
      },
    });

    $(document).on("click", ".close-bar", function () {
      $(this).siblings(".resp-tabs-container").slideToggle("");
    });

    $('.affilation_program').change(function () {
      obj.get_schools();
    })
    $('.by_gender').change(function () {
      obj.get_schools();
    })
    $('.school_type').change(function () {
      obj.get_schools();
    })

    $(".js-range-slider").ionRangeSlider({
      type: "double",
      grid: true,
      min: 0,
      max: 1000,
      from: (this.query_params.fee_from) ? this.query_params.fee_from : 200,
      to: (this.query_params.fee_to) ? this.query_params.fee_to : 800,
      prefix: "₹",
      disable: true,
      onStart: function (data) {
        $('#fee_from').val(data.from);
        $('#fee_to').val(data.to);
      },
      onFinish: function (data) {
        $('#fee_from').val(data.from);
        $('#fee_to').val(data.to);
        obj.get_schools();
      },
    });

    $('.area_submit').click(function () {
      var area_val = $(this).closest('.refinment_row').find('.search_from').val();
      $('.area_search').val(area_val);
      $('#area').val(area_val);
      $('.no_responsive .refinment_row').css({ 'visibility': 'hidden', 'opacity': 0 });
      obj.get_schools();
    });

    $('.reset_btn').click(function () {
      var area_val = $(this).closest('.refinment_row').find('.search_from').val('').val();
      $('.area_search').val(area_val);
      $('#area').val(area_val);
      $(".js-range-slider1").data("ionRangeSlider").update({ from: 0 });
      $('#area_distance').val(0);
      obj.get_schools();
    });

    $(document).on('change', '.facilities_options', function () {
      obj.get_schools();
    })

    $('.multiselect').change(function () {
      obj.get_schools();
    })

    $('input[name=admission_status_res]').change(function () {
      obj.get_schools();
    })
    $('input[name=school_paging_res]').change(function () {
      obj.get_schools();
    })

    //No Responsive
    $(".no_responsive .dropdown-menu .dropdown-item").click(function () {
      $(this).closest('.dropdown').find('a.dropdown-toggle').text($(this).text());
      $(this).closest('.dropdown-menu').css({ 'visibility': 'hidden', 'opacity': 0 });
      obj.get_schools();
    });
    $('.dropdown-menu a').click(function() {
      $(this).addClass('selected').siblings().removeClass('selected');
  }); 
    // $(".no_responsive.refinment_row_holder").hover(function () {
    //   $(this).find('.refinment_row').css({ 'visibility': 'visible', 'opacity': 1 });
    // },
    //   function () {
    //     $(this).find('.refinment_row').css({ 'visibility': 'hidden', 'opacity': 0 });
    //   });

    $(".no_responsive .col").hover(function () {
      $(this).find('.dropdown-menu').css({ 'visibility': 'visible', 'opacity': 1 });
    },
      function () {
        $(this).find('.dropdown-menu').css({ 'visibility': 'hidden', 'opacity': 0 });
      });
    //End Responsive

    $(document).on('click', 'button.btn-dark', function (e) {
      e.preventDefault();
      var url = $(this).find('a').attr('href');
      var type = $(this).find('a').data('type');
      if (url) {
        var link = document.createElement('a');
        link.href = url;
        if (type != 'Form') {
          link.target = '_blank';
        } else {
          link.download = 'true';
        }
        link.click();
        return true;
      }
      alert(type + ' is not uploaded');
      return false;
    })
  }

  getSchoolInfoHtml(school): any {
    var max_number = school.school_details.max_class_number;
    var standard = school.standard;
    var fee_types = school.fee_types;
    var fee_details = school.fee_details;
    var school_address = school.school_address;
    var school_media = school.school_medias;
    var full_school_address = this.util.getFullAddress(school_address);

    var brochure = '';
    for (var p = 0; p < school_media.length; p++) {
      if (school_media[p].type == 'brochure') {
        brochure = school_media[p].media;
      }
    }

    var data = `
    <div class="project-tab horizontalTab_`+ school.id + `">
        <ul class="resp-tabs-list">
            <li>About</li>
            <li>Contact</li>
            <li>Pictures</li>
            <li>Facilities</li>
        </ul>
        <div class="resp-tabs-container">

            <div class="tab-content fadeIn">
                <div class="school-rating">
                    <div class="row">
                        <div class="col-md-7 col-lg-12">
                            <div class="text-left">
                                <div class="about-heading" >` + school.school_name + `</div>
                                <div class="about-text">
                                    ` + school.about + `
                                </div>
                            </div>
                        </div>
                        <div class="col-md-5 col-lg-12">
                            <div class="img-loaction">
                                <a href="#"><svg style="width:25px; margin-right:10px;" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                viewBox="0 0 45.3 45.6" enable-background="new 0 0 45.3 45.6" xml:space="preserve">
                             <g>
                               
                                 <circle fill="none" stroke="#111B44" stroke-width="1.3469" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="22.4" cy="23.1" r="18.9"/>
                               
                                 <ellipse fill="none" stroke="#111B44" stroke-width="1.3469" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="22.4" cy="23.1" rx="7" ry="18.9"/>
                               
                                 <path fill="none" stroke="#111B44" stroke-width="1.3469" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                                 M22.4,13.4c-5.5,0-10.5-1.2-13.9-3c-3.1,3.4-4.9,7.8-4.9,12.7c0,4.9,1.9,9.3,4.9,12.7c3.4-1.8,8.4-3,13.9-3s10.5,1.2,13.9,3
                                 c3.1-3.4,4.9-7.8,4.9-12.7c0-4.9-1.9-9.3-4.9-12.7C32.9,12.3,27.9,13.4,22.4,13.4z"/>
                               
                                 <polyline fill="none" stroke="#111B44" stroke-width="1.3469" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="
                                 18.4,20.4 20.3,25.8 22.5,21.1 24.3,25.8 26.4,20.4 	"/>
                               
                                 <polyline fill="none" stroke="#111B44" stroke-width="1.3469" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="
                                 31.1,20.4 33.1,25.8 35.2,21.1 37.1,25.8 39.1,20.4 	"/>
                               
                                 <polyline fill="none" stroke="#111B44" stroke-width="1.3469" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="
                                 5.4,20.4 7.4,25.8 9.5,21.1 11.3,25.8 13.4,20.4 	"/>
                             </g>
                             </svg>
                                    ` + full_school_address + `
                                </a>`;

    if (this.util.isNotEmpty(school.website)) {
      data += `<a href="` + school.website + `">`;
      if (this.util.isNotEmpty(school.school_details.std_code + '-' + school.school_details.office_phone_1)) {
        data += `<svg style="width:25px; margin-right:10px;" version="1.1" id="Icons" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        viewBox="0 0 32 32" style="enable-background:new 0 0 32 32;" xml:space="preserve">
     <style type="text/css">
       .st0{fill:none;stroke:#000000;stroke-width:2;stroke-miterlimit:10;}
       .st1{fill:none;stroke:#000000;stroke-width:2;stroke-linejoin:round;stroke-miterlimit:10;}
       .st2{fill:none;stroke:#000000;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
       .st3{fill:none;stroke:#000000;stroke-width:2;stroke-linecap:round;stroke-miterlimit:10;}
       .st4{fill:none;stroke:#000000;stroke-width:2;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:3;}
     </style>
     <path class="st1" d="M17.6,18.8c-0.4,0.4-0.8,1-1.1,1.5c-1.8-1.4-3.4-3.1-4.8-4.8c0.6-0.3,1.1-0.7,1.5-1.1c2-2,1.3-4.6-0.7-6.6
       S8,5,5.9,7.1s-2.3,5.7-0.7,8c2.9,4.4,7.3,8.8,11.7,11.7c2.4,1.6,6,1.3,8-0.7s1.3-4.6-0.7-6.6S19.6,16.7,17.6,18.8z"/>
     <path class="st2" d="M27.9,13.9c0-5.5-4.4-9.9-9.9-9.9"/>
     <path class="st2" d="M23,13.7c0-2.6-2.2-4.7-4.9-4.7"/>
     </svg>
                                        ` + school.school_details.std_code + '-' + school.school_details.office_phone_1;
      }
      data += `<span> | Official Homepage</span></a>`;
    }

    data += `</div>
                            <div class="download-btn mb-2">
                                <button class="btn btn-dark"><a href="`+ brochure + `" data-url="` + brochure + `" data-type="Brochure" class="check_download_url"><svg enable-background="new 0 0 32 32"  id="Слой_1" version="1.1" viewBox="0 0 32 32"  xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g id="Download"><path d="M31,22c-0.552,0-1,0.448-1,1v7H2v-7c0-0.552-0.448-1-1-1s-1,0.448-1,1v8c0,0.552,0.448,1,1,1h30   c0.552,0,1-0.448,1-1v-8C32,22.448,31.552,22,31,22z" fill="#121313"/><path d="M15.27,23.707c0.389,0.385,1.04,0.389,1.429,0l6.999-6.9c0.395-0.391,0.394-1.024,0-1.414   c-0.394-0.391-1.034-0.391-1.428,0l-5.275,5.2V1c0-0.552-0.452-1-1.01-1c-0.558,0-1.01,0.448-1.01,1v19.593l-5.275-5.2   c-0.395-0.391-1.034-0.391-1.428,0c-0.395,0.391-0.395,1.024,0,1.414L15.27,23.707z" fill="#121313"/></g><g/><g/><g/><g/><g/><g/></svg>Download Brochure</a></button>
                            </div>
                            <!-- <div class="map-section mt-4">
                                <h2>Bus Route</h2>
                                <a href=""><img src="assets/img/arrow__right.png" alt="" /> Bus Route for juniors class 1 to class 5.</a><br />
                                <a href=""><img src="assets/img/arrow__right.png" alt="" /> Bus Route for seniors class 6 to class 10.</a>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>

            <div class="tab-content fadeIn">
                <div class="school-rating">
                    <h3>Contact Us</h3>
                    <div class="row">
                        <div class="text-center col-12 col-sm-3 contact-tab-text mb-3 mb-md-0">
                            <img src="assets/img/call.png" alt="">
                            <div class="contact-heading">Call Us</div>`;

    if (this.util.isNotEmpty(school.school_details.office_phone_1)) {
      data += `<div class="contact-text">` + school.school_details.std_code + '-' + school.school_details.office_phone_1 + `</div>`;
    }
    if (this.util.isNotEmpty(school.school_details.office_phone_2)) {
      data += `<div class="contact-text">` + school.school_details.std_code + '-' + school.school_details.office_phone_2 + `</div>`;
    }
    if (this.util.isNotEmpty(school.school_details.office_phone_3)) {
      data += `<div class="contact-text">` + school.school_details.std_code + '-' + school.school_details.office_phone_3 + `</div>`;
    }

    data += `</div>
                        <div class="text-center col-12 col-sm-3 contact-tab-text mb-3 mb-md-0">
                            <img src="assets/img/mail.png" alt="">
                            <div class="contact-heading">Email</div>`;
    if (this.util.isNotEmpty(school.school_email)) {
      data += `<div class="contact-text"><a href="mailto:` + school.school_email + `">` + school.school_email + `</a></div>`;
    }
    data += `</div>
                        <div class="text-center col-12 col-sm-3 contact-tab-text mb-3 mb-md-0">
                            <img src="assets/img/world.png" alt="">
                            <div class="contact-heading">Visit us</div>
                            <div class="contact-text"><a href="` + school.website + `" target="_blank">` + school.website + `</a></div>
                        </div>
                        <div class="text-center col-12 col-sm-3 contact-tab-text">
                            <img src="assets/img/location.png" alt="">
                            <div class="contact-heading">Address</div>
                            <div class="contact-text">` + full_school_address + `</div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="tab-content fadeIn">
                <div class="image-grid">`;
    if (school_media.length > 0) {
      for (var v = 0; v < school_media.length; v++) {
        if (school_media[v].type == 'gallary') {
          data += `<div class="school-type-images">
                                <img src="`+ school_media[v].media + `" alt="logo" class="img-fluid">
                            </div>`;
        }
      }
    } else {
      data += `<div class="school-type-images">No images found</div>`;
    }

    data += `</div>
            </div>

            <div class="tab-content fadeIn">
                <div class="school-rating">
                    <h3>SCHOOL FACILITY</h3>
                    <div class="d-flex justify-content-between flex-wrap mb-4 prdct-images">`;

    if ((school.school_facilities).length > 0) {
      $.each(school.school_facilities, function (index, facility) {
        data += `<div class="text-center">
                                    <img src="`  + facility.facilities.logo + `" alt="">
                                    <div class="name">`+ facility.facilities.name + `</div>
                                </div>`;
      });
    } else {
      data += `<div class="text-center">No facility found</div>`;
    }

    data += `</div>
                </div>
            </div>`;

    data += `

        </div>
        <div class="close-bar text-center d-none d-md-block">
            <a href="javascript:void(0)"><i class="fa fa-angle-up" aria-hidden="true"></i>Close</a>
        </div>
    </div>`;

    return data;
  }
}
