<section class="main common-form">
	<div class="top-logo text-center">
		<img src="assets/img/logo.png" width="200" alt="logo" class="img-fluid">
        <h3>{{schoolMainName}} Common Admission Form</h3>
	</div>
	<div class="container" >
		    <div class="main-outer col-md-12 m-auto p-0" *ngIf="userDetails">
				<div class="form-top">
                    <div class="form-heading">
                        <h2>Child</h2>
                    </div>
                    <div class="form-outer-top">
                        <h5>Basic Information</h5>
							<div class="form-outer mb-1">
								<div class="input-tab login">
									<div class="position-relative">
                                        <label for="fname">First Name *:</label>
                                        <span>{{ userDetails.fname }}</span>
									</div>
								</div>
							<div class="input-tab login">
								<div class="position-relative">
                                    <label for="mname">Middle Name :</label>
                                    <span>{{ userDetails.mname }}</span>
								</div>
							</div>
                            <div class="input-tab login">
                                <div class="position-relative">
                                    <label for="lname">Last Name *:</label>
                                    <span>{{ userDetails.lname }}</span>
                                </div>
                            </div>
                            <div class="input-tab login">
								<div class="position-relative">
                                    <label for="dob">Date of birth *:</label>
                                    <span>{{ userDetails.dob }}</span>
								</div>
                            </div>
                            <div class="input-tab login">
                                <div class="position-relative">
                                    <label for="classname">Class of which admission sought *:</label>
                                    <span>{{ userDetails.class }}</span>
                                </div>
                            </div>
							<div class="input-tab login">
								<!-- <div class="position-relative">
									<input type="text" formControlName="gender" [ngModel]="userDetails.gender" placeholder="Gender" id="gender" name="gender">
									<div *ngIf="(f['gender'].errors && !f['gender'].pristine) || (submitted && f['gender'].errors)" class="error-inp">
										<span *ngIf="(f['gender'].errors.required)" [hidden]="!f['gender'].errors.required">Please enter gender.</span>
									</div>
                                </div> -->
                                <div class="input-tab login">
                                    <label for="gender">Gender *:</label>
                                    <span>{{ userDetails.gender }}</span>
                                </div>
                            </div>
                            <div class="input-tab login">
                                <div class="position-relative">
                                    <label for="cast">Category *:</label>
                                    <span>{{ userDetails.cast }}</span>
                                </div>
                            </div>
                            <div class="input-tab login">
								<div class="position-relative">
                                    <label for="nationality">Nationality :</label>
                                    <span>{{ userDetails.nationality }}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="form-outer-top">
                        <h5>Detail of previous school</h5>
						<div class="form-outer mb-1">
							<div class="input-tab login">
								<div class="position-relative">
                                    <label for="school_name">Name of school :</label>
                                    <span>{{ userDetails.school_name }}</span>
								</div>
							</div>
							<div class="input-tab login">
								<div class="position-relative">
                                    <label for="last_class_attended">Last class attended :</label>
                                    <span>{{ userDetails.last_class_attended }}</span>
                                </div>
							</div>
							<div class="input-tab login">
								<div class="position-relative">
                                    <label for="address_of_school">Address of school :</label>
                                    <span>{{ userDetails.address_of_school }}</span>
								</div>
							</div>

						</div>
                    </div>

                    <div class="form-outer-top">
                        <h5>Medical Information</h5>
                        <div class="form-outer mb-1">
                            <div class="input-tab login">
                                <div class="position-relative">
                                    <label for="blood_group">Blood Group *:</label>
                                    <span>{{ userDetails.blood_group }}</span>
                                </div>
                            </div>
                            <div class="input-tab login">

                                
                            </div>
                            <div class="input-tab login">
                                <div class="position-relative" style="display: flex; flex-direction: row; justify-content: space-between;">
                                    <!-- <input type="text" placeholder="Special need child" id="special_child" name="special_child" value="{{userDetails.special_child}}"> -->
                                    <label>
                                        Special need child: 
                                    </label>
                                    <label class="special-text">
                                        <input id="special_child" name="special_child" type="checkbox" style="width: 10%;" [checked]="userDetails.special_child" [disabled]="true">
                                        <span></span>
                                    </label>
                                </div>
                                <div class="position-relative">
                                    <label for="details">If yes then give details :</label>
                                    <span>{{ userDetails.details }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

				<div class="form-top">
                    <div class="form-heading">
                        <h2>Father</h2>
                    </div>
                    <div class="form-outer-top">
                        <h5>Basic Information</h5>
                        <div class="form-outer mb-1">
                            <div class="input-tab login">
                                <div class="position-relative">
                                    <label for="father_name">Name:</label>
                                    <span>{{ userDetails.father_name }}</span>
                                </div>
                            </div>
                            <div class="input-tab login">
                                <div class="position-relative">
                                    <label for="nationality">Nationality *:</label>
                                    <span>{{ userDetails.fnationality }}</span>
                                </div>
                            </div>
                            <div class="input-tab login">
                                <div class="position-relative">
                                    <label for="highest_academic_qualification">Highest academic qualification :</label>
                                    <span>{{ userDetails.highest_academic_qualification }}</span>
                                </div>
                            </div>
                            <div class="input-tab login">
                                <div class="position-relative">
                                    <label for="phone_1">Mobile Number :</label>
                                    <span>{{ userDetails.phone_1 }}</span>
                                </div>
                            </div>
                            <div class="input-tab login">
                                <div class="position-relative">
                                    <label for="phone_2">Alternate Number :</label>
                                    <span>{{ userDetails.phone_2 }}</span>
                                </div>
                            </div>
                            <div class="input-tab login">
                                <div class="position-relative">
                                    <label for="office_phone">Office phone :</label>
                                    <span>{{ userDetails.office_phone }}</span>
                                </div>
                            </div>
                            <div class="input-tab login">
                                <div class="position-relative">
                                    <label for="occupation">Occupation :</label>
                                    <span>{{ userDetails.occupation }}</span>
                                </div>
                            </div>
                            <div class="input-tab login">
                                <div class="position-relative">
                                    <label for="address_of_occupation">Address of Occupation :</label>
                                    <span>{{ userDetails.address_of_occupation }}</span>
                                </div>
                            </div>
                            <div class="input-tab login">
                                <div class="position-relative">
                                    <label for="email_id">Email id :</label>
                                    <span>{{ userDetails.email_id }}</span>
                                </div>
                            </div>
						</div>
					</div>
				</div>

                <div class="form-top">
                    <div class="form-heading">
                        <h2>Mother</h2>
                    </div>
                    <div class="form-outer-top">
                        <h5>Basic Information</h5>
						<div class="form-outer mb-1">
							<div class="input-tab login">
								<div class="position-relative">
                                    <label for="mother_name">Name :</label>
                                    <span>{{ userDetails.mother_name }}</span>
								</div>
							</div>
							<div class="input-tab login">
                                <div class="position-relative">
                                    <label for="mnationality">Nationality *:</label>
                                    <span>{{ userDetails.mnationality }}</span>
                                </div>
                            </div>
                            <div class="input-tab login">
                                <div class="position-relative">
                                    <label for="mhighest_academic_qualification">Highest academic qualification :</label>
                                    <span>{{ userDetails.mhighest_academic_qualification }}</span>
                                </div>
                            </div>
                            <div class="input-tab login">
                                <div class="position-relative">
                                    <label for="mphone_1">Mobile Number :</label>
                                    <span>{{ userDetails.mphone_1 }}</span>
								</div>
                            </div>
                            <div class="input-tab login">
                                <div class="position-relative">
                                    <label for="mphone_2">Alternate Number :</label>
                                    <span>{{ userDetails.mphone_2 }}</span>
                                </div>
                            </div>
                            <div class="input-tab login">
                                <div class="position-relative">
                                    <label for="moffice_phone">Office phone :</label>
                                    <span>{{ userDetails.moffice_phone }}</span>
                                </div>
                            </div>
                            <div class="input-tab login">
                                <div class="position-relative">
                                    <label for="moccupation">Occupation :</label>
                                    <span>{{ userDetails.moccupation }}</span>
								</div>
                            </div>
                            <div class="input-tab login">
                                <div class="position-relative">
                                    <label for="maddress_of_occupation">Address of Occupation :</label>
                                    <span>{{ userDetails.maddress_of_occupation }}</span>
                                </div>
                            </div>
                            <div class="input-tab login">
                                <div class="position-relative">
                                    <label for="memail_id">Email id :</label>
                                    <span>{{ userDetails.memail_id }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="form-top">
                    <div class="form-heading">
                        <h2>Address</h2>
                    </div>
                    <div class="form-outer-top">
                        <h5>Present Address</h5>
                        <div class="form-outer mb-1">
                            <div class="input-tab login">
                                <div class="position-relative">
                                    <label for="present_address">Address :</label>
                                    <span>{{ userDetails.present_address }}</span>
                                </div>
                            </div>
                            <div class="input-tab login">
                                <div class="position-relative">
                                    <label for="city">City :</label>
                                    <span>{{ userDetails.city }}</span>
                                </div>
                            </div>
                            <div class="input-tab login">
                                <div class="position-relative">
                                    <label for="state">State :</label>
                                    <span>{{ userDetails.state }}</span>
                                </div>
                            </div>
                            <div class="input-tab login">
								<div class="position-relative">
                                    <label for="pin_code">Pin code :</label>
                                    <span>{{ userDetails.pin_code }}</span>
								</div>
							</div>
                        </div>
                    </div>
                    <div class="form-outer-top" style="width: 50%; display: flex; flex-direction: row; justify-content: space-between;">
                        <label>
                            Present Address same as Permanent Address: 
                        </label>
                        <label class="special-text">
                            <input type="checkbox" style="width: 10%;" [(ngModel)]="checkAddress" [ngModelOptions]="{standalone: true}" [disabled]="true">
                            <span></span>
                        </label>
                    </div>
                    <div class="form-outer-top">
						<h5>Permanent Address</h5>
                        <div class="form-outer mb-1">
                            <div class="input-tab login">
                                <div class="position-relative">
                                    <label for="permanent_address">Address :</label>
                                    <span>{{ userDetails.permanent_address }}</span>
                                </div>
	                        </div>
                            <div class="input-tab login">
                                <div class="position-relative">
                                    <label for="p_city">City :</label>
                                    <span>{{ userDetails.p_city }}</span>
                                </div>
                            </div>
                            <div class="input-tab login">
                                <div class="position-relative">
                                    <label for="p_state">State :</label>
                                    <span>{{ userDetails.p_state }}</span>
                                </div>
                            </div>
                            <div class="input-tab login">
								<div class="position-relative">
                                    <label for="p_pin_code">Pin code :</label>
                                    <span>{{ userDetails.p_pin_code }}</span>
                                </div>
							</div>
                        </div>
					</div>
                </div>

                <div class="form-top">
                    <div class="form-heading">
                        <h2>General</h2>
                    </div>
                    <div class="form-outer-top">
                        <h5>Guardian</h5>
                        <div class="form-outer mb-1">
                            <div class="input-tab login">
                                <div class="position-relative">
                                    <label for="gname">Name :</label>
                                    <span>{{ userDetails.gname }}</span>
                                </div>
                            </div>
                            <div class="input-tab login">
                                <div class="position-relative">
                                    <label for="relationship">Relationship :</label>
                                    <span>{{ userDetails.relationship }}</span>
                                </div>
                            </div>
                            <div class="input-tab login">
                                <div class="position-relative">
                                    <label for="phone">Mobile Number :</label>
                                    <span>{{ userDetails.phone }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-outer-top">
                        
                            <div class="">
                                <div class="position-relative">
                                    <label for="parent_status"><h5>Parent Status : </h5></label>
                                    <span>{{ userDetails.parent_status }}</span>
                                </div>
                            </div>
                            <div class="input-tab login hidden">
                                <div class="position-relative">
                                    <label for="windowname">Widow :</label>
                                    <span>{{ userDetails.windowname }}</span>
                                </div>
                            </div>
                            <div class="input-tab login hidden">
                                <div class="position-relative">
                                    <label for="windowername">Widower :</label>
                                    <span>{{ userDetails.windowername }}</span>
                                </div>
                            </div>
                            <div class="input-tab login">
                                <label for="whether_police_defence_personnel">Whether police/defence personnel :</label>
                                <span>{{ userDetails.whether_police_defence_personnel == 1 ? 'Yes' : 'No' }}</span>
                        </div>
                    </div>
                    <div class="form-outer-top">
						<h5>Sibling study in school ?</h5>
                        <div class="form-outer mb-1">
                            <div class="input-tab login">
                                <div class="position-relative">
                                    <label for="sname">Sibling Name :</label>
                                    <span>{{ userDetails.sname }}</span>
                                </div>
                            </div>
                            <div class="input-tab login">
                                <div class="position-relative">
                                    <label for="sclass">Class:</label>
                                    <span>{{ userDetails.sclass }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-outer-top">
                        <h5>Area of interest where parents could enrich the school</h5>
                        <div class="form-outer mb-1">
                            <div class="input-tab login">
								<div class="position-relative">
                                    <label for="hobbies">Hobbies :</label>
                                    <span>{{ userDetails.hobbies }}</span>
								</div>
                            </div>
						</div>
                    </div>
                </div>
 
                <div class="form-outer-top">
                     <div class="form-group">
                        <label for="information">Any other information you would like to share :</label>
                        <span>{{ userDetails.information }}</span>
                     </div>
                </div>

				<div class="form-heading">
					<h2>Documents Upload </h2>
				</div>
				<h4 style="color:red;margin-left:1em;">Allow file type (PDF OR JPG)</h4>
				<div class="form-row" style="padding:1em;">
					<div class="form-group col-md-6">
						<label for="file-input">Date of birth certificate of child *<img src="assets/img/up-arrow.png" class="ml-2"/></label>
                        <p *ngIf="userDetails.date_of_birth_certificate_of_child; else NA" class="pl-4 align-middle mb-0"><a [href]="image_uri + userDetails.date_of_birth_certificate_of_child" target="_blank">Click Here to download</a></p>
					</div>
					<div class="form-group col-md-6">
						<label for="file-input"> Voter ID <img src="assets/img/up-arrow.png" class="ml-2"/></label>
                        <p *ngIf="userDetails.proof_of_residence; else NA" class="pl-4 align-middle mb-0"><a [href]="image_uri + userDetails.proof_of_residence" target="_blank">Click Here to download</a></p>
					</div>
				</div>
				<div class="form-row" style="padding:1em;">
					<div class="form-group col-md-6">
						<label for="file-input">Aadhar Card <img src="assets/img/up-arrow.png" class="ml-2"/></label>
                        <p *ngIf="userDetails.proof_types; else NA" class="pl-4 align-middle mb-0"><a [href]="image_uri + userDetails.proof_types" target="_blank">Click Here to download</a></p>
					</div>
					<div class="form-group col-md-6">
						<label for="file-input">Current Electricity/Telephone/Water Bill<img src="assets/img/up-arrow.png" class="ml-2"/></label>
                        <p *ngIf="userDetails.proof_of_sibling; else NA" class="pl-4 align-middle mb-0"><a [href]="image_uri + userDetails.proof_of_sibling" target="_blank">Click Here to download</a></p>
					</div>
				</div>
				<div class="form-row" style="padding:1em;">
					<div class="form-group col-md-6">
						<label for="file-input">School leaving certificate (if applicable) <img src="assets/img/up-arrow.png" class="ml-2"/></label>
                        <p *ngIf="userDetails.school_leaving_certificate; else NA" class="pl-4 align-middle mb-0"><a [href]="image_uri + userDetails.school_leaving_certificate" target="_blank">Click Here to download</a></p>
					</div>
                </div>
                <ng-template #NA><p class="pl-4 align-middle mb-0">NA</p></ng-template>
				<!-- <div class="total-count d-flex justify-content-center text-center my-5">
					<button type="submit" class="btn btn-dark">Submit to School</button>
				</div> -->
			</div>
	</div>
</section>
<div><ngx-spinner type="ball-clip-rotate-multiple" size="medium"></ngx-spinner></div>
