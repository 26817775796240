import { Component, ViewChild, OnInit, ElementRef, Input, Output, EventEmitter , Renderer2 } from '@angular/core';
import { SearchService } from '../search.service';
import Utils from '../utils';
import { SlimLoadingBarService } from 'ng2-slim-loading-bar';
import { DeviceDetectorService } from 'ngx-device-detector';

import {
  ActivatedRoute,
  NavigationCancel,
  Event,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router
} from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './front.component.html',
  styleUrls: ['./front.component.css']
})
export class FrontComponent implements OnInit {
 
  public cities: any;
  public util: any;
  params;
  params2;
  public dropdownState: any = {
    singleSelection: true,
    text: "Select ",
    //enableCheckAll: false,
    //badgeShowLimit:4,
    //selectAllText: 'Select All',
    //unSelectAllText: 'UnSelect All',
    enableSearchFilter: true,
    classes: "myclass custom-class"
  }
  

  dropdownList = [];
    selectedItems = [];
    dropdownSettings = {};
    
  

  constructor(private route: ActivatedRoute, private loadingBar: SlimLoadingBarService, private router: Router, private elRef: ElementRef, private ss: 
    SearchService, private deviceService: DeviceDetectorService) {
    this.ss
      .getFilterRequiredData()
      .subscribe((data: any) => {
        let cities = []
        data.cities.map((item,index)=>{
          cities.push( { "id": index, "itemName": item })
        })
        this.cities = cities;
        cities.splice(0,0,( { "id": "", "itemName": "All" }))
      });

    this.util = Utils;
    this.router.events.subscribe((event: Event) => {
      this.navigationInterceptor(event);
    });
  }

  ngOnInit(){
     
    this.selectedItems = [];
    this.dropdownSettings = { 
      singleSelection: true, 
      text:"Select ",
      enableSearchFilter: true,
      classes:"myclass"
    };   
    const body=document.getElementsByTagName('body')[0];
  body.classList.add('home_body');         
}
 
ngOnDestroy(){
  const body=document.getElementsByTagName('body')[0];
  body.classList.remove('home_body');
}
  private navigationInterceptor(event: Event): void {
    if (event instanceof NavigationStart) {
      this.loadingBar.start();
    }
    if (event instanceof NavigationEnd) {
      this.loadingBar.complete();
    }
    if (event instanceof NavigationCancel) {
      this.loadingBar.stop();
    }
    if (event instanceof NavigationError) {
      this.loadingBar.stop();
    }
  }

  home_submit(): void {
    let main: any;
    let form_data = this.selectedItems[0].itemName;
    console.log(form_data[0].value);
      this.params = {
        "admission_status": "",
        "affilation_program": "",
        "area": "",
        "area_distance": "0",
        "by_gender": "All",
        "facility": "",
        "location": "",
        "school_paging": "",
        "school_type": "",
        "school_upto": ""
      };
      this.params2 = {
        "admission_status": "",
        "affilation_program": "",
        "area": "",
        "area_distance": "0",
        "by_gender": "All",
        "facility": "",
        "location": this.selectedItems[0].itemName,
        "school_paging": "",
        "school_type": "",
        "school_upto": ""
      };
    if (this.selectedItems[0].itemName == 'All') {
        main = this.params;
    }
    if (this.selectedItems[0].itemName != 'All') {
      main = this.params2;
  }
    this.router.navigate(['/schools'], { queryParams: main });
  }


}


