<section class="main_school_list">
    <div class="search_school_top">
        <div class="filter-box">
            <input type="text" [(ngModel)]="searchSchool" name="searchSchool"
                placeholder="Search By School Name" class="form-control">
        </div>
    </div>
    <div class="main-outer">
        <div class="main_outer_left">
            <div class="container">
                <input type="hidden" class="form_data" id="area" name="area" value="{{  query_params?.area }}">
                <input type="hidden" class="form_data" name="area_distance" id="area_distance"
                    value="{{ query_params?.area_distance }}">
                <input type="hidden" class="form_data" name="fee_from" id="fee_from"
                    value="{{ query_params?.fee_from }}">
                <input type="hidden" class="form_data" name="fee_to" id="fee_to" value="{{ query_params?.fee_to }}">

                <div class="form-outer form_top no-gutters">
                    <div class="col">
                        <div class="form-group form-custom postion-relative location_dropdwon nice-select-hover">
                            <input id="test2" type="radio" name="firstradio" class="acordian_radio" checked />
                            <label class="label" for="test2">Location</label>
                            <div class="form_inner"> 
                                <select nice-select class="form-control form_data" id="location"
                                    (change)="getAreaByCity($event)" name="location">
                                    
                                    <option value="">All</option>
                                    <option value="{{ city }}" *ngFor="let city of cities;"
                                        [selected]="city == query_params?.location">{{ util.ucfirst(city) }}</option> 
                                </select> 
                            </div>
                        </div>
                    </div>

                    <div class="col d-md-flex" *ngIf="isDesktopDevice || isTablet">
                        <div
                            class="form-group postion-relative form-custom refinment_row_holder no_responsive location_dropdwon">
                            <input id="test3" type="radio" name="firstradio" class="acordian_radio" />
                            <label class="label" for="test3">Distance</label>
                            <input type="text" class="form-control area_search" placeholder="Area / Locality (eg. Sector 17)" value="{{  query_params?.area }}">
                            <div class="form_inner distance_title dropdown_hover">
                                <h4>Find me a school close to...</h4>
                                <div class="refinment_row">
                                    
                                    <div class="search_row d-flex mb-3">
                                        <select nice-select class="form-control search_from">
                                            <option value="">Select Area</option>
                                            <option value="{{ area}}" *ngFor="let area of areas;"
                                                [selected]="area == query_params?.area">{{ area }}</option>
                                        </select>
                                    </div>
                                    <div class="no-trans">
                                        <input type="text" class="js-range-slider1" name="my_range" value="" />
                                    </div>
                                    <div class="refinment_row_button">
                                        <button class="reset_btn">Reset</button>
                                        <button class="active area_submit">Done</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col d-md-flex" *ngIf="isDesktopDevice || isTablet">
                        <div class="form-group form-custom postion-relative multiselect location_dropdwon nice-select-hover">
                            <input id="test4" type="radio" name="firstradio" class="acordian_radio" />
                            <label class="label" for="test4">School Upto</label>
                            <div class="form_inner">
                                <select nice-select class="form-control form_data" name="school_upto" id="">
                                    <option value="">All</option>
                                    <option value="1" [selected]="(query_params?.school_upto.includes(1))">Play/Primary
                                        UPTO KG
                                    </option>
                                    <option value="2" [selected]="(query_params?.school_upto.includes(2))">Elementary
                                        UPTO IV
                                    </option>
                                    <option value="3" [selected]="(query_params?.school_upto.includes(3))">Middle UPTO
                                        VIII
                                    </option>
                                    <option value="4" [selected]="(query_params?.school_upto.includes(4))">High UPTO X
                                    </option>
                                    <option value="5" [selected]="(query_params?.school_upto.includes(5))">Intermediate
                                        UPTO XII
                                    </option>
                                    <option value="5" [selected]="(query_params?.school_upto.includes(6))">Special
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-outer form_outr_custom form_top no_responsive d-md-flex row m-0 justify-content-md-between col-md-12 p-md-0  flex-wrap filter-outer"
                    *ngIf="isDesktopDevice || isTablet">
                    <div class="col location_dropdwon form-custom">
                        <input id="test5" type="radio" name="firstradio" class="acordian_radio" />
                        <label class="label" for="test5">Admissions Open</label>
                        <div class="dropdown form_inner ">
                            <a class="btn btn-secondary dropdown-toggle hide_withopacity" href="#" role="button" id="admission_status"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                *ngIf="query_params?.admission_status;">{{ query_params?.admission_status }}</a>
                            <a class="btn btn-secondary dropdown-toggle hide_withopacity" href="#" role="button" id="admission_status"
                                *ngIf="!(query_params?.admission_status);" data-toggle="dropdown" aria-haspopup="true"
                                aria-expanded="false">All</a>
                            <div class="new-select dropdown-menu drop_display" aria-labelledby="admission_status">
                                <a class="dropdown-item selected" href="javascript:void(0);">All</a>
                                <a class="dropdown-item" href="javascript:void(0);">On</a>
                                <a class="dropdown-item" href="javascript:void(0);">Off</a>
                            </div>
                        </div>
                    </div>
                    
                  
                    <div class="col location_dropdwon form-custom ">
                        <input id="test8" type="radio" name="firstradio" class="acordian_radio" />
                        <label class="label" for="test8">Type</label>
                        <div class="dropdown form_inner ">
                            <a class="btn btn-secondary dropdown-toggle hide_withopacity" href="#" role="button" id="school_type"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                *ngIf="query_params?.school_type;">{{ query_params?.school_type }}</a>
                            <a class="btn btn-secondary dropdown-toggle hide_withopacity" href="#" role="button" id="school_type"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                *ngIf="!(query_params?.school_type);">All</a>
                            <div class="new-select dropdown-menu  drop_display" aria-labelledby="school_type">
                                <a class="dropdown-item selected" href="javascript:void(0);">All</a>
                                <a class="dropdown-item" href="javascript:void(0);">Govt</a>
                                <a class="dropdown-item" href="javascript:void(0);">Private</a>
                            </div>
                        </div>
                    </div>
                    <div class="col   form-custom drop_down--new">
                        <input id="test7" type="radio" name="firstradio" class="acordian_radio" />
                        <label class="label" for="test7">By Genders</label>
                        <div class="dropdown form_inner">
                            <a class="btn btn-secondary dropdown-toggle" href="#" role="button" id="by_gender"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                *ngIf="query_params?.by_gender;">{{ query_params?.by_gender }}</a>
                            <a class="btn btn-secondary dropdown-toggle" href="#" role="button" id="by_gender"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                *ngIf="!(query_params?.by_gender);">All</a>
                            <div class="new-select dropdown-menu drop_display_none dropdown_hover dropdown_hover_bordered" aria-labelledby="by_gender">
                                <a class="dropdown-item selected" href="javascript:void(0);">All</a>
                                <a class="dropdown-item" href="javascript:void(0);">Boys</a>
                                <a class="dropdown-item" href="javascript:void(0);">Girls</a>
                                <a class="dropdown-item" href="javascript:void(0);">Co-Ed</a>
                            </div>
                        </div>
                    </div>
                    <div class="col location_dropdwon form-custom custom_width custom_width_big affiliation_drop--c nice-select-hover">
                        <input id="test6" type="radio" name="firstradio" class="acordian_radio" />
                        <label class="label" for="test6">Affiliation program</label>
                        <div class="form-group position-relative form_inner">
                            <select nice-select class="form-control form_data" id="board_name"
                                (change)="changeAffilation($event)" name="board_name">
                                <option value="">All</option>
                                <option value="{{board.board_name}}" *ngFor="let board of boards; let i=index">
                                    {{board.board_name}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col location_dropdwon form-custom custom_width drop_down--new">
                        <input id="test9" type="radio" name="firstradio" class="acordian_radio" />
                        <label class="label" for="test9">More Filters</label>
                        <div class="form-group postion-relative filter-srch-tab secondary form_inner m-0">
                            <div class="new-select">
                                <span>Select</span>
                                <div class="school-different-ctgry row m-0 dropdown_hover" ng-repeat="x in placeTypes track by $index">
                                    <div class="f_scroll">
                                        <div class="ctgry-btn" *ngFor="let facility of facilities;" >
                                            <input type="checkbox" id="checkbox{{$index}}" value="{{ facility.id }}" class="facilities_options" [checked]="(query_params?.facility.includes(facility.id))" />
                                            <span for="{{ facility.id }}">{{ facility.name }}</span>
                                        </div>
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="col location_dropdwon form-custom custom_width drop_down--new hide-box">
                        <input id="test10" type="radio" name="firstradio" class="acordian_radio" />
                        <label class="label" for="test10">Show Schools</label>
                        <div class="dropdown form_inner">
                            <a class="btn btn-secondary dropdown-toggle" href="#" role="button" id="school_paging"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                *ngIf="query_params?.school_paging;">{{ query_params?.school_paging }}</a>
                            <a class="btn btn-secondary dropdown-toggle " href="#" role="button" id="school_paging"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                *ngIf="!(query_params?.school_paging);">All</a>
                            <div class="new-select dropdown-menu dropdown_hover dropdown_hover dropdown_hover_bordered" aria-labelledby="school_paging">
                                <a class="dropdown-item selected" href="javascript:void(0);">All</a>
                                <a class="dropdown-item" href="javascript:void(0);">10</a>
                                <a class="dropdown-item" href="javascript:void(0);">25</a>
                                <a class="dropdown-item" href="javascript:void(0);">50</a>
                                <a class="dropdown-item" href="javascript:void(0);">100</a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row filter-outer" *ngIf="isMobile">
                    <div class="d-block d-md-none col-12">
                        <button type="button" class="btn btn-primary mb-3" data-toggle="modal"
                            data-target="#exampleModal">
                            <img src="assets/img/filter.png" class="img-fluid" /> filters
                        </button>
                        <!-- Modal -->
                        <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog"
                            aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div class="modal-dialog" role="document">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div class="modal-body p-0 form-outer">
                                        <div class="col">
                                            <div class="form-group postion-relative refinment_row_holder">
                                                <h4>Find me a school close to...</h4> 
                                                <input type="text" class="form-control area_search" placeholder="Area / Locality (eg. Sector 17)" value="{{  query_params?.area }}">
                                                <div class="refinment_row">
                                                    <div class="search_row d-flex mb-3">
                                                        <select class="form-control search_from">
                                                            <option value="">Select Area</option>
                                                            <option value="{{ area}}" *ngFor="let area of areas;"
                                                                [selected]="area == query_params?.area">{{ area }}
                                                            </option>
                                                        </select>
                                                    </div>
                                                    <div class="no-trans">
                                                        <input type="text" class="js-range-slider1" name="my_range"
                                                            value="" />
                                                    </div>
                                                    <div class="refinment_row_button">
                                                        <button class="reset_btn">Reset</button>
                                                        <button class="active area_submit">Done</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div class="col m-select multiselect">
                                            <label> <img src="assets/img/school-icon.png" alt="icons" class="img-fluid">
                                                School Upto</label>
                                            <div class="form-group postion-relative">
                                                <select class="form_data form-control" name="school_upto_res"
                                                    id="multiselect_1">
                                                    <option value="">All</option>
                                                    <option value="1"
                                                        [selected]="(query_params?.school_upto.includes(1))">
                                                        Play/Primary UPTO KG</option>
                                                    <option value="2"
                                                        [selected]="(query_params?.school_upto.includes(2))">
                                                        Elementary UPTO IV</option>
                                                    <option value="3"
                                                        [selected]="(query_params?.school_upto.includes(3))">
                                                        Middle UPTO VIII</option>
                                                    <option value="4"
                                                        [selected]="(query_params?.school_upto.includes(4))">
                                                        High UPTO X</option>
                                                    <option value="5"
                                                        [selected]="(query_params?.school_upto.includes(5))">
                                                        Intermediate UPTO XII</option>
                                                    <option value="5"
                                                        [selected]="(query_params?.school_upto.includes(6))">
                                                        Special</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col">
                                            <label>Admissions Open</label>
                                            <div class="dropdown">
                                                <div class="new-select dropdown-menu dropdown_menu_custom"
                                                    aria-labelledby="dropdownMenuLink">
                                                    <div class="custom-control custom-radio dropdown-item">
                                                        <input type="radio" id="customRadioInline1"
                                                            name="admission_status_res" class="custom-control-input"
                                                            value="On"
                                                            [checked]="(query_params?.admission_status == 'On')">
                                                        <label class="custom-control-label"
                                                            for="customRadioInline1">On</label>
                                                    </div>
                                                    <div class="custom-control custom-radio dropdown-item">
                                                        <input type="radio" id="customRadioInline2"
                                                            name="admission_status_res" class="custom-control-input"
                                                            value="Off"
                                                            [checked]="(query_params?.admission_status == 'Off')">
                                                        <label class="custom-control-label"
                                                            for="customRadioInline2">Off</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col">
                                            <label>Affiliation program</label>
                                            <div class="dropdown">
                                                <div class="new-select dropdown-menu dropdown_menu_custom"
                                                    aria-labelledby="dropdownMenuLink">

                                                    <div class="custom-control custom-checkbox dropdown-item"
                                                        *ngFor="let board of boards; let i=index">

                                                        <input type="checkbox"
                                                            class="affilation_program custom-control-input"
                                                            id="customControlInline13" value="{{board.board_name}}"
                                                            (change)="changeAffilation($event)">
                                                        <!-- [checked]="(query_params?.facility.includes(facility.id))"> -->

                                                        <label class="custom-control-label"
                                                            for="customControlInline13">{{board.board_name}}</label>
                                                    </div>
                                                    <!-- <select class="form-control form_data" id="board_name" (change)="changeAffilation($event)"
                                                    name="board_name">
                                                    <option value="">All</option>
                                                    <option value="{{board.board_name}}" *ngFor="let board of boards; let i=index">{{board.board_name}}</option>
                                                    </select> -->
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col">
                                            <label>By Gender</label>
                                            <div class="dropdown">
                                                <div class="new-select dropdown-menu dropdown_menu_custom"
                                                    aria-labelledby="dropdownMenuLink">
                                                    <div class="custom-control custom-checkbox dropdown-item">
                                                        <input type="checkbox" class="by_gender custom-control-input"
                                                            id="customControlInline112" value="Boys"
                                                            [checked]="(query_params?.by_gender.includes('Boys'))">
                                                        <label class="custom-control-label"
                                                            for="customControlInline112">Boys</label>
                                                    </div>
                                                    <div class="custom-control custom-checkbox dropdown-item">
                                                        <input type="checkbox" class="by_gender custom-control-input"
                                                            id="customControlInline133" value="Girls"
                                                            [checked]="(query_params?.by_gender.includes('Girls'))">
                                                        <label class="custom-control-label"
                                                            for="customControlInline133">Girls</label>
                                                    </div>
                                                    <div class="custom-control custom-checkbox dropdown-item">
                                                        <input type="checkbox" class="by_gender custom-control-input"
                                                            id="customControlInline134" value="Co-Ed"
                                                            [checked]="(query_params?.by_gender.includes('Co-Ed'))">
                                                        <label class="custom-control-label"
                                                            for="customControlInline134">Co-Ed</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col">
                                            <label>Type</label>
                                            <div class="dropdown">
                                                <div class="new-select dropdown-menu dropdown_menu_custom"
                                                    aria-labelledby="dropdownMenuLink">
                                                    <div class="custom-control custom-checkbox dropdown-item">
                                                        <input type="checkbox" class="school_type custom-control-input"
                                                            id="customControlInline1122" value="Govt"
                                                            [checked]="(query_params?.school_type.includes('Govt'))">
                                                        <label class="custom-control-label"
                                                            for="customControlInline1122">Govt</label>
                                                    </div>
                                                    <div class="custom-control custom-checkbox dropdown-item">
                                                        <input type="checkbox" class="school_type custom-control-input"
                                                            id="customControlInline1333" value="Private"
                                                            [checked]="(query_params?.school_type.includes('Private'))">
                                                        <label class="custom-control-label"
                                                            for="customControlInline1333">Private</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-auto col">
                                            <label> More Filters</label>
                                            <div class="filter-outer">
                                                <div class="form-group filter-srch-tab postion-relative filter-srch-tab">
                                                    <div class="new-select">
                                                        <div class="share-icons py-0">
                                                            <div class="school-different-ctgry row m-0">
                                                                <div class="" *ngFor="let facility of facilities;">
                                                                    <div class="ctgry-btn">
                                                                        <input type="checkbox" value="{{ facility.id }}"
                                                                            class="facilities_options"
                                                                            [checked]="(query_params?.facility.includes(facility.id))">
                                                                        <span>{{ facility.name }}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col">
                                            <label>Show Schools</label>
                                            <div class="dropdown">
                                                <div class="new-select dropdown-menu dropdown_menu_custom"
                                                    aria-labelledby="dropdownMenuLink">
                                                    <div class="custom-control custom-radio dropdown-item">
                                                        <input type="radio" id="customRadioInline189"
                                                            name="school_paging_res" class="custom-control-input"
                                                            value="10"
                                                            [checked]="(query_params?.school_paging == '10')">
                                                        <label class="custom-control-label"
                                                            for="customRadioInline189">10</label>
                                                    </div>
                                                    <div class="custom-control custom-radio dropdown-item">
                                                        <input type="radio" id="customRadioInline190"
                                                            name="school_paging_res" class="custom-control-input"
                                                            value="25"
                                                            [checked]="(query_params?.school_paging == '25')">
                                                        <label class="custom-control-label"
                                                            for="customRadioInline190">25</label>
                                                    </div>
                                                    <div class="custom-control custom-radio dropdown-item">
                                                        <input type="radio" id="customRadioInline191"
                                                            name="school_paging_res" class="custom-control-input"
                                                            value="50"
                                                            [checked]="(query_params?.school_paging == '50')">
                                                        <label class="custom-control-label"
                                                            for="customRadioInline191">50</label>
                                                    </div>
                                                    <div class="custom-control custom-radio dropdown-item">
                                                        <input type="radio" id="customRadioInline192"
                                                            name="school_paging_res" class="custom-control-input"
                                                            value="100"
                                                            [checked]="(query_params?.school_paging == '100')">
                                                        <label class="custom-control-label"
                                                            for="customRadioInline192">100</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            
            </div>
        </div>
        <div class="main_outer_right">
            <div class="container">
                <div class="school_info_div">
                    <div class="row" *ngIf="schools">
                        <div class="col-md-12">
                            <!-- <p class="count" *ngIf="schools.length >= 1;">{{schools.length }} Records Found</p> -->
                            <p class="count" *ngIf="(schools | filter: searchSchool).length >= 1;">
                                {{ (schools | filter: searchSchool).length }} schools Found</p>

                            <p class="count" *ngIf="(schools | filter: searchSchool).length < 1;">0 Record Found</p>
                        </div>
                        <!-- <div class="col-md-6 p-md-1 instruction_text" [style.display]="'none'">
                        Click on school name to download admission forms
                    </div> -->
                    </div>

                    <div *ngIf="loading" class="loading-wrapper"><img class="loding-icon" src="assets/img/loader.gif" />
                    </div>
                    <div class="school-list" class="search-results">
                        <!-- <div class="list-inner" *ngFor="let school of pageData | filter: searchSchool;  let i = index"> -->
                        <div class="list-inner shadow-sm"
                            *ngFor="let school of schools  | filter: searchSchool | slice: (currentPage-1) * itemsPerPage : currentPage * itemsPerPage; let i = index">
                            <div class="row">
                                <div class="col-md-3 col-lg-2 p-md-1">
                                    <div class="school-img" *ngIf="util.isNotEmpty(school.school_media)">
                                        <img src="{{ school.school_media.media }}" alt="logo" class="img-fluid"
                                            (click)="getSchoolInfo(school.id)">
                                    </div>

                                    <div class="school-img" *ngIf="!(util.isNotEmpty(school.school_media))">
                                        <img src="assets/img/dummy_logo.png" alt="logo" class="img-fluid"
                                            (click)="getSchoolInfo(school.id)">
                                    </div>
                                </div>
                                <div class="col-md-5 col-lg-10 school-text">
                                    <div>
                                    <h2><a class="show_school_info" *ngIf="util.isNotEmpty(school.website)" [href]="school.website"
                                        target="_blank">{{ school.school_name }}</a></h2>
                                        <p> {{ util.getFullAddress(school.school_address) }} </p>
                                        <div class="location-text">
                                            <img src="assets/img/galobe.png" alt="" /> 
                                            <a *ngIf="util.isNotEmpty(school.website)" [href]="school.website" target="_blank">{{ school.website }}</a>
                                        </div>
                                        <!-- <p *ngIf="util.isNotEmpty(school.school_address.city)"> 
                                        {{ util.ucfirst(school.school_address.city) }}</p> -->
                                    </div>
                                    <div class="school-right-main">
                                        <h4>School Advertise</h4>
                                        <div class="school-different-ctgry">
                                            <h6>Admission Status :
                                                <strong>{{ school.school_details.admission_status == '1' ? 'Open' : 'Close' }}</strong>
                                            </h6>
                                            <h6 *ngIf="util.isNotEmpty(school.school_details.admission_type)">
                                                Admission Type :
                                                <strong>{{ school.school_details.admission_type == 'fcfs' ? 'FCFS' : 'Lottery' }}</strong>
                                            </h6>
                                            <h6 *ngIf="util.isNotEmpty(school.form_price)">
                                                Price :
                                                <strong>&#8377; {{ school.form_price }}</strong>
                                            </h6>
                                            <h6 *ngIf="!util.isNotEmpty(school.form_price)">
                                                Price
                                                <strong>: &#8377; 0 </strong>
                                            </h6>
                                            <h6 *ngIf="util.isNotEmpty(school.school_details.max_class_number)">
                                                Max. Classes : <strong>
                                                    {{school.school_details.max_class_number}}</strong>
                                            </h6>
                                            <h6 *ngIf="!util.isNotEmpty(school.school_details.max_class_number)">
                                                Max. Classes : <strong>N/A</strong>
                                            </h6>
                                            <h6 *ngIf="util.isNotEmpty(school.school_details.open_date)">Open Date :
                                                <strong>{{ school.school_details.open_date | date }}</strong></h6>
                                            <div
                                                *ngIf="school.school_details.admission_type == 'lottery' ? true : false">
                                                <h6 *ngIf="util.isNotEmpty(school.school_details.close_date)">Close Date
                                                    :
                                                    <strong>{{ school.school_details.close_date | date }}</strong></h6>
                                                <h6 *ngIf="util.isNotEmpty(school.school_details.lottery_date)">Lottery
                                                    Date
                                                    : <strong>{{ school.school_details.lottery_date | date }}</strong>
                                                </h6>
                                                <h6 *ngIf="util.isNotEmpty(school.school_details.remarks)">Remarks :
                                                    <strong>{{ school.school_details.remarks }}</strong></h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="d-lg-flex align-items-center school-btn">
                                <div class="download-btns">
                                    <a class="show_school_info" [style.cursor]="'pointer'"
                                    (click)="getSchoolInfo(school.id)">More Info</a>
                                </div>
                                <div class="download-btn" *ngIf="util.isNotEmpty(school.school_form)">
                                    <button class="btn btn-dark border-outline"><a href="{{ school.school_form.media }}"
                                            data-type="Form" class="check_download_url" download>
                                            <img src="assets/img/download.png" alt="">Download Forms</a>
                                    </button>
                                </div>
                                <div class="download-btn" *ngIf="!(util.isNotEmpty(school.school_form))">
                                    <button class="btn btn-dark border-outline">
                                        <a href="" data-type="Form" class="check_download_url" download>
                                            <img src="assets/img/download.png" alt="">Download Forms</a>
                                    </button>
                                </div>
                                <div class="add-cart-btn"
                                    *ngIf="school.school_details.admission_status == '1' ? true : false">
                                    <button class="check_download_url" (click)="OffAddCart(school)"
                                        *ngIf="token == null">
                                        <img alt="form" src="assets/img/cart.png">
                                        Add to Cart
                                    </button>
                                    <button class="check_download_url"
                                        (click)="OnAddCart(school.id, school.school_name)" *ngIf="token != null">
                                        <img alt="form" src="assets/img/cart.png">
                                        Add to Cart
                                    </button>
                                </div>
                            </div>
                            <!--<school-info-component [messageEvent]="showVar"></school-info-component>-->
                            <div class="school_infoff">
                                <div class="school_{{ school.id }}" style="display:none"></div>
                            </div>
                        </div>
                        <!--<router-outlet></router-outlet>-->
                        <ngb-pagination [(page)]="currentPage" [pageSize]="itemsPerPage" [collectionSize]="totalItems"
                            (pageChange)="pageChanged()"></ngb-pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>