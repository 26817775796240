<section class="main common-form">
	<div class="top-logo text-center">
		<img src="assets/img/logo.png" width="200" alt="logo" class="img-fluid">
		<h3>{{schoolMainName}} Common Admission Form</h3>
	</div>
	<div class="container">
		<form [formGroup]="myForm" id="myForm" (ngSubmit)="submitForm()" enctype='multipart/form-data'>
            <div class="main-outer col-md-12 m-auto p-0" *ngFor="let detail of userDetails; let q = index">
				<div class="form-top">
                    <div class="form-heading">
                        <h2>Child</h2>
                    </div>
                    <div class="form-outer-top">
                        <h5>Basic Information</h5>
							<div class="form-outer mb-1">
								<div class="input-tab login">
									<div class="position-relative">
                                        <label for="fname">First Name *:</label>
										<input type="text" formControlName="fname" [ngModel]="detail.fname" id="fname" name="fname">
										<div *ngIf="(f['fname'].errors && !f['fname'].pristine) || (submitted && f['fname'].errors)" class="error-inp">
											<span *ngIf="(f['fname'].errors.required)" [hidden]="!f['fname'].errors.required">Please enter first name.</span>
										</div>
									</div>
								</div>
							<div class="input-tab login">
								<div class="position-relative">
                                    <label for="mname">Middle Name :</label>
									<input type="text" formControlName="mname" [ngModel]="detail.mname" id="mname" name="mname">
									<div *ngIf="(f['mname'].errors && !f['mname'].pristine) || (submitted && f['mname'].errors)" class="error-inp">
										<span *ngIf="(f['mname'].errors.required)" [hidden]="!f['mname'].errors.required">Please enter middle name.</span>
									</div>
								</div>
							</div>
                            <div class="input-tab login">
                                <div class="position-relative">
                                    <label for="lname">Last Name *:</label>
                                    <input type="text" formControlName="lname" [ngModel]="detail.lname" id="lname" name="lname">
									<div *ngIf="(f['lname'].errors && !f['lname'].pristine) || (submitted && f['lname'].errors)" class="error-inp">
										<span *ngIf="(f['lname'].errors.required)" [hidden]="!f['lname'].errors.required">Please enter last name.</span>
									</div>
                                </div>
                            </div>
                            <div class="input-tab login">
								<div class="position-relative">
                                    <label for="dob">Date of birth *:</label>
									<!-- <input type="text" formControlName="dob" [ngModel]="detail.dob" placeholder="Date of birth" id="dob" name="dob">
									<div *ngIf="(f['dob'].errors && !f['dob'].pristine) || (submitted && f['dob'].errors)" class="error-inp">
										<span *ngIf="(f['dob'].errors.required)" [hidden]="!f['dob'].errors.required">Please enter date of birth.</span>
                                    </div> -->
                                    <input id="dob" formControlName="dob" (click)="d.toggle()" placeholder="yyyy-mm-dd *" name="dob" [ngModel]="detail.dob" ngbDatepicker #d="ngbDatepicker" [minDate]="{year: 1980, month: 1, day: 1}" [maxDate]="{year: 2050, month: 1, day: 1}">
                                    <div *ngIf="(f['dob'].errors && !f['dob'].pristine) || (submitted && f['dob'].errors)" class="error-inp">
										<span *ngIf="(f['dob'].errors.required)" [hidden]="!f['dob'].errors.required">Please enter date of birth.</span>
                                    </div>
								</div>
                            </div>
                            <div class="input-tab login">
                                <div class="position-relative">
                                    <label for="classname">Class of which admission sought *:</label>
                                    <select id="classname" class="custom-select custom-select-lg mb-3"  name="classname" formControlName="classname">
                                        <option value="Pre">Pre-Nursery</option>
                                        <option value="Pre">Nursery</option>
                                        <option value="Pre">LKG</option>
                                        <option value="Pre">UKG</option>
                                        <option value="1st">1st</option>
                                        <option value="2nd">2nd</option>
                                        <option value="3rd">3rd</option>
                                        <option value="4th">4th</option>
                                        <option value="5th">5th</option>
                                        <option value="6th">6th</option>
                                        <option value="7th">7th</option>
                                        <option value="8th">8th</option>
                                        <option value="9th">9th</option>
                                        <option value="10th">10th</option>
                                        <option value="11th">11th</option>
                                        <option value="12th">12th</option>
                                    </select>
									<div *ngIf="(f['classname'].errors && !f['classname'].pristine) || (submitted && f['classname'].errors)" class="error-inp">
										<span *ngIf="(f['classname'].errors.required)" [hidden]="!f['classname'].errors.required">Please enter class.</span>
									</div>
                                </div>
                            </div>
							<div class="input-tab login">
								<!-- <div class="position-relative">
									<input type="text" formControlName="gender" [ngModel]="detail.gender" placeholder="Gender" id="gender" name="gender">
									<div *ngIf="(f['gender'].errors && !f['gender'].pristine) || (submitted && f['gender'].errors)" class="error-inp">
										<span *ngIf="(f['gender'].errors.required)" [hidden]="!f['gender'].errors.required">Please enter gender.</span>
									</div>
                                </div> -->
                                <div class="input-tab login">
                                    <label for="gender">Gender *:</label>
                                    <select id="gender" class="custom-select custom-select-lg mb-3"  name="gender" formControlName="gender" required>
                                        <!--<option selected value="">Whether police/defence personnel</option>-->
                                        <option value="Male">Male</option>
                                        <option value="Female">Female</option>
                                    </select>
                                    <div *ngIf="(f['gender'].errors && !f['gender'].pristine) || (submitted && f['gender'].errors)" class="error-inp">
										<span *ngIf="(f['gender'].errors.required)" [hidden]="!f['gender'].errors.required">Please enter gender.</span>
									</div>
                                </div>
                            </div>
                            <div class="input-tab login">
                                <div class="position-relative">
                                    <label for="cast">Caste *:</label>
                                    <select id="cast" class="custom-select custom-select-lg mb-3"  name="cast" formControlName="cast">
                                        <!--<option selected value="">Whether police/defence personnel</option>-->
                                        <option value="Gernal">General</option>
                                        <option value="SC">SC</option>
                                        <option value="ST">ST</option>
                                        <option value="OBC">OBC</option>
                                    </select>
                                    <!-- <input type="text" formControlName="cast" [ngModel]="detail.cast" placeholder="SC/ST/OBC" id="cast" name="cast"> -->
									<div *ngIf="(f['cast'].errors && !f['cast'].pristine) || (submitted && f['cast'].errors)" class="error-inp">
										<span *ngIf="(f['cast'].errors.required)" [hidden]="!f['cast'].errors.required">Please enter cast.</span>
									</div>
									<input type="hidden" id="school_id" name="school_id" value="{{schooluid}}" />
									<input type="hidden" id="order_id" name="order_id" value="{{schooloid}}" />
                                </div>
                            </div>
                            <div class="input-tab login">
								<div class="position-relative">
                                    <label for="nationality">Nationality :</label>
                                    <!-- <input type="text" formControlName="nationality" [ngModel]="detail.nationality" placeholder="Nationality" id="nationality" name="nationality"> -->
                                    <select class="custom-select custom-select-lg mb-3" formControlName="nationality" id="nationality" name="nationality" (click)="setValues($event.target.value)">
                                        <option *ngFor="let nation of nationData" value="{{ nation }}">
                                            {{nation}}
                                        </option>
                                    </select>
 									<div *ngIf="(f['nationality'].errors && !f['nationality'].pristine) || (submitted && f['nationality'].errors)" class="error-inp">
										<span *ngIf="(f['nationality'].errors.required)" [hidden]="!f['nationality'].errors.required">Please enter nationality.</span>
									</div>                                   
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="form-outer-top">
                        <h5>Details of previous school</h5>
						<div class="form-outer mb-1">
							<div class="input-tab login">
								<div class="position-relative">
                                    <label for="school_name">Name of school :</label>
									<input type="text" id="school_name" name="school_name" value="{{detail.school_name}}">
								</div>
							</div>
							<div class="input-tab login">
								<div class="position-relative">
                                    <label for="last_class_attended">Last class attended :</label>
                                    <select id="last_class_attended" class="custom-select custom-select-lg mb-3"  name="last_class_attended" formControlName="last_class_attended">
                                        <option value="Pre">Pre-Nursery</option>
                                        <option value="Pre">Nursery</option>
                                        <option value="Pre">LKG</option>
                                        <option value="Pre">UKG</option>
                                        <option value="1st">1st</option>
                                        <option value="2nd">2nd</option>
                                        <option value="3rd">3rd</option>
                                        <option value="4th">4th</option>
                                        <option value="5th">5th</option>
                                        <option value="6th">6th</option>
                                        <option value="7th">7th</option>
                                        <option value="8th">8th</option>
                                        <option value="9th">9th</option>
                                        <option value="10th">10th</option>
                                        <option value="11th">11th</option>
                                        <option value="12th">12th</option>
                                    </select>
								</div>
							</div>
							<div class="input-tab login">
								<div class="position-relative">
                                    <label for="address_of_school">Address of school :</label>
									<input type="text" id="address_of_school" name="address_of_school" value="{{detail.address_of_school}}">
								</div>
							</div>

						</div>
                    </div>

                    <div class="form-outer-top">
                        <h5>Medical Information</h5>
                        <div class="form-outer mb-1">
                            <div class="input-tab login">
                                <div class="position-relative">
                                    <label for="blood_group">Blood Group *:</label>
                                    <!-- <input type="text" placeholder="Blood Group" id="blood_group" name="blood_group" value="{{detail.blood_group}}"> -->
                                    <select class="custom-select custom-select-lg mb-3" id="blood_group" name="blood_group" value="{{detail.blood_group}}">
                                        <option value="A+">A+</option>
                                        <option value="A-">A-</option> 
                                        <option value="B+">B+</option> 
                                        <option value="B-">B-</option> 
                                        <option value="O+">O+</option>
                                        <option value="O-">O-</option>
                                        <option value="AB+">AB+</option>
                                        <option value="AB-">AB-</option>
                                    </select>
                                </div>
                            </div>
                          
                            <div class="input-tab login">
                                <div class="position-relative" style="display: flex; flex-direction: row; justify-content: space-between;">
                                    <input type="text" placeholder="Special need child" id="special_child" name="special_child" value="{{detail.special_child}}">
                                    <span>
                                        Special need child: 
                                    </span>
                                    <label class="special-text">
                                    <input id="special_child" name="special_child" type="checkbox" style="width: 10%;" value="{{detail.special_child}}" (click)="toggleValues('special')" [checked]="detail.special_child">
                                    <span></span>
                                    </label>
                                </div>
                                <div class="position-relative">
                                    <label for="details">If yes then give details :</label>
                                    <input type="text" id="details" name="details" value="{{detail.details}}" [disabled]="!detail.special_child">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

				<div class="form-top">
                    <div class="form-heading">
                        <h2>Father</h2>
                    </div>
                    <div class="form-outer-top">
                        <h5>Basic Information</h5>
                        <div class="form-outer mb-1">
                            <div class="input-tab login">
                                <div class="position-relative">
                                    <label for="father_name">Name:</label>
                                    <input type="text" id="father_name" name="father_name" value="{{detail.father_name}}">
                                </div>
                            </div>
                            <div class="input-tab login">
                                <div class="position-relative">
                                    <label for="nationality">Nationality *:</label>
                                    <!-- <input type="text" placeholder="Nationality" id="fnationality" name="fnationality" value="{{detail.fnationality}}"> -->
                                    <select class="custom-select custom-select-lg mb-3" id="fnationality" name="fnationality" formControlName="fnationality">
                                        <option *ngFor="let nation of nationData"  value="{{ nation }}">
                                            {{nation}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="input-tab login">
                                <div class="position-relative">
                                    <label for="highest_academic_qualification">Highest academic qualification :</label>
                                    <input type="text" id="highest_academic_qualification" name="highest_academic_qualification" value="{{detail.highest_academic_qualification}}">
                                </div>
                            </div>
                            <div class="input-tab login">
                                <div class="position-relative">
                                    <label for="phone_1">Phone 1 :</label>
                                    <input type="text" name="phone_1" id="phone_1" value="{{detail.phone_1}}">
                                </div>
                            </div>
                            <div class="input-tab login">
                                <div class="position-relative">
                                    <label for="phone_2">Phone 2 :</label>
                                    <input type="text" name="phone_2" id="phone_2" value="{{detail.phone_2}}">
                                </div>
                            </div>
                            <div class="input-tab login">
                                <div class="position-relative">
                                    <label for="office_phone">Office phone :</label>
                                    <input type="text" name="office_phone" id="office_phone" value="{{detail.office_phone}}">
                                </div>
                            </div>
                            <div class="input-tab login">
                                <div class="position-relative">
                                    <label for="occupation">Occupation :</label>
                                    <input type="text" id="occupation" name="occupation" value="{{detail.occupation}}">
                                </div>
                            </div>
                            <div class="input-tab login">
                                <div class="position-relative">
                                    <label for="address_of_occupation">Address of Occupation :</label>
                                    <input type="text" id="address_of_occupation" name="address_of_occupation" value="{{detail.address_of_occupation}}">
                                </div>
                            </div>
                            <div class="input-tab login">
                                <div class="position-relative">
                                    <label for="email_id">Email id :</label>
                                    <input type="text" id="email_id" name="email_id" value="{{detail.email_id}}">
                                </div>
                            </div>
						</div>
					</div>
				</div>

                <div class="form-top">
                    <div class="form-heading">
                        <h2>Mother</h2>
                    </div>
                    <div class="form-outer-top">
                        <h5>Basic Information</h5>
						<div class="form-outer mb-1">
							<div class="input-tab login">
								<div class="position-relative">
                                    <label for="mother_name">Name :</label>
									<input type="text" id="mother_name" name="mother_name" value="{{detail.mother_name}}">
								</div>
							</div>
							<div class="input-tab login">
                                <div class="position-relative">
                                    <label for="mnationality">Nationality *:</label>
                                    <!-- <input type="text" placeholder="Nationality" id="mnationality" name="mnationality" value="{{detail.mnationality}}"> -->
                                    <select class="custom-select custom-select-lg mb-3" id="mnationality" name="mnationality" formControlName="mnationality">
                                        <option *ngFor="let nation of nationData"  value="{{ nation }}">
                                            {{nation}}
                                        </option>
                                    </select>                                    
                                </div>
                            </div>
                            <div class="input-tab login">
                                <div class="position-relative">
                                    <label for="mhighest_academic_qualification">Highest academic qualification :</label>
                                    <input type="text" id="mhighest_academic_qualification" name="mhighest_academic_qualification" value="{{detail.mhighest_academic_qualification}}">
                                </div>
                            </div>
                            <div class="input-tab login">
                                <div class="position-relative">
                                    <label for="mphone_1">Phone 1 :</label>
									<input type="text" id="mphone_1" name="mphone_1" value="{{detail.mphone_1}}">
                                </div>
                            </div>
                            <div class="input-tab login">
                                <div class="position-relative">
                                    <label for="mphone_2">Phone 2 :</label>
                                    <input type="text" id="mphone_2" name="mphone_2" value="{{detail.mphone_2}}">
                                </div>
                            </div>
                            <div class="input-tab login">
                                <div class="position-relative">
                                    <label for="moffice_phone">Office phone :</label>
                                    <input type="text" id="moffice_phone" name="moffice_phone" value="{{detail.moffice_phone}}">
                                </div>
                            </div>
                            <div class="input-tab login">
                                <div class="position-relative">
                                    <label for="moccupation">Occupation :</label>
									<input type="text" id="moccupation" name="moccupation" value="{{detail.moccupation}}">
                                </div>
                            </div>
                            <div class="input-tab login">
                                <div class="position-relative">
                                    <label for="maddress_of_occupation">Address of Occupation :</label>
                                    <input type="text" id="maddress_of_occupation" name="maddress_of_occupation" value="{{detail.maddress_of_occupation}}">
                                </div>
                            </div>
                            <div class="input-tab login">
                                <div class="position-relative">
                                    <label for="memail_id">Email id :</label>
                                    <input type="text" id="memail_id" name="memail_id" value="{{detail.memail_id}}">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="form-top">
                    <div class="form-heading">
                        <h2>Address</h2>
                    </div>
                    <div class="form-outer-top">
                        <h5>Present Address</h5>
                        <div class="form-outer mb-1">
                            <div class="input-tab login">
                                <div class="position-relative">
                                    <label for="present_address">Address :</label>
                                    <input type="text" required id="present_address" name="present_address" value="{{detail.present_address}}">
                                </div>
                            </div>
                            <div class="input-tab login">
                                <div class="position-relative">
                                    <label for="city">City :</label>
                                    <input type="text" required id="city" name="city" value="{{detail.city}}">
                                </div>
                            </div>
                            <div class="input-tab login">
                                <div class="position-relative">
                                    <label for="state">State :</label>
                                    <input type="text" required id="state" name="state" value="{{detail.state}}">
                                </div>
                            </div>
                            <div class="input-tab login">
								<div class="position-relative">
                                    <label for="pin_code">Pin code :</label>
									<input type="text" placeholder="" required id="pin_code" name="pin_code" value="{{detail.pin_code}}">
								</div>
							</div>
                        </div>
                    </div>
                    <div class="form-outer-top" style="width: 50%; display: flex; flex-direction: row; justify-content: space-between;">
                        <span>
                            Present Address same as Permanent Address: 
                        </span>
                        <label class="special-text">
                            <input type="checkbox" style="width: 10%;" (click)="toggleValues('address')" [(ngModel)]="checkAddress" [ngModelOptions]="{standalone: true}">
                            <span></span>
                        </label>
                    </div>
                    <div class="form-outer-top">
						<h5>Permanent Address</h5>
                        <div class="form-outer mb-1">
                            <div class="input-tab login">
                                <div class="position-relative">
                                    <label for="permanent_address">Address :</label>
                                    <input type="text" formControlName="permanent_address" [ngModel]="detail.permanent_address" required id="permanent_address" name="permanent_address" value="{{detail.permanent_address}}" [readonly]="checkAddress">
                                    <div *ngIf="(f['permanent_address'].errors && !f['permanent_address'].pristine) || (submitted && f['permanent_address'].errors)" class="error-inp">
										<span *ngIf="(f['permanent_address'].errors.required)" [hidden]="!f['permanent_address'].errors.required">Please enter permanent address.</span>
									</div> 
                                </div>
	                        </div>
                            <div class="input-tab login">
                                <div class="position-relative">
                                    <label for="p_city">City :</label>
                                    <input type="text" formControlName="p_city" [ngModel]="detail.p_city" required id="p_city" name="p_city" value="{{detail.p_city}}" [readonly]="checkAddress">
                                    <div *ngIf="(f['p_city'].errors && !f['p_city'].pristine) || (submitted && f['p_city'].errors)" class="error-inp">
										<span *ngIf="(f['p_city'].errors.required)" [hidden]="!f['p_city'].errors.required">Please enter city.</span>
									</div>
                                </div>
                            </div>
                            <div class="input-tab login">
                                <div class="position-relative">
                                    <label for="p_state">State :</label>
                                    <input type="text" formControlName="p_state" [ngModel]="detail.p_state" required id="p_state" name="p_state" value="{{detail.p_state}}" [readonly]="checkAddress">
                                    <div *ngIf="(f['p_state'].errors && !f['p_state'].pristine) || (submitted && f['p_state'].errors)" class="error-inp">
										<span *ngIf="(f['p_state'].errors.required)" [hidden]="!f['p_state'].errors.required">Please enter state.</span>
									</div> 
                                </div>
                            </div>
                            <div class="input-tab login">
								<div class="position-relative">
                                    <label for="p_pin_code">Pin code :</label>
                                    <input type="text" formControlName="p_pin_code" [ngModel]="detail.p_pin_code" required id="p_pin_code" name="p_pin_code" value="{{detail.p_pin_code}}" [readonly]="checkAddress">
                                    <div *ngIf="(f['p_pin_code'].errors && !f['p_pin_code'].pristine) || (submitted && f['p_pin_code'].errors)" class="error-inp">
										<span *ngIf="(f['p_pin_code'].errors.required)" [hidden]="!f['p_pin_code'].errors.required">Please enter pin code.</span>
									</div>	
	                            </div>
							</div>
                        </div>
					</div>
                </div>

                <div class="form-top">
                    <div class="form-heading">
                        <h2>General</h2>
                    </div>
                    <div class="form-outer-top">
                        <h5>Guardian</h5>
                        <div class="form-outer mb-1">
                            <div class="input-tab login">
                                <div class="position-relative">
                                    <label for="gname">Name :</label>
                                    <input type="text" name="gname" id="gname" value="{{detail.gname}}">
                                </div>
                            </div>
                            <div class="input-tab login">
                                <div class="position-relative">
                                    <label for="relationship">Relationship :</label>
                                    <input type="text" id="relationship" name="relationship" value="{{detail.relationship}}">
                                </div>
                            </div>
                            <div class="input-tab login">
                                <div class="position-relative">
                                    <label for="phone">Phone :</label>
                                    <input type="text" id="phone" name="phone" value="{{detail.phone}}">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-outer-top">
                        <h5>Details of single parent if applicable</h5>
                            <div class="">
                                <div class="position-relative">
                                    <label for="parent_status">Parent Status :</label>
                                      <label class="radio-inline" *ngFor="let parent_statu of parent_statuses;let i = index">
                                        <input type="radio" name="parent_status" value="{{ parent_statu }}" data-id="{{ index }}" [checked]="detail.parent_status ? (detail.parent_status == parent_statu ? true : false) : 'Married'" value="{{ parent_statu }}">{{ parent_statu }}
                                      </label>
                                </div>
                            </div>
                            <div class="input-tab login hidden">
                                <div class="position-relative">
                                    <label for="windowname">Widow :</label>
                                    <input type="text" id="windowname" name="windowname" >
                                </div>
                            </div>
                            <div class="input-tab login hidden">
                                <div class="position-relative">
                                    <label for="windowername">Widower :</label>
                                    <input type="text" id="windowername" name="windowername" >
                                </div>
                            </div>
                            <div class="input-tab login hidden">
                                <div class="position-relative">
                                    <label for="whether_police_defence_personnel">Whether police/defence personnel :</label>
                                    <!-- <select id="whether_police_defence_personnel" class="custom-select custom-select-lg mb-3"  name="whether_police_defence_personnel" required>
                                        <option>One</option>
                                        <option>Two</option>
                                        <option>Three</option>
                                    </select> -->
                                    <label class="radio-inline">
                                        <input type="radio" name="whether_police_defence_personnel" value="Yes">Yes
                                        <input type="radio" name="whether_police_defence_personnel" value="No">No
                                    </label>
                                </div>
                        </div>
                    </div>
                    <div class="form-outer-top">
						<h5>Sibling study in school ?</h5>
                        <div class="form-outer mb-1">
                            <div class="input-tab login">
                                <div class="position-relative">
                                    <label for="sname">Sibling Name :</label>
                                    <input type="text" id="sname" name="sname" value="{{detail.sname}}">
                                </div>
                            </div>
                            <div class="input-tab login">
                                <div class="position-relative">
                                    <label for="sclass">Class:</label>
                                    <input type="text" id="sclass" name="sclass" value="{{detail.sclass}}">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-outer-top">
                        <h5>Area of interest where parents could enrich the school</h5>
                        <div class="form-outer mb-1">
                            <div class="input-tab login">
								<div class="position-relative">
                                    <label for="hobbies">Hobbies :</label>
									<select id="hobbies" class="custom-select custom-select-lg mb-3" name="hobbies" >
										<option selected value="">Select Hobbies</option>
										<option *ngFor="let hobi of hobbiesdata">{{hobi}}</option>
									</select>
                                </div>
                            </div>
						</div>
                    </div>
                </div>
 
                <div class="form-outer-top">
                     <div class="form-group">
                        <label for="information">Remarks:</label>
                         <textarea class="form-control" rows="3" id="information" name="information" >{{detail.information}}</textarea>
                     </div>
                </div>

				<div class="form-heading">
					<h2>Documents Upload </h2>
				</div>
				<h4 style="color:red;margin-left:1em;">Allow file type (PDF OR JPG)</h4>
				<div class="form-row" style="padding:1em;">
					<div class="form-group col-md-6">
						<label for="file-input">Date of birth certificate of child *<img src="assets/img/up-arrow.png" class="ml-2"/></label>
                        <input formControlName="dobcertificate" id="files1" type="file"  class="form-control" (change)="onFileChange($event,'dobcertificate')">
                        DOB certificate : {{dobcertificate}}
						<p *ngIf="detail.date_of_birth_certificate_of_child" class="pl-4 align-middle mb-0">{{detail.date_of_birth_certificate_of_child}}</p>
					</div>
					<div class="form-group col-md-6">
						<label for="file-input"> Voter ID <img src="assets/img/up-arrow.png" class="ml-2"/></label>
						<input id="files2"  type="file"  class="form-control" (change)="onFileChange($event,'votercard')">
					</div>
				</div>
				<div class="form-row" style="padding:1em;">
					<div class="form-group col-md-6">
						<label for="file-input">Aadhar Card <img src="assets/img/up-arrow.png" class="ml-2"/></label>
						<input id="files3"  type="file"  class="form-control" (change)="onFileChange($event,'aadharcard')">
					</div>
					<div class="form-group col-md-6">
						<label for="file-input">Current Electricity/Telephone/Water Bill<img src="assets/img/up-arrow.png" class="ml-2"/></label>
						<input id="files4"  type="file"  class="form-control" (change)="onFileChange($event,'anybill')">
					</div>
				</div>
				<div class="form-row" style="padding:1em;">
					<div class="form-group col-md-6">
						<label for="file-input">School leaving certificate (if applicable) <img src="assets/img/up-arrow.png" class="ml-2"/></label>
						<input id="files5"  type="file"  class="form-control" (change)="onFileChange($event,'tc')">
					</div>
				</div>
				<div class="total-count d-flex justify-content-center text-center my-5">
					<button type="submit" class="btn btn-dark">Submit to School</button>
				</div>
			</div>
		</form>
	</div>
</section>
<div><ngx-spinner type="ball-clip-rotate-multiple" size="medium"></ngx-spinner></div>
