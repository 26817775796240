import { Component, OnInit, AfterViewChecked } from '@angular/core';
import { SelectedSchool } from '../Models/Product.Model';
import { SearchService } from '../search.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Globals } from '../globals';
import { SessionStorageService } from 'ngx-webstorage';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { environment } from '../../environments/environment';
import { AbstractControl, ValidatorFn, FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { isObject } from 'rxjs/internal-compatibility';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
	selector: 'app-checkout',
	templateUrl: './checkout.component.html',
	styleUrls: ['./checkout.component.css']
})
export class CheckoutComponent implements OnInit {

	allTotal: number;
	totalamount: number;
	schoolAddedTocart;
	payuform;
	cartcount: number = 0;
	total_cart_orders: number = 0;
	urlLink: any;
	txnId: any;
	amount: any;
	productinfo: any;
	firstName: any;
	email: any;
	phone: any;
	surl: any;
	furl: any;
	hash: any;
	payUMoney: any;
	key: any;
	serviceProviderValue: any;
	serviceProviderSize: any;
	
	public checkoutForm: FormGroup;
	public submitted: boolean = false;
	
	constructor(private ss: SearchService, private globals: Globals, private session: SessionStorageService, private httpClient: HttpClient, private spinner: NgxSpinnerService, private router: Router) {
		
		this.checkoutForm = new FormGroup({
			firstName: new FormControl('', [Validators.required, Validators.pattern("^[A-Za-z]+$"), Validators.minLength(3), Validators.maxLength(15)]),
			lastName: new FormControl('', [Validators.required, Validators.pattern("^[A-Za-z]+$"), Validators.minLength(3), Validators.maxLength(15)]),
			email: new FormControl('', [Validators.required,Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")] ),
			mobile: new FormControl('', [Validators.required,Validators.pattern("^[6-9]{1}[0-9]{9}$")]),
			address: new FormControl('', [Validators.required, Validators.minLength(5), Validators.maxLength(190)]),
			city: new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(45)]),			
			zip: new FormControl('', [Validators.required,Validators.pattern("^[1-9]{1}[0-9]{5}$")]),
			totalamount: new FormControl(''),
		});
	
	}
	
	get f() { return this.checkoutForm.controls; }
	
	ngOnInit(): void {
		this.payUMoney = false;
		var access_token = this.session.retrieve('access_token');
		if (!access_token) {
			this.router.navigate(['dashboard']);
		}
		// var uri = 'https://demo.softprodigyphp.in/Admitmykid/backend/public/api/';
		var uri = environment.baseurl;
		let headers = new HttpHeaders();
		headers.append('Content-Type', 'application/json');
		headers.append('authorization', 'Bearer ' + access_token);
		var body = '';
		this.httpClient.post(uri + "getCartDetails", body, { headers })
			.subscribe(
				response => {
					// console.log("Response", response);
					if ((response as any).length == 0) {
						this.router.navigate(['dashboard']);
					}
					if ((response as any).school_details.length > 0) {
						let total = 0;
						for (var i = 0; i < (response as any).school_details.length; i++) {
							if ((response as any).school_details[i].form_price != null) {
								total += (response as any).school_details[i].form_price;
								this.totalamount = total;
							}else{
								this.totalamount = 0;
							}
						}
					} else {
						this.totalamount = 0;
					}
					this.checkoutForm.controls.email.setValue((response as any).email);
					this.checkoutForm.controls.mobile.setValue((response as any).contact_number);
					this.checkoutForm.controls.firstName.setValue((response as any).full_name);
					this.schoolAddedTocart = (response as any).school_details
					this.total_cart_orders = (response as any).total_cart_orders
				},
				error => {
					console.log("Error", error);
				}
			);
	}

	// ngAfterViewChecked() {
	payment_form_submit() {
		let val = document.getElementById('payment_form_submit')
		if (val != undefined || val != null) {
			val.click();
		}
		// document.f1.submit()
	}

	async submitForm() {

		this.submitted = true;
		if (this.checkoutForm.valid) {
			try {
				this.spinner.show();
				// debugger;			
				const fname = this.checkoutForm.value.firstName;
				const lname = this.checkoutForm.value.lastName;
				const email = this.checkoutForm.value.email;
				const mobile = this.checkoutForm.value.mobile;
				const address = this.checkoutForm.value.address;
				const city = this.checkoutForm.value.city;
				const zip = this.checkoutForm.value.zip;
				
				const totalElement: HTMLInputElement = document.getElementById('totalamount') as HTMLInputElement
				const total: string = totalElement.value;

				// var uri = 'https://demo.softprodigyphp.in/Admitmykid/backend/public/api/';
				var uri = environment.baseurl;
				var access_token = this.session.retrieve('access_token');
				let headers = new HttpHeaders();
				headers.append('Content-Type', 'application/text');
				headers.append('authorization', 'Bearer ' + access_token);
				await this.httpClient.get(uri + "orders?total=" + total + "&first_name=" + fname + "&last_name=" + lname + "&address=" + address + "&city=" + city + "&zipcode=" + zip + "&contact_number=" + mobile + "&email=" + email, { headers, 'responseType': 'text' })
					.subscribe(
						response => {
							this.spinner.hide();
							// console.log(response as any);
							var data = (response as any);
							try{
								var dsms = (JSON.parse(data)).message;
								// console.log(dsms);
								if(typeof dsms !== 'undefined' && dsms == "success"){
									this.router.navigate(['thankyou'])
								}
							}catch(err){
								this.payuform = (response as any);
								document.getElementById('someStuff').innerHTML = (response as any);
								document.getElementById('container').style.display = "none"
								this.payUMoney = "true"
								this.payment_form_submit();
							}
						},
						error => {
							this.spinner.hide();
							this.payuform = (error as any);
						}
					);
				// Order post
			
			} catch (err) {
			console.log(333333);
			console.log(err);
				// this.payuform = (error as any);
			}			
		
		}
	}
	
}
