<section class="main dashboard-top-main">
	 
	<div class="container">
		<h4 class="dash-head">Welcome to Dashboard</h4>
		<div class="main-outer col-md-12 m-auto">
			<div class="noData">
				<div class="row" *ngIf="cartData?.total_cart_orders > 0">
					<div class="col-md-12 p-md-1">
						<div class="cart-school-img ">
							<h4 style="text-align:center;" *ngIf="cartData?.total_cart_orders === 1">{{cartData?.total_cart_orders}} Order is added to the Cart</h4>
							<h4 style="text-align:center;" *ngIf="cartData?.total_cart_orders > 1">{{cartData?.total_cart_orders}} Orders are added to the Cart</h4>
						</div>
					</div>
				</div>
			</div>
			<div class="school-list cart table-responsive">
				<div class="dashboard-table table-responsive" *ngIf="dashboardlist?.length > 0">
					<table class="table">
						<thead>
							<tr>
								<th>School Details</th>
								<th style="width: 160px;">Student Name</th>
								<th>CAF Status</th>
								<th>Payment Status</th>
								<th>School Ack.I</th> 
							</tr>
						</thead>
						<tbody>
							<tr class="dashboard-list" *ngFor="let school of dashboardlist; let q = index">
								<td>
									<div class="school-list cart">
										<div class="list-inner">
											<div class="row">
												<div class="col-4 p-md-1">
													<div class="cart-school-img">
														<img src="assets/img/logo-3.gif" alt="logo" class="img-fluid">
													</div>
												</div> 
												<div class="col-8 school-text p-0">
													<h2>{{school.school_name}}</h2>
													<p>{{school.postal_address}}, {{school.pin_code}}</p>
													<div class="location-text">
														<p><img src="assets/img/galobe.png" alt="" /><a href="{{school.website}}" target="_blank"> {{school.website}}</a></p>
													</div>
													<p>{{school.city}}</p>
												</div>
											</div>
										</div>
									</div>
								</td>
								<td><div *ngIf="school.ack_id; else NA">{{ school.fname + ' ' + (school.mname ? school.mname : '')  + ' ' + school.lname}}</div></td>
								<td><div>
									<h2 class="mb-2">
										<span *ngIf="school.ack_id" class="badge badge-info">{{school.status | titlecase }}</span>
										<span *ngIf="!school.ack_id" class="badge badge-warning">Pending</span>
									</h2>
								</div>
								<span>
									<h2 *ngIf="school.ack_id"><a [routerLink]="['/admission-form-view', school.schoolId , school.id]">View Common Admission Form</a></h2>

									<h2 *ngIf='!school.ack_id && dashboardlist.length == 1'><a [routerLink]="['/admission-form-add', school.schoolId , school.id]">Please Fill Common Admission Form</a></h2>

									<h2 *ngIf='!school.ack_id && dashboardlist.length > 1'><a [routerLink]="['/admission-form-add', school.schoolId , school.id]">Please Edit Common Admission Form</a></h2>

								</span></td>
								<td class="order-number">
									<h2 *ngIf="school.order_number" >
										<p class="mb-2">Payment Successful.</p>
										<p class="mb-2">Payment ID: {{school.order_number}}</p>
									</h2>
								</td>
								<td>
									<h2 *ngIf="!school.ack_id" class="badge badge-warning">Pending</h2>
									<h2 *ngIf="school.ack_id == 'pending'">Your Application is under review</h2>
									<h2 *ngIf="school.ack_id != 'pending' && school.ack_id != null">{{school.ack_id}}</h2>
								</td> 
							</tr>
						</tbody>
					</table>
					 
				 
				</div>
				<div class="noData">
					<div class="row" *ngIf="cartData?.total_cart_orders === 0 && dashboardlist?.length === 0">
						<div class="col-md-12 p-md-1">
							<div class="cart-school-img ">
								<h4 style="text-align:center;">Your dashboard is empty. Please select schools after clicking 
									<a class="prime-color" [routerLink]="['/schools']" routerLinkActive="router-link-active" >‘search’</a></h4>
							</div>
						</div>
					</div>
				</div>
				<ng-template #NA>N/A</ng-template>
			</div>
		</div>
	</div>
</section>
