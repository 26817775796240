<div class="top_navigation" *ngIf="!auth.loggedIn()">
	<a href=""><img width="200" src="assets/img/logo.png" alt="logo" class="img-fluid"></a>
	<div class="right_nav">
		<!-- <div>
			<a routerLink="/" routerLinkActive="active-link" [routerLinkActiveOptions]="{ exact: true }"><svg width="24"
				height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path fill-rule="evenodd" clip-rule="evenodd"
					d="M18.319 14.4326C20.7628 11.2941 20.542 6.75347 17.6569 3.86829C14.5327 0.744098 9.46734 0.744098 6.34315 3.86829C3.21895 6.99249 3.21895 12.0578 6.34315 15.182C9.22833 18.0672 13.769 18.2879 16.9075 15.8442C16.921 15.8595 16.9351 15.8745 16.9497 15.8891L21.1924 20.1317C21.5829 20.5223 22.2161 20.5223 22.6066 20.1317C22.9971 19.7412 22.9971 19.1081 22.6066 18.7175L18.364 14.4749C18.3493 14.4603 18.3343 14.4462 18.319 14.4326ZM16.2426 5.28251C18.5858 7.62565 18.5858 11.4246 16.2426 13.7678C13.8995 16.1109 10.1005 16.1109 7.75736 13.7678C5.41421 11.4246 5.41421 7.62565 7.75736 5.28251C10.1005 2.93936 13.8995 2.93936 16.2426 5.28251Z"
					fill="currentColor" />
			</svg>
			<span> Search</span></a>
		</div> -->
		<div>
			<a routerLink="/cart" routerLinkActive="active-link" [routerLinkActiveOptions]="{ exact: true }"
				title="Cart">
				<span class="notification-icon">
					<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path fill-rule="evenodd" clip-rule="evenodd"
							d="M5.79166 2H1V4H4.2184L6.9872 16.6776H7V17H20V16.7519L22.1932 7.09095L22.5308 6H6.6552L6.08485 3.38852L5.79166 2ZM19.9869 8H7.092L8.62081 15H18.3978L19.9869 8Z"
							fill="currentColor" />
						<path
							d="M10 22C11.1046 22 12 21.1046 12 20C12 18.8954 11.1046 18 10 18C8.89543 18 8 18.8954 8 20C8 21.1046 8.89543 22 10 22Z"
							fill="currentColor" />
						<path
							d="M19 20C19 21.1046 18.1046 22 17 22C15.8954 22 15 21.1046 15 20C15 18.8954 15.8954 18 17 18C18.1046 18 19 18.8954 19 20Z"
							fill="currentColor" />
					</svg>
					<span class="number-text" *ngIf="globals.cartcount != 0">
						{{ globals.cartcount }}</span>
					Cart
				</span>
			</a>
		</div>
		<div class="ml-2">
			<a class="btn" routerLink="/login" routerLinkActive="active-link"
				[routerLinkActiveOptions]="{ exact: true }">
				<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path fill-rule="evenodd" clip-rule="evenodd"
						d="M16 7C16 9.20914 14.2091 11 12 11C9.79086 11 8 9.20914 8 7C8 4.79086 9.79086 3 12 3C14.2091 3 16 4.79086 16 7ZM14 7C14 8.10457 13.1046 9 12 9C10.8954 9 10 8.10457 10 7C10 5.89543 10.8954 5 12 5C13.1046 5 14 5.89543 14 7Z"
						fill="currentColor" />
					<path
						d="M16 15C16 14.4477 15.5523 14 15 14H9C8.44772 14 8 14.4477 8 15V21H6V15C6 13.3431 7.34315 12 9 12H15C16.6569 12 18 13.3431 18 15V21H16V15Z"
						fill="currentColor" /></svg>
				Sign in
			</a>
		</div>
		<div class="ml-2">
			<a href="{{portaluri}}" target="_blank" rel="noopener">
				Partner Login
			  </a>
		</div>
	</div>
</div>
<div class="top_navigation justify-content-between" *ngIf="auth.loggedIn()">
	<a href=""><img width="200" src="assets/img/logo.png" alt="logo" class="img-fluid"></a>
	<div class="d-flex right_nav">
		<div>
			<a routerLink="/cart" routerLinkActive="active-link" [routerLinkActiveOptions]="{ exact: true }"
				title="Cart" click="(testing)">
				<span class="notification-icon">
					<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path fill-rule="evenodd" clip-rule="evenodd"
							d="M5.79166 2H1V4H4.2184L6.9872 16.6776H7V17H20V16.7519L22.1932 7.09095L22.5308 6H6.6552L6.08485 3.38852L5.79166 2ZM19.9869 8H7.092L8.62081 15H18.3978L19.9869 8Z"
							fill="currentColor" />
						<path
							d="M10 22C11.1046 22 12 21.1046 12 20C12 18.8954 11.1046 18 10 18C8.89543 18 8 18.8954 8 20C8 21.1046 8.89543 22 10 22Z"
							fill="currentColor" />
						<path
							d="M19 20C19 21.1046 18.1046 22 17 22C15.8954 22 15 21.1046 15 20C15 18.8954 15.8954 18 17 18C18.1046 18 19 18.8954 19 20Z"
							fill="currentColor" />
					</svg>
					<span class="number-text"> {{ globals.cartcount }}</span> Cart
				</span>
			</a>
		</div>
		<div>
			<a routerLink="/" routerLinkActive="active-link" [routerLinkActiveOptions]="{ exact: true }"><svg width="24"
					height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path fill-rule="evenodd" clip-rule="evenodd"
						d="M18.319 14.4326C20.7628 11.2941 20.542 6.75347 17.6569 3.86829C14.5327 0.744098 9.46734 0.744098 6.34315 3.86829C3.21895 6.99249 3.21895 12.0578 6.34315 15.182C9.22833 18.0672 13.769 18.2879 16.9075 15.8442C16.921 15.8595 16.9351 15.8745 16.9497 15.8891L21.1924 20.1317C21.5829 20.5223 22.2161 20.5223 22.6066 20.1317C22.9971 19.7412 22.9971 19.1081 22.6066 18.7175L18.364 14.4749C18.3493 14.4603 18.3343 14.4462 18.319 14.4326ZM16.2426 5.28251C18.5858 7.62565 18.5858 11.4246 16.2426 13.7678C13.8995 16.1109 10.1005 16.1109 7.75736 13.7678C5.41421 11.4246 5.41421 7.62565 7.75736 5.28251C10.1005 2.93936 13.8995 2.93936 16.2426 5.28251Z"
						fill="currentColor" />
				</svg>
				<span> Search</span></a>
		</div>
		<div class="ml-2">
			<a routerLink="/dashboard" routerLinkActive="active-link" [routerLinkActiveOptions]="{ exact: true }">
				<span> Dashboard</span></a>
		</div>

		<div class="notification-main notification-username">
			<span class="notification-icon">Hi, {{ globals.username }}</span>
			<a href="javascript:;" (click)="auth.logoutUser()" style="    background: none !important;
			color: #000;">

				Logout
			</a>
		</div>
	</div>
</div>
<script>
	$(window).scroll(function () {
		var scroll = $(window).scrollTop();

		if (scroll >= 100) {
			$(".top-navr").addClass("darkHeader");
		} else {
			$(".top-nav").removeClass("darkHeader");
		}
	});
</script>