<div class="tab-panel-wrapper ">
    <div class="school-rating">
        <div class="row">
            <div class="col-md-5 col-lg-4">
                <div class="abt-img">
                    <img src="assets/img/school-1.jpg" alt="logo" class="img-fluid">
                </div>
                <div class="img-loaction">
                    <a href="#"><img src="assets/img/link.png" alt="">
                        <span>www.stephenschandigarh.com</span></a>
                    <a href="#"><img src="assets/img/abt-call.png" alt=""> <span>0172
                            2222222</span></a>
                </div>
                <div class="download-btn">
                    <button class="btn btn-dark"><img src="assets/img/download.png"
                                                      alt="">Download
                        Brochure</button>
                </div>
            </div>
            <div class="col-md-7 col-lg-8">
                <div class="text-left">
                    <div class="about-heading">ST.STEPHEN’S SCHOOL</div>
                    <div class="about-text">Lorem ipsum dolor sit amet, te vel minim
                        putent
                        suscipiantur. Qui eu cetero persequeris conclusion emque, quando
                        deleniti electram in his. Vel audia mreprehendunt ea. Error
                        errem
                        facilisi ex vis, cuveri malis eum, nulla laudem postea sed cu.
                        Eam
                        omnis legere sententiae ut. Lorem ipsum dolor sit amet, te vel
                        minim
                        putent suscipiantur. Qui eu cetero persequeris conclusion emque,
                        quando deleniti electram in his. </div>
                </div>
            </div>
        </div>
    </div>
    <div class="school-rating">
        <h3>bus route</h3>
        <div class="map-section">
            <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13725.468374154318!2d76.72217599999999!3d30.679945999999997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m3!3e6!4m0!4m0!5e0!3m2!1sen!2sin!4v1565080838622!5m2!1sen!2sin"
                width="" height="274" frameborder="0" style="border:0"
                allowfullscreen></iframe>
        </div>
    </div>
</div>