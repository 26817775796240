import { Injectable, Injector } from '@angular/core';
import { HttpInterceptor } from '@angular/common/http';
import { AuthService } from '../services/auth/auth.service'

@Injectable({
  providedIn: 'root',
})
export class TokenInterceptorService implements HttpInterceptor {

  constructor(private inject: Injector) { }
  intercept(req, next) {
    let auth = this.inject.get(AuthService)
    let tokenReq = req.clone({
      setHeaders: {
        Authorization: `Bearer ${auth.getToken()}`
      }
    })
    return next.handle(tokenReq)
  }
}
