<div class="tab-panel-wrapper ">
    <div class="image-grid">
        <div class="school-type-images">
            <img src="assets/img/school-1.jpg" alt="logo" class="img-fluid">
        </div>
        <div class="school-type-images">
            <img src="assets/img/school-1.jpg" alt="logo" class="img-fluid">
        </div>
        <div class="school-type-images">
            <img src="assets/img/school-1.jpg" alt="logo" class="img-fluid">
        </div>
        <div class="school-type-images">
            <img src="assets/img/school-1.jpg" alt="logo" class="img-fluid">
        </div>
        <div class="school-type-images">
            <img src="assets/img/school-1.jpg" alt="logo" class="img-fluid">
        </div>

        <div class="school-type-images">
            <img src="assets/img/school-1.jpg" alt="logo" class="img-fluid">
        </div>
        <div class="school-type-images">
            <img src="assets/img/school-1.jpg" alt="logo" class="img-fluid">
        </div>
    </div>

</div>