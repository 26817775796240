<section class="main">
    
    <div class="container container_list_cart">
        <div class="main-outer col-md-12 ">
            <div class="school-list cart">
                <div class="list-inner" *ngFor="let school of schoolAddedTocart; let q = index">
                    <div class="row" *ngIf="schoolAddedTocart!='null'">
                        <div class="table-responsive">
                            <table class="table table-bordered m-0">
                                <tr>
                                    <td style="text-align: center; width: 17%;"> <img src="assets/img/logo-1.gif" alt="logo" class="img-fluid"></td>
                                    <td style="width: 65%;"> 
                                        <div>
                                            <h2>{{school.school_name}}</h2>
                                            <a href="{{school.website}}" target="_blank"> {{school.website}}</a>
                                        </div>
                                    </td>
                                    <td>
                                        <h4 *ngIf="school.form_price != null"><i class="fa fa-inr" aria-hidden="true"></i>₹{{school.form_price}} </h4>
                                        <h4 *ngIf="school.form_price == null"><i class="fa fa-inr" aria-hidden="true"></i>₹0 </h4>
                                    </td>
                                    <td>
                                        <div class="school-different-ctgry">
                                            <a href="javascript:;" (click)="OnRemoveCart(school,school.id)" title="Remove"
                                                *ngIf="tokenacc != null">
                                                <i class="fa fa-times"></i>
                                            </a>
                                            <a href="javascript:;" (click)="OffRemoveCart(school)" title="Remove"
                                                *ngIf="tokenacc == null">
                                                <i class="fa fa-times"></i>
                                            </a>
                                        </div>
                                    </td> 
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>

            </div>
            <div class="school-list cart" *ngIf="schoolAddedTocart == null || schoolAddedTocart.length == 0 ">
                <div class="list-inner">
                    <div class="row">
                        <div class="col-md-12 p-md-1">
                            <div class="cart-school-img ">
                                <h4 style="text-align:center;">No items in cart</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="side_sticky">
            <div class="add-more col-md-12 m-auto">
                <a [routerLink]="['/schools']" routerLinkActive="router-link-active"><i class="fa fa-plus-circle"
                        aria-hidden="true"></i> Add more schools
                </a>
            </div>
            <div class="add-more col-md-12 m-auto">
                Note: The option to fill the admission form of the student is after completion of payment 
            </div>

            <div class="count_total" *ngIf="schoolAddedTocart != null && schoolAddedTocart.length != 0">
                <h4>TOTAL<span><i class="fa fa-inr" aria-hidden="true"></i> ₹{{totalamount}}</span></h4>
            </div>

            <div class="t  col-md-12" *ngIf="schoolAddedTocart != null ">
                <button class="btn" (click)="ChecUser()">
                    Make Payment</button>
            </div>
        </div>
    </div>
</section>