import { Component, OnInit } from '@angular/core';
import { SelectedSchool } from '../Models/Product.Model';
import { SearchService } from '../search.service';
import { SharedService } from '../services/updateCart';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Globals } from '../globals';
import { SessionStorageService } from 'ngx-webstorage';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { environment } from '../../environments/environment';
@Component({
	selector: 'app-cart',
	templateUrl: './cart.component.html',
	styleUrls: ['./cart.component.css']
})
export class CartComponent implements OnInit {

	allTotal: number;
	totalamount: number;
	cartcount: number = 0;
	public schoolAddedTocart: SelectedSchool[];
	cartItemCount: number = 0;
	tokenacc: string;
	// uri = 'https://demo.softprodigyphp.in/Admitmykid/backend/public/api';
	uri = environment.baseurl;
	
	constructor(private ss: SearchService, private globals: Globals, private session: SessionStorageService, private httpClient: HttpClient, private router: Router, private sharedService: SharedService) { }

	ngOnInit() {
		
		var access_token = this.session.retrieve('access_token');
 		if (access_token) {
			this.tokenacc = access_token;
			// var uri = 'https://demo.softprodigyphp.in/Admitmykid/backend/public/api/';
			var uri = environment.baseurl;
			let headers = new HttpHeaders();
			headers.append('Content-Type', 'application/json');
			headers.append('authorization', 'Bearer ' + access_token);
			var body = '';
			this.httpClient.post(uri + "getCartDetails", body, { headers })
				.subscribe(
					response => {
						console.log((response as any));
						if ((response as any).school_details.length > 0) {
							let total = 0;
							for (var i = 0; i < (response as any).school_details.length; i++) {
								if ((response as any).school_details[i].form_price != null) {
									total += (response as any).school_details[i].form_price;
									this.totalamount = total;
								}else{
									this.totalamount = 0;
								}
							}
						} else {
							this.totalamount = 0
						}
						this.schoolAddedTocart = (response as any).school_details
					},
					error => {
						console.log("Error", error);
					}
				);
		} else {
			this.schoolAddedTocart = this.ss.getProductFromCart();
			if (this.schoolAddedTocart != null) {
				let total = 0;
				for (var i = 0; i < this.schoolAddedTocart.length; i++) {
					if (this.schoolAddedTocart[i].form_price) {
						total += this.schoolAddedTocart[i].form_price;
						this.totalamount = total;
					}
				}
			} else {
				this.totalamount = 0
			}
		}

	}

	OnRemoveCart(school,id) {
		var school = school;
		var schools_id = [];
		// var uri = 'https://demo.softprodigyphp.in/Admitmykid/backend/public/api/';
		var uri = environment.baseurl;
		if (id) {
			schools_id.push({
				school_id: id
			});
			// post request
			var access_token = this.session.retrieve('access_token');
			let headers = new HttpHeaders();
			headers.append('Content-Type', 'application/json');
			headers.append('authorization', 'Bearer ' + access_token);
			var body = schools_id;
			this.httpClient.post(uri + "removeCart-orders", body, { headers })
				.subscribe(
					response => {
						if ((response as any).school_details.length > 0) {
							let total = 0;
							for (var i = 0; i < (response as any).school_details.length; i++) {
								if ((response as any).school_details[i].form_price) {
									total += (response as any).school_details[i].form_price;
									this.totalamount = total;
								}
							}
						} else {
							this.totalamount = 0;
						}
						console.log(response);
						
						this.globals.cartcount = (response as any).total_cart_orders
						Swal.fire(school.school_name + ' Removed from Cart Successfully.');
						this.ngOnInit();
					},
					error => {
						console.log("Error", error);
					}
				);
		}
	}

	OffRemoveCart(schools: SelectedSchool) {
		this.schoolAddedTocart = this.ss.getProductFromCart();
		let tempProduct = this.schoolAddedTocart.find(p => p.id == schools.id);

		if (tempProduct) {
			var schoolname = tempProduct.school_name;
			console.log(schoolname);
			this.ss.reProductToCart(schools.id);
			this.schoolAddedTocart = this.ss.getProductFromCart();
			Swal.fire( schoolname + ' Removed from Cart Successfully.')
		}
		if (this.schoolAddedTocart != null) {
			// calculate
			let total = 0;
			for (var i = 0; i < this.schoolAddedTocart.length; i++) {
				if (this.schoolAddedTocart[i].form_price) {
					total += this.schoolAddedTocart[i].form_price;
					this.totalamount = total;
				}
			}

			// calculate
			this.cartItemCount = this.schoolAddedTocart.length;
			this.globals.cartcount = this.schoolAddedTocart.length;
			this.sharedService.updateCartCount(this.cartItemCount);
		} else {
			this.totalamount = 0;
			this.cartItemCount = 0;
			this.globals.cartcount = 0;
			this.sharedService.updateCartCount(this.cartItemCount);
		}

	}

	ChecUser() {
		if (this.tokenacc == null) {
			Swal.fire('Please login to your account.').then((result) => {
				if (result.isConfirmed || result.isDismissed) {
					this.router.navigate(['login'])
				}
			})
		}
		else if (this.schoolAddedTocart.length == 0) {
			Swal.fire('Cart is empty.')
		}
		else {
			if (this.tokenacc != null && this.schoolAddedTocart.length != 0) {
				this.router.navigate(['checkout']);
			}
		}
	}
}
